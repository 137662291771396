import {DecorProps} from '../../../core/interfaces/interfaces';
import {DECORATOR} from '../../../constants/images';

const StylishDecor = ({
  width = '100%',
  height = '100%',
  style,
  children,
  className,
}: DecorProps) => {
  return (
    <div className={`main-decor ${className}`} style={{width, height}}>
      <img className="decor-edge-tl" src={DECORATOR.DECORATOR_EDGES} alt="decorator-div-content" />
      <img className="decor-edge-tr" src={DECORATOR.DECORATOR_EDGES} alt="decorator-div-content" />
      <img className="decor-edge-bl" src={DECORATOR.DECORATOR_EDGES} alt="decorator-div-content" />
      <img className="decor-edge-br" src={DECORATOR.DECORATOR_EDGES} alt="decorator-div-content" />
      <div style={style} className="decor-content">
        {children}
      </div>
    </div>
  );
};

export default StylishDecor;
