import Button from '@components/common/Button.component';
import Decor from '@components/common/decorators/StylishDecor.component';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import {useEffect, useState} from 'react';
import {setWalletConnection, setWalletAddress} from '@redux/features/wallet/walletSlice';
import {setMintLoading} from '@redux/features/mint/mintSlice';
import {useDispatch} from 'react-redux';
import ConnectWith from '@components/modal/ConnectWith.modal';

const ConnectWallet = () => {
  const dispatch = useDispatch();
  const [popUpOpen, setPopupOpen] = useState<boolean>(false);

  const eventHandler = (event: any) => {
    if (event.detail.type === 'RESPONSE_ADDRESS') {
      if (event.detail.payload) {
        dispatch(setWalletConnection(true));
        dispatch(setWalletAddress(event.detail.payload));
        dispatch(setMintLoading(false));
      }
    } else {
      console.log('Error on wallet connection!');
      dispatch(setMintLoading(false));
    }
  };

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  return (
    <div className="connect__wallet__wrapper">
      <div className="connect__wallet">
        <Decor className="wallet__background">
          <StylishSeperator className="connect-wallet-seperator" text="WALLET CONNECTION" />
          <div className="wallet__message">
            <p>You need a wallet to interact with the platform. </p> <br />
            If you do not have ICONex wallet installed, please download from{' '}
            <a
              href="https://chrome.google.com/webstore/detail/iconex/flpiciilemghbmfalicajoolhkkenfel?hl=en"
              target="_blank"
              rel="noreferrer">
              {' '}
              here{' '}
            </a>{' '}
            and try again. <br />
            <div className="connect_btn">
              <Button
                style={{margin: '20px 0'}}
                onClick={() => {
                  setPopupOpen(true);
                }}
                text="Connect Wallet"
                className="primary"
              />
            </div>
          </div>
        </Decor>
        {popUpOpen && <ConnectWith setConnectWalletPopup={setPopupOpen} />}
      </div>
    </div>
  );
};

export default ConnectWallet;
