import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@redux/store';
import {getAddressClaimHistory, getClaimableAmount} from '@services/Icon.service.js';
import {toFixedNoRounding} from '@services/Misc.service';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import {getAvailableCrown} from '@services/Crown.service';
import {setAvailableCrown} from '@redux/features/wallet/walletSlice';
import {getbOMMRewards, getLPRewards, getcrownLPRewards, getStakingRewards} from '@api/API';
import ClaimCompleteModal from './modals/ClaimComplete';
import ClaimHistoryModal from './modals/ClaimHistory';
import ClaimLoadingModal from '../LPStaking/modals/Loading';
import CrownSources from './CrownSources';
import AccountOverview from './AccountOverview';
import RewardDistributionModal from './modals/RewardDistribution';

const CrownRedemption = () => {
  const {walletAddress} = useSelector((state: RootState) => state.inventory);
  const dispatch = useDispatch();

  const [isClaimLoading, setClaimLoading] = useState<boolean>(false);
  const [isClaimComplete, setClaimComplete] = useState<boolean>(false);
  // eslint-disable-next-line
  const [claimedAmount, setClaimedAmount] = useState<number>(0);
  const [isClaimHistoryVisible, setClaimHistoryVisible] = useState<boolean>(false);
  const [claimHistoryData, setClaimHistoryData] = useState<any>([]);

  // Crown Sources
  const [stakeRewardDB, setStakeRewardDB] = useState<any>(null);
  const [lpRewardDB, setlpRewardDB] = useState<number>(0);
  const [crownlpRewardDB, setCrownlpRewardDB] = useState<number>(0);
  const [boom500kRewardDB, setboom500kRewardDB] = useState<number>(0);
  const [isRewardPopupOpen, setRewardPopupOpen] = useState<boolean>(false);

  const [availableForClaimLocked, setAvailableForClaimLocked] = useState<number>(-1);

  useEffect(() => {
    const getCrownDetails = async () => {
      if (walletAddress) {
        // Setting available for claim
        dispatch(
          setAvailableCrown(toFixedNoRounding((await getAvailableCrown(walletAddress)) as string))
        );

        const claimHistory = await getAddressClaimHistory(walletAddress, 10, 0);
        setClaimHistoryData(claimHistory);
      }
    };

    getCrownDetails();
  }, [isClaimComplete]);

  useEffect(() => {
    const getCrownClaimDetails = async () => {
      if (walletAddress) {
        setAvailableForClaimLocked((await getClaimableAmount(walletAddress)) as number);
      }
    };

    getCrownClaimDetails();
  }, []);

  // Amounts from API -> Stake and LP data
  useEffect(() => {
    const getRewards = async () => {
      const stakeRewardRes = await getStakingRewards(walletAddress!);
      if (stakeRewardRes.data?.rewards) {
        setStakeRewardDB(stakeRewardRes.data);
      }

      const lpRewardRes = await getLPRewards(walletAddress!);
      if (lpRewardRes.data?.accrued_rewards) {
        setlpRewardDB(lpRewardRes.data.accrued_rewards);
      }

      const lpCrownRewardRes = await getcrownLPRewards(walletAddress!);
      if (lpCrownRewardRes.data?.accrued_rewards) {
        setCrownlpRewardDB(lpCrownRewardRes.data.accrued_rewards);
      }

      const bOMM500kpoolRes = await getbOMMRewards(walletAddress!);
      if (bOMM500kpoolRes.data?.reward) {
        setboom500kRewardDB(bOMM500kpoolRes.data.reward);
      }
    };

    getRewards();
  }, []);

  return (
    <div className="crown__wrap">
      <StylishSeperator className="stylish-info-seperator" text="CROWN REDEMPTION" />

      <AccountOverview
        isClaimComplete={isClaimComplete}
        isClaimLoading={isClaimLoading}
        setClaimLoading={setClaimLoading}
        setClaimedAmount={setClaimedAmount}
        setClaimComplete={setClaimComplete}
        setClaimHistoryVisible={setClaimHistoryVisible}
        claimHistoryData={claimHistoryData}
      />

      <CrownSources
        isClaimComplete={isClaimComplete}
        setRewardPopupOpen={setRewardPopupOpen}
        stakeRewardDB={stakeRewardDB}
        lpRewardDB={lpRewardDB}
        lpCrownRewardDB={crownlpRewardDB}
        boom500kRewardDB={boom500kRewardDB}
      />

      <ClaimLoadingModal isClaimLoading={isClaimLoading} isClaimComplete={isClaimComplete} />

      <ClaimCompleteModal
        isClaimComplete={isClaimComplete}
        setClaimComplete={setClaimComplete}
        claimedAmount={availableForClaimLocked}
      />

      <ClaimHistoryModal
        isClaimHistoryVisible={isClaimHistoryVisible}
        setClaimHistoryVisible={setClaimHistoryVisible}
        claimHistoryData={claimHistoryData}
      />

      <RewardDistributionModal
        isRewardPopupOpen={isRewardPopupOpen}
        setRewardPopupOpen={setRewardPopupOpen}
        stakeRewardDB={stakeRewardDB}
        lpRewardDB={lpRewardDB}
        boom500kRewardDB={boom500kRewardDB}
      />
    </div>
  );
};

export default CrownRedemption;
