/* eslint-disable prettier/prettier */
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
// import PlainSeperator from '@components/common/decorators/PlainSeperator.component';

const Feature = () => {
  return (
    <StylishDecor style={{ margin: '25px 30px' }} className="emerald-feature-wrapper">
      <StylishSeperator className="stylish-info-seperator" text="crown Usage" />
      <p className="r-body-2 text-center emerald-feature-info-top mansion-feature-info-p">
        CROWN is the governance token for the Emerald City in GangstaVerse. The CROWN token (IRC-2
        token standard) will be used to fuel the entire economy of the City. It is a currency that
        will be used as a reward for various in-game activities in the Emerald City. Newly
        accumulated CROWN will be claimable once a week for now, and eventually, they can be claimed
        instantly (per block).
      </p>
      <div className="emerald-features">
        <div className="feature-info">
          <p className="r-subtitle-1 feature-info-title">1. CROWN/sICX pair </p>
          <p className="r-body-2 mansion-feature-info-p">
            Provide liquidity in CROWN/sICX pair in Balanced DEX to receive CROWN tokens as a
            reward. The current monthly reward for providing liquidity is 150,000 CROWN per month.{' '}
          </p>
          {/* <PlainSeperator /> */}
        </div>
        <div className="feature-info">
          <p className="r-subtitle-1 feature-info-title">2. Mint Civilians</p>
          <p className="r-body-2 mansion-feature-info-p">
            CROWN can be used with two GangstaBets and a Golden Key to mint Generation 0 Civilians
            in the Emerald City. These Civilians can earn more CROWN by going on different journeys
            in the City.
          </p>
          {/* <PlainSeperator /> */}
        </div>
        <div className="feature-info">
          <p className="r-subtitle-1 feature-info-title">3. Mint Land Certificate</p>
          <p className="r-body-2 mansion-feature-info-p">
            Use CROWN to get a Land Certificate for the Emerald City. This Certificate will act as a
            proof of ownership to get Land during the Land Mint (City launch).
          </p>
          {/* <PlainSeperator /> */}
        </div>
        <div className="feature-info">
          <p className="r-subtitle-1 feature-info-title">4. Deposit in Bank</p>
          <p className="r-body-2 mansion-feature-info-p">
            Deposit CROWN in the Emerald City Bank to receive fees from the Emerald City ecosystem.
            10% of all CROWN and marketplace transactions will be deposited automatically into the
            Bank.
          </p>
          {/* <PlainSeperator /> */}
        </div>

        <div className="feature-info">
          <p className="r-subtitle-1 feature-info-title">5. Deposit in Forex</p>
          <p className="r-body-2 mansion-feature-info-p">
            Deposit CROWN in Forex to receive GangstaBet NFTs in the Sapphire City (Polygon
            Network).
          </p>
        </div>
      </div>
    </StylishDecor>
  );
};

export default Feature;
