import StylishSeperator from '@components/common/decorators/StylishSeperator.component';

interface ILoadingScreen {
  loading: boolean;
  text: string;
}

const LoadingScreen = ({loading, text}: ILoadingScreen) => {
  if (!loading) return null;
  return (
    <div className="reveal-loading-wrapper">
      <div className="reveal-loading-content">
        <div className="shimmer-wrapper">
          <div className="shimmer big" />
          <div className="shimmer small" />
        </div>
        <div className="reveal-loading-overlay">
          <StylishSeperator className="stylish-info-seperator extra-sm-seperator" text={text} />
        </div>
      </div>
    </div>
  );
};
// background: rgba(32, 168, 133, 0.6);
// background: radial-gradient(at center, rgba(32, 168, 133, 0.6), rgba(28, 36, 42, 0.5));

export default LoadingScreen;
