import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Mansion from '@pages/Home/Mansion';
import Bank from '@pages/Bank/Bank';
import Header from '@components/common/Header.component';
import Footer from '@components/common/Footer.component';
import ConnectWallet from '@pages/ConnectWallet/ConnectWallet';
import CrownRedemption from '@pages/CrownRedemption/CrownRedemption';
import Forex from '@pages/Forex/Forex';
import Inventory from '@pages/Inventory/Inventory';
import {useSelector} from 'react-redux';
import {RootState} from '@redux/store';
import {useEffect, useState} from 'react';
import Toaster from '@components/common/toaster/Toaster.component';
import toasterService from '@components/common/toaster/ToasterService.component';
import Error404 from '@components/error/Error404';
import PropertyProfile from '@pages/PropertyProfile/PropertyProfile';
import MintLand from '@pages/LandReveal/MintLand';
import Land from '@pages/Lands/Land';
// import LPStaking from '@pages/LPStaking/LPStaking';

const App = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    // console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
  }
  const {isWalletConnected, walletAddress} = useSelector((state: RootState) => state.inventory);
  const {revealStarted} = useSelector((state: RootState) => state.revealStatus);
  const [toasterList, setToasterList] = useState([]);
  useEffect(() => {
    toasterService.get().subscribe((data: any) => {
      setToasterList(data);
    });
  });
  return (
    <BrowserRouter>
      <Header />
      <div className="r-container">
        <Routes>
          <Route path="/" element={<Mansion />} />
          <Route path="/bank" element={<Bank />} />
          <Route path="/forex" element={<Forex />} />
          {revealStarted && (
            <>
              <Route
                path="/reveal"
                element={isWalletConnected && walletAddress ? <MintLand /> : <ConnectWallet />}
              />
              <Route path="/land" element={<PropertyProfile />} />
            </>
          )}
          <Route
            path="/crown"
            element={isWalletConnected && walletAddress ? <CrownRedemption /> : <ConnectWallet />}
          />
          {/* <Route
            path="/lp"
            element={isWalletConnected && walletAddress ? <LPStaking /> : <ConnectWallet />}
          /> */}
          <Route path="/inventory/:address" element={<Inventory />} />
          <Route path="/lands" element={<Land />} />
          <Route
            path="/inventory/connect"
            element={!isWalletConnected ? <ConnectWallet /> : <Error404 />}
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
        <Toaster position="bottom-right" autoDelete toastList={toasterList} />
      </div>
    </BrowserRouter>
  );
};

export default App;
