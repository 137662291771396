import Button from '@components/common/Button.component';
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import {LAND_CRAFT_URL, LAND_REVEAL_MEDIUM_ARTICLE_URL} from '@constants/constants';
import {HiOutlineExternalLink} from 'react-icons/hi';

const AllLandRevealed = () => {
  return (
    <StylishDecor className="mint-status-wrapper" style={{width: '100%'}}>
      <div className="mint-status-inner-wrapper">
        <p className="heading-name" style={{paddingBottom: '40px'}}>
          All the land have been revealed
        </p>
        <Button
          text="View in craft"
          icon={<HiOutlineExternalLink />}
          onClick={() => {
            window.open(LAND_CRAFT_URL, '_blank');
          }}
          className="primary"
        />
        {/* eslint-disable-next-line */}
        <div
          className="medium-link"
          onClick={() => {
            window.open(LAND_REVEAL_MEDIUM_ARTICLE_URL, '_blank');
          }}>
          <p>Read More about Land Reveal in our Medium article</p>
          <HiOutlineExternalLink />
        </div>
      </div>
    </StylishDecor>
  );
};

export default AllLandRevealed;
