/*eslint-disable */
import {useState, useEffect} from 'react';

import {RiFileCopyLine, RiRoadMapLine, RiArrowRightSLine} from 'react-icons/ri';

import {LAND_VIEWER} from '@constants/images';
import Button from '@components/common/Button.component';
import BuySale from '@components/profile/BuySale.component';
import {TbArrowUpRight} from 'react-icons/tb';
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import PropertyLocation from '@components/property/PropertyLocation.component';
import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
import LocationMarkMap from '@components/map/locationMarkMap.component';
import PropertyCarousel from '@components/property/PropertyCarousel.component';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {getLandMetadata} from '@api/API';
import {getLandDetail} from '@services/Icon.service';
import LandViewer from '@components/land-viewer/LandViewer';
import {DEV_LAND_REVEAL_BUCKET, LAND_CRAFT_URL} from '@constants/constants';

interface IPropertyDetail {
  metadata: any;
  name: string;
  ownerAddress: string;
  metaId: number;
}

const PropertyProfile = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [addressCopied, setAddressCopied] = useState<boolean>(false);
  const [propertyDetail, setPropertyDetail] = useState<IPropertyDetail>();
  const [queryParams, setQueryParams] = useState<any>({});
  const [nftId, setNftId] = useState<any>(null);

  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const paramEntries = Object.fromEntries(params.entries());
    setQueryParams(paramEntries);
    if (!('token' in paramEntries) || paramEntries['token'] === '') navigate('/error/');
    if (paramEntries.token) setNftId(paramEntries.token);
  }, []);

  useEffect(() => {
    if (!nftId) return;
    (async () => {
      try {
        if (nftId && +nftId) {
          const {metaId, name, owner} = await getLandDetail(nftId);

          const {data} = await getLandMetadata(+metaId);
          setPropertyDetail({
            metadata: data,
            name: name,
            ownerAddress: owner,
            metaId: metaId,
          });
        } else {
          navigate('/error/');
        }
      } catch (err) {
        navigate('/error/');
      }
    })();
  }, [nftId]);

  const handleOpenModal = (x: string, y: string) => {
    // TODO: modal view make it dynamic
    // navigate(`${location.pathname}?${x}x${y}`);
    navigate(`${location.pathname}?5023`);
    setShowModal(true);
  };

  return (
    <div className="property-profile-page">
      {propertyDetail && (
        <div className="property-profile-wrapper">
          <div className="profile-left">
            <PropertyCarousel
              images={propertyDetail.metadata.images}
              previewImage={propertyDetail.metadata.preview}
              // imageUrl={propertyDetail.metadata.image_uri}
              imageUrl={
                DEV_LAND_REVEAL_BUCKET ? DEV_LAND_REVEAL_BUCKET : propertyDetail.metadata.image_uri
              }
            />
            <div className="property-info-wrapper">
              {/* <div className="property-btns">
                <div className="diff-viewer-btn primary disabled">
                  <button
                    className="button"
                    onClick={() =>
                      handleOpenModal(
                        propertyDetail.metadata.coordinates.x,
                        propertyDetail.metadata.coordinates.y
                      )
                    }
                    disabled>
                    <img
                      width={'100%'}
                      className={`3d-view`}
                      src={LAND_VIEWER}
                      alt={`landViewer`}
                    />
                  </button>
                </div>
                <Button
                  text="view on map"
                  className="primary-outline disabled"
                  style={{width: '230px'}}
                  icon={<RiRoadMapLine className="primary-color" />}
                  iconPosition="start"
                  disabled
                />
              </div> */}

              {/* <Link
                onClick={() => {
                  window.open(propertyDetail.metadata.image_url);
                }}
                className="links ipfs-link  primary-color"
                to="#">
                View in IPFS <TbArrowUpRight />
              </Link> */}
              <Link
                onClick={() => {
                  // window.open(`${LAND_CRAFT_URL}`);
                  window.open(`https://craft.network/nft/emerald-city-land:${+nftId}`);
                }}
                className="links ipfs-link primary-color"
                to="#">
                View in Craft <TbArrowUpRight />
              </Link>
              <button
                onClick={async () => {
                  await window.navigator.clipboard.writeText(propertyDetail.ownerAddress);
                  setAddressCopied(true);
                  setTimeout(() => {
                    setAddressCopied(false);
                  }, 1000);
                }}
                type="button"
                className={`text-center profile-owner ${addressCopied ? 'show-info' : ''}`}>
                Owned By:{' '}
                <span className="primary-color">
                  {propertyDetail.ownerAddress !== '' &&
                    `${propertyDetail.ownerAddress.substring(
                      0,
                      8
                    )}...${propertyDetail.ownerAddress.slice(-8)}`}{' '}
                </span>{' '}
                <RiFileCopyLine size={16} className="primary-color" style={{paddingRight: '5px'}} />{' '}
              </button>
            </div>
          </div>
          <div className="profile-right">
            {/* <BuySale /> */}
            <StylishDecor className="location-bg" height="auto">
              <div className="property-detail-content">
                <div className="property-location-info">
                  <p className="property-name">{propertyDetail.name}</p>
                  <PlainSeperator width="100%" />
                  <PropertyLocation
                    // propertyName={propertyDetail.name}
                    district={propertyDetail.metadata.district}
                    zone={propertyDetail.metadata.zone}
                    infrastructure={
                      propertyDetail.metadata.attributes &&
                      propertyDetail.metadata.attributes.find(
                        (attr: any) => attr.key === 'Infrastructure'
                      )?.value
                    }
                    landAttributes={propertyDetail.metadata.attributes}
                  />
                  {/* <Link
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_LAND_BUCKET_API}/metadata/${propertyDetail.metaId}.json`
                      );
                    }}
                    className="links metadata-link primary-color"
                    to="#">
                    View full metadata <RiArrowRightSLine />
                  </Link> */}
                </div>
                <PlainSeperator style={{marginTop: '18px'}} />
                <LocationMarkMap
                  lat={propertyDetail.metadata.coordinates.x}
                  lng={propertyDetail.metadata.coordinates.y}
                />
              </div>
            </StylishDecor>
          </div>
        </div>
      )}
      {showModal && (
        <LandViewer setShowModal={setShowModal} showModal={showModal} queryParams={queryParams} />
      )}
    </div>
  );
};

export default PropertyProfile;
