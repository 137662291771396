import React, {useState} from 'react';
import {AiFillCloseCircle, AiOutlineZoomIn, AiOutlineZoomOut} from 'react-icons/ai';
import {BiReset} from 'react-icons/bi';

interface artViewProps {
  setShowImage: any;
  image: string;
}

const ArtView = ({setShowImage, image}: artViewProps) => {
  const [zoomValue, setZoomValue] = useState(100);
  const zoomInOut = (value: string) => {
    if (value === 'in') {
      if (zoomValue < 200) setZoomValue(zoomValue + 5);
    } else if (value === 'out') {
      if (zoomValue > 40) setZoomValue(zoomValue - 5);
    } else {
      setZoomValue(100);
    }
  };
  return (
    <div className="artViewOverlay art-view">
      <div className=" px-3 py-4 art-view-modal ">
        <button
          onClick={() => setShowImage(false)}
          type="button"
          className="close position-absolute borderless-btn">
          <AiFillCloseCircle color="red" size={25} />
        </button>
        <img style={{height: `${zoomValue}%`}} src={image} alt="art view" className="art" />
        <div className="zoom-in-out">
          <AiOutlineZoomOut
            color="var(--primary-color)"
            onClick={() => {
              zoomInOut('out');
            }}
            size={30}
          />

          <p className="text-white zoom-value">{zoomValue}%</p>
          <button
            onClick={() => {
              zoomInOut('in');
            }}
            className="borderless-btn"
            type="button">
            <AiOutlineZoomIn color="var(--primary-color)" size={30} />
          </button>
        </div>
        {zoomValue !== 100 && (
          <button
            type="button"
            onClick={() => {
              zoomInOut('normal');
            }}
            className="borderless-btn">
            {' '}
            <BiReset className="text-white reset-zoom" color="var(--primary-color)" size={30} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ArtView;
