export const BRIBES_LIST = [
  {
    name: 'Tankard',
    zone: 'Recreational',
  },
  {
    name: 'Blessed Bird',
    zone: 'Airport',
  },
  {
    name: 'Hammer',
    zone: 'Industrial',
  },
  {
    name: 'Pouch',
    zone: 'Governmental',
  },
  {
    name: 'Shrine',
    zone: 'Education',
  },
  {
    name: 'Potion',
    zone: 'Health',
  },
  {
    name: 'Gold Coin',
    zone: 'Commercial',
  },
  {
    name: 'Enchanted Stone',
    zone: 'Residential',
  },
];

export const TEST = {};

export const LAND_REVEAL_CAP = 3600;
export const LAND_CRAFT_URL = 'https://craft.network/collection/emerald-city-land';
export const REVEAL_START_TIME = '2023-07-22 19:59:00+0545';
export const LAND_REVEAL_MEDIUM_ARTICLE_URL =
  'https://gangstaverse.medium.com/land-reveal-the-emerald-city-8f1c1be16559p[';
export const DEV_LAND_REVEAL_BUCKET =
  process.env.REACT_APP_ENV === 'development'
    ? 'https://land-reveal-dev.s3.ap-south-1.amazonaws.com/'
    : null;
