/* eslint-disable */
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://dev-emerald-city.herokuapp.com/api';

const CROWN_LP_BASE_URL = 'https://prod-gangstabet-crown-bg.herokuapp.com/api';

const GANGSTABET_BASE_URL = process.env.REACT_APP_GANGSTA_API_BASE_URL;

const LAND_METADATA_URL = process.env.REACT_APP_LAND_BUCKET_API + '/metadata';

const LAND_REVEAL_BASE_URI = process.env.REACT_APP_REVEAL_BASE_URI;

const LAND_BASE_API = process.env.REACT_APP_BASE_API;

const LAND_METADATA_BE_URL = 'https://land-api.emeraldcity.xyz/land';

export const filterMarketplaceData = (filter: string) => {
  return axios.get(`${BASE_URL}/marketplace/filter/?${filter}`);
};

export const getStakingRewards = (walletAddress: String) => {
  return axios.get(`${GANGSTABET_BASE_URL}/rewards/${walletAddress}`);
};

export const getcrownLPRewards = (walletAddress: String) => {
  console.log(`${CROWN_LP_BASE_URL}/reward/${walletAddress}`);
  return axios.get(`${CROWN_LP_BASE_URL}/reward/${walletAddress}`);
};

export const getLPRewards = (walletAddress: String) => {
  return axios.get(`${GANGSTABET_BASE_URL}/reward/${walletAddress}`);
};

export const getbOMMRewards = (walletAddress: String) => {
  return axios.get(`${GANGSTABET_BASE_URL}/bommreward/${walletAddress}`);
};

export const getGoldenKeyRewardStatus = (
  walletAddress: String,
  mintCount: number,
  index: number
) => {
  return axios.post(`https://mountain-api.gangstabet.io/api/goldenkeyreward/${walletAddress}`, {
    address: walletAddress,
    minted_count: index,
    index: mintCount,
  });
};

export const getLandMetadata = (metaId: number) => {
  // const testNftMetaId = 'land_10_30';
  return axios.get(`${LAND_METADATA_URL}/${metaId}.json`);
};

export const syncLandMint = (txHash: string) => {
  return axios.post(`${LAND_REVEAL_BASE_URI}/mint/txHash`, {
    txHash: txHash,
  });
};

export const syncUserAddress = (walletAddress: string) => {
  console.log(walletAddress);
  return axios.get(`${LAND_REVEAL_BASE_URI}/sync/${walletAddress}`);
};

export const getLandMetadataByNftId = (nftId: number) => {
  return axios.get(`${LAND_METADATA_BE_URL}/${nftId}.json`);
};

export const getFilteredLand = (filterPayload: any) => {
  const params: {[key: string]: string} = {};
  if (filterPayload.hasOwnProperty('Road') && filterPayload['Road'].length > 0) {
    params['Road'] = filterPayload['Road'];
  }
  if (filterPayload.hasOwnProperty('Ground') && filterPayload['Ground'].length > 0) {
    params['Ground'] = filterPayload['Ground'];
  }
  if (filterPayload.hasOwnProperty('Vegetation') && filterPayload['Vegetation'].length > 0) {
    params['Vegetation'] = filterPayload['Vegetation'];
  }
  if (filterPayload.hasOwnProperty('Vehicle') && filterPayload['Vehicle'].length > 0) {
    params['Vehicle'] = filterPayload['Vehicle'];
  }
  if (filterPayload.hasOwnProperty('Watercraft') && filterPayload['Watercraft'].length > 0) {
    params['Watercraft'] = filterPayload['Watercraft'];
  }
  if (filterPayload.hasOwnProperty('Monument') && filterPayload['Monument'].length > 0) {
    params['Monument'] = filterPayload['Monument'];
  }
  if (filterPayload.hasOwnProperty('Aircraft') && filterPayload['Aircraft'].length > 0) {
    params['Aircraft'] = filterPayload['Aircraft'];
  }
  if (filterPayload.hasOwnProperty('Backyard') && filterPayload['Backyard'].length > 0) {
    params['Backyard'] = filterPayload['Backyard'];
  }
  if (
    filterPayload.hasOwnProperty('Infrastructure') &&
    filterPayload['Infrastructure'].length > 0
  ) {
    params['Infrastructure'] = filterPayload['Infrastructure'];
  }
  return axios.get(`${LAND_BASE_API}/api/land/filter`, {params});
};

export const getMintedLandWithCount = () => {
  return axios.get(`${LAND_BASE_API}/api/land/mintedLandsCount`);
};
