/* eslint-disable */
import CertificateCard from '@components/cards/CertificateCard.component';

const Certificate = ({
  addressCertificates,
  showSkeleton,
  tabType,
  mintedZoneCountCertificate,
}: any) => {
  return (
    <div>
      {Object.keys(addressCertificates).length > 0 && !showSkeleton && tabType === 'CERTIFICATE' && (
        <div className="owned-nfts">
          {Object.keys(addressCertificates).map((key, index) => {
            const indexs = `key_${index}`;

            return (
              <CertificateCard
                key={indexs}
                type={key}
                number={Object.values(addressCertificates)[index] as string}
                totalCount={mintedZoneCountCertificate[key]}
              />
            );
          })}
        </div>
      )}

      <div className="show-skeleton-inventory">
        {console.log(tabType, showSkeleton)}
        {showSkeleton &&
          tabType === 'CERTIFICATE' &&
          [1, 2, 3, 4].map((index) => {
            const key = `key_${index}`;
            return (
              <div
                key={key}
                style={{width: '247px', height: '335px', borderRadius: '5px'}}
                className="skeleton skeleton-default-width"></div>
            );
          })}
      </div>
    </div>
  );
};

export default Certificate;
