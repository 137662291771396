import Modal from '@components/modal/Modal.component';
import {MINT_STATES} from '@constants/states';

const RevealFailedModal = ({revealProgress, isVisible, setVisible, handleRevealError}: any) => {
  return (
    revealProgress.revealStatus === MINT_STATES.FAILED &&
    isVisible && (
      <Modal
        setPopupOpen={setVisible}
        extraFunctions={handleRevealError}
        title="Revealing Encountered an error!">
        <p> There is an issue with revealing the certificate. Please try again, later. </p>
      </Modal>
    )
  );
};

export default RevealFailedModal;
