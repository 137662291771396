/* eslint-disable */
import Button from '@components/common/Button.component';
import Modal from '@components/modal/Modal.component';

const GKModalWinner = ({isWinner, setVisible}: any) => {
  return (
    isWinner && (
      <Modal setPopupOpen={setVisible} title="Congratulations!" isCloseHidden>
        <div className="golden-key-check">
          <p> You won a golden key!</p>
          <img
            src="https://media.craft.network/600x/https://craft-network.mypinata.cloud/ipfs/QmYHeURAgqgDiUjWfdRDUsEUaMV91S25bhYRpX23zcLpMW"
            width={150}
          />
          <br />
          <Button
            onClick={() => setVisible(false)}
            style={{width: '250px'}}
            text="Close"
            className="primary"
          />
        </div>
      </Modal>
    )
  );
};

export default GKModalWinner;
