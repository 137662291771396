/* eslint-disable no-unused-vars */
import ImageLoader from '@loader/ImageLoader';
import {MANSION} from '@constants/images';
import PlainDecor from '@components/common/decorators/PlainDecor.component';
import {useNavigate} from 'react-router-dom';

interface IPropertyCardProps {
  imgSource: string;
  name: string;
  zone: string;
  district: string;
  number: string;
  totalCount?: string;
  nftId: number;
  lazyLoading?: boolean;
}

const PropertyCard = ({
  imgSource,
  name,
  zone,
  district,
  number,
  totalCount,
  nftId,
  lazyLoading,
}: IPropertyCardProps) => {
  const navigate = useNavigate();
  return (
    /* eslint-disable-next-line */
    <div
      className="card-wrapper property-card-wrapper"
      onClick={() => navigate(`/land?token=${nftId}`)}>
      <div className="property-img-wrapper">
        <img
          src={imgSource}
          className="property-img"
          alt={`${name}-property`}
          loading={`${lazyLoading ? 'lazy' : 'eager'}`}
        />
      </div>
      <div className="card-box-part">
        <PlainDecor padding="15px" edgeStyleEnabled>
          <div className="card-decor-box">
            <div className="card-type">
              <div className="card-info-content">
                {/* <p className="r-caption card-type-text">Land</p> */}
                <p className="r-body-1 card-property-name-text">{name}</p>
                <p className="r-body-1 card-property-district-text">{district}</p>
                <p className="card-zone-text">{zone}</p>
              </div>
              {/* <div className="type-inventory-count">
                <p className="inventory-type-num">
                  {Number(number)} of {Number(totalCount)}
                </p>
              </div> */}
            </div>
          </div>
        </PlainDecor>
      </div>
    </div>
  );
};

export default PropertyCard;
