/*eslint-disable */
interface ButtonProps {
  onClick?: any;
  text: string;
  icon?: any;
  style?: any;
  className: 'primary' | 'primary-outline' | string;
  isProgress?: boolean;
  disabled?: boolean;
  shimmer?: boolean;
  iconPosition?: 'start' | 'end' | string;
}

const Button = ({
  onClick,
  text,
  icon,
  className,
  style,
  isProgress,
  disabled,
  shimmer,
  iconPosition,
}: ButtonProps) => {
  return (
    <div onClick={!disabled && onClick} className="button-component">
      <div className={`button-wrapper ${className}`}>
        {!disabled && shimmer && (
          <div className="shimmer-wrapper">
            <div className="shimmer big" />
            <div className="shimmer small" />
          </div>
        )}
        <button
          disabled={isProgress || disabled}
          type="button"
          className={`button ${isProgress && 'disable-buttons'} ${disabled && 'disabled-buttons'}`}
          style={style}>
          <strong>
            {iconPosition === 'start' && icon}{' '}
            <span className="button-text-spacing">
              {isProgress && className === 'primary' ? (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : isProgress && className === 'primary-outline' ? (
                <div className="lds-ring lds-ring-outline">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                text
              )}
            </span>{' '}
            {(iconPosition === 'end' || !iconPosition) && icon}
          </strong>
        </button>
      </div>
    </div>
  );
};

export default Button;
