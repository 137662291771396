import {CERTIFICATE_LOADING} from '@constants/images';
import {useEffect, useState} from 'react';

interface imageLoader {
  src: string;
  className?: string;
  alt: string;
  style?: any;
  onClick?: any;
  skeletonStyle?: any;
  width?: string;
  height?: string;
  skeletonClassName?: string;
  gifSkeleton?: boolean;
}

const ImageLoader = ({
  src,
  className,
  alt,
  style,
  onClick,
  skeletonStyle,
  width = '100%',
  height,
  skeletonClassName,
  gifSkeleton,
}: imageLoader) => {
  const [isImageLoaded, setImageLoader] = useState(true);
  useEffect(() => {
    if (src) {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        setImageLoader(false);
      };
    }
  }, [src]);
  if (isImageLoaded) {
    if (gifSkeleton) return <img src={CERTIFICATE_LOADING} style={skeletonStyle} alt="loading" />;
    return (
      <div
        style={skeletonStyle}
        className={`skeleton skeleton-default-width ${skeletonClassName}`}
      />
    );
  }
  return (
    // eslint-disable-next-line
    <img
      onClick={onClick}
      width={width}
      height={height}
      src={src}
      style={style}
      className={className}
      alt={alt}
    />
  );
};

export default ImageLoader;
