/* eslint-disable import/no-extraneous-dependencies */
import {configureStore} from '@reduxjs/toolkit';
import {persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';
import mintSlice from '@redux/features/mint/mintSlice';
import bankSlice from '@redux/features/bank/bankSlice';
import walletSlice from '@redux/features/wallet/walletSlice';
import landRevealSlice from './features/landReveal/landRevealSlice';
import revealStatusSlice from './features/landReveal/revealStatusSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['bank', 'mint', 'landReveal'],
  // whitelist: ['wallet'],
};

const rootReducer = combineReducers({
  mint: mintSlice,
  bank: bankSlice,
  inventory: walletSlice,
  landReveal: landRevealSlice,
  revealStatus: revealStatusSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
