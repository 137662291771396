/*eslint-disable*/
class ScaleManager {
  private originalHeight: number;
  private originalWidth: number;
  private scaledWidth: number;
  private scaledHeight: number;
  private scaleFactor: {scaleX: number; scaleY: number};

  constructor(canvasWidth: number, canvasHeight: number) {
    this.originalHeight = 140;
    this.originalWidth = 140;
    this.scaledWidth = canvasWidth / 2;
    this.scaledHeight = canvasHeight / 2;
    this.scaleFactor = this.calculateScaleFactor(
      this.originalWidth,
      this.originalHeight,
      this.scaledWidth,
      this.scaledHeight
    );
  }

  /**
   * Method for finding the scale factor scaling coords.
   * @param originalX
   * @param originalY
   * @param scaledX
   * @param scaledY
   * @returns
   */
  private calculateScaleFactor(
    originalX: number,
    originalY: number,
    scaledX: number,
    scaledY: number
  ) {
    const deltaX = scaledX - originalX;
    const deltaY = scaledY - originalY;
    const scaleX = deltaX !== 0 ? scaledX / originalX : 1;
    const scaleY = deltaY !== 0 ? scaledY / originalY : 1;
    return {
      scaleX: scaleX,
      scaleY: scaleY,
    };
  }

  getOriginalCoords(Sx: number, Sy: number) {
    return {
      x: Sx / this.scaleFactor.scaleX,
      y: Sy / this.scaleFactor.scaleY,
    };
  }

  getScaledCoords(x: number, y: number) {
    return {
      x: x * this.scaleFactor.scaleX,
      y: y * this.scaleFactor.scaleY,
    };
  }
}

export default ScaleManager;
