/*eslint-disable */
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

interface IWalletState {
  isWalletConnected: boolean;
  walletAddress: string | null;
  availableCrown: String;
  isLedger: boolean;
  ledgerPath: string | null;
  ledgerTransport: any;
}

const initialState: IWalletState = {
  isWalletConnected: false,
  walletAddress: null,
  availableCrown: '-',
  isLedger: false,
  ledgerPath: '',
  ledgerTransport: null,
};

export const walletSlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setWalletAddress: (state: IWalletState, action: PayloadAction<string | null>) => {
      state.walletAddress = action.payload;
      state.isWalletConnected = Boolean(action.payload);
    },
    setWalletConnection: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnected = action.payload;
    },
    setAvailableCrown: (state, action: PayloadAction<String>) => {
      state.availableCrown = action.payload;
    },
    setIsLedger: (state, action: PayloadAction<boolean>) => {
      state.isLedger = action.payload;
    },
    setLedgerPath: (state: IWalletState, action: PayloadAction<string | null>) => {
      state.ledgerPath = action.payload;
    },
    setLedgerTransport: (state: IWalletState, action: PayloadAction<any>) => {
      state.ledgerTransport = action.payload;
    },
  },
});

export const {
  setWalletAddress,
  setWalletConnection,
  setAvailableCrown,
  setIsLedger,
  setLedgerPath,
  setLedgerTransport,
} = walletSlice.actions;

export default walletSlice.reducer;
