import ReactPaginate from 'react-paginate';

const Pagination = ({handlePageClick, pageCount, currentPage}: any) => {
  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=" >"
        onPageChange={handlePageClick}
        forcePage={currentPage}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="<"
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="active"
      />
    </div>
  );
};
export default Pagination;
