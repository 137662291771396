import {AiOutlineClose} from 'react-icons/ai';
import StylishDecor from '../common/decorators/StylishDecor.component';
import {DECORATOR} from '../../constants/images';

interface modalProps {
  children?: any;
  title?: string;
  extraFunctions?: any;
  setPopupOpen?: any;
  style?: any;
  isCloseHidden?: boolean;
}

const Modal = ({
  children,
  title,
  extraFunctions,
  setPopupOpen,
  isCloseHidden,
  style = {maxWidth: '543px'},
}: modalProps) => {
  return (
    <div className="overlay">
      <div className="modal-wrapper" style={style}>
        {!isCloseHidden && (
          <AiOutlineClose
            className="modal-close-btn"
            onClick={() => {
              setPopupOpen(false);
              if (extraFunctions) {
                extraFunctions();
              }
            }}
            color="var(--primary-color)"
            size={20}
          />
        )}

        <StylishDecor>
          <div className="modal-content">
            {title && (
              <>
                <p className="r-subtitle-1 modal-title">{title}</p>
                <img
                  className="modal-seperator"
                  src={DECORATOR.MODAL_SEPERATOR}
                  alt="modal seperator"
                />
              </>
            )}
            <div className="model-custom-content">{children}</div>
          </div>
        </StylishDecor>
      </div>
    </div>
  );
};

export default Modal;
