import Modal from '@components/modal/Modal.component';
import {CROWN3D} from '@constants/images';

const LoadingModal = ({
  isStakeLoading,
}: //  isStakeComplete
any) => {
  return (
    isStakeLoading && (
      // !isStakeComplete &&
      <Modal setPopupOpen={() => {}} isCloseHidden>
        <div className="claim__box">
          <p className="crown-loader">LOADING</p>

          <div className="loader-img" style={{textAlign: 'center'}}>
            <img src={CROWN3D} alt="crown-loader" width={150} />
          </div>
        </div>
      </Modal>
    )
  );
};

export default LoadingModal;
