/*eslint-disable */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IRevealStatus {
  revealStarted: boolean;
}

const initialState: IRevealStatus = {
  revealStarted: false,
};

export const revealStatusSlice = createSlice({
  name: 'revealStatus',
  initialState,
  reducers: {
    setLandRevealStarted: (state, action: PayloadAction<boolean>) => {
      state.revealStarted = action.payload;
    },
  },
});

export const {setLandRevealStarted} = revealStatusSlice.actions;
export default revealStatusSlice.reducer;
