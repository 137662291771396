import Button from '@components/common/Button.component';
import Modal from '@components/modal/Modal.component';

const LandRevealApproval = ({
  isApproveModalVisible,
  setApproveModalVisible,
  isApproveModalLoading,
  handleApproveButtonClick,
}: any) => {
  return (
    isApproveModalVisible && (
      <Modal title="Land Reveal Approval" setPopupOpen={setApproveModalVisible}>
        <p style={{textAlign: 'center'}}>
          Please provide approval to the contract for revealing the land certificate.
        </p>
        <br />

        <div style={{margin: '20px', textAlign: 'center'}}>
          <Button
            style={{maxWidth: '300px', width: '100%'}}
            isProgress={isApproveModalLoading}
            text="Approve"
            className="primary"
            onClick={handleApproveButtonClick}
          />
        </div>
      </Modal>
    )
  );
};

export default LandRevealApproval;
