import Button from '@components/common/Button.component';
import Decor from '@components/common/decorators/StylishDecor.component';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import {useEffect, useState} from 'react';
import {setWalletConnection, setWalletAddress} from '@redux/features/wallet/walletSlice';
import {setMintLoading} from '@redux/features/mint/mintSlice';
import {useDispatch} from 'react-redux';
import ConnectWith from '@components/modal/ConnectWith.modal';
import {useNavigate} from 'react-router-dom';

const Error404 = () => {
  const dispatch = useDispatch();
  const [popUpOpen, setPopupOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const eventHandler = (event: any) => {
    if (event.detail.type === 'RESPONSE_ADDRESS') {
      if (event.detail.payload) {
        dispatch(setWalletConnection(true));
        dispatch(setWalletAddress(event.detail.payload));
        dispatch(setMintLoading(false));
      }
    } else {
      console.log('Error on error404 connection!');
      dispatch(setMintLoading(false));
    }
  };

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  return (
    <div className="error__404__wrapper">
      <div className="error__404">
        <Decor className="error404__background">
          <StylishSeperator className="error-heading-sep" width="60%" text="404!" />
          <div className="error404__message">
            <p>{"Looks like the page doesn't seem to exist. "}</p>
            <Button
              style={{width: '301px', margin: '20px 0'}}
              onClick={() => {
                navigate('/');
              }}
              text="Navigate back Home"
              className="primary"
            />
          </div>
        </Decor>
        {popUpOpen && <ConnectWith setConnectWalletPopup={setPopupOpen} />}
      </div>
    </div>
  );
};

export default Error404;
