/*eslint-disable*/
import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Modal from '@components/modal/Modal.component';
import {LEDGER, ICON, HANA} from '@constants/images';
import Button from '@components/common/Button.component';
import {handleLogin} from '@services/Icon.service.js';
import AppIcx from '@ledgerhq/hw-app-icx';
import Transport from '@ledgerhq/hw-transport-webhid';
import LedgerAddressModal from './LedgerAddressModal';
import {
  setLedgerPath,
  setLedgerTransport,
  setWalletAddress,
  setIsLedger,
} from '@redux/features/wallet/walletSlice';

const BASE_PATH = `44'/4801368'/0'/0'`;

const ConnectWith = ({setConnectWalletPopup}) => {
  const [walletConnection, setWalletConnection] = useState(false);

  // Modal
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  // Ledger
  const [transport, setTransport] = useState(null);
  const [icx, setIcx] = useState(null);
  const [walletPaths, setWalletPaths] = useState([]);
  const [hasLedgerSupport, setHasLedgerSupport] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(false);

  // Pagination
  const [currPage, setCurrPage] = useState(1);
  const [wallets, setWallets] = useState([]);

  // const address = useSelector((state) => state.inventory.walletAddress);

  const dispatch = useDispatch();

  const addressPerPage = 8;

  const loadAddresses = async (suppressError, icx) => {
    let i = 0;
    let counter;
    const addresses = [];
    const paths = [];
    setIsAddressLoading(true);
    while (i < addressPerPage) {
      try {
        suppressError = true;
        counter = (currPage - 1) * addressPerPage + i;
        const path = `${BASE_PATH}/${counter}'`;

        let {address} = await icx.getAddress(path, false, true);

        address = address.toString();
        addresses.push(address);
        paths.push(path);
        console.log('Ledger is connected!!!');
        setIsLedger(true);
      } catch (error) {
        if (error?.statusCode === 28428)
          errorMessage = 'Transport channel could not be established';
        // setError(error?.message ?? 'Loading of address failed');

        console.log('Failed to load addresses.');
        console.log(error);
      }
      i++;
    }

    if (addresses.length === 0) {
      // alert("Your ledger is busy, please re-connect it.");
    }

    setIsAddressLoading(false);
    setWallets(addresses);
    setWalletPaths(paths);
  };

  const connectToLedger = () => {
    let suppressError = false;
    setIsConnecting(true);

    Transport.create()
      .then(async (transport) => {
        transport.setDebugMode(true);
        setTransport(transport);
        const icx = new AppIcx(transport);
        setIcx(icx);
        try {
          console.log('Connection successful! ');
          setIsConnected(true);
          setHasLedgerSupport(true);
          await icx.getAddress(`${BASE_PATH}/0'`, false, true);
          await loadAddresses(suppressError, icx);
          setIsConnecting(false);
        } catch (error) {
          alert('Please re-connect your ledger to icon network.');

          // if (suppressError)
          //   setError(error?.message ? error.message : 'Failed connecting to Ledger.');
          // else {
          // setError(
          //   error?.message ? error.message : 'transport channel could not be established!'
          // );
          // }
          console.log('Failed connecting to ledger!');
          setIsConnected(false);
          setIsConnecting(false);
          setHasLedgerSupport(false);
        }
      })
      .catch((error) => {
        if (error.name === 'TransportOpenUserCancelled') {
          console.log('Cancelled!');
        } else {
          // setError(error?.message ? error.message : 'transport channel could not be established!');
          console.log(error);
          alert('Please re-connect your ledger to icon network.');
          // setHasLedgerSupport(false);
        }
        setIsConnecting(false);
      });
  };

  const onNext = () => {
    setCurrPage((prev) => prev + 1);
  };

  const onPrevious = () => {
    setCurrPage((prev) => prev - 1);
  };

  const firstPage = () => {
    setCurrPage(1);
  };

  useEffect(() => {
    if (isConnected && hasLedgerSupport) {
      loadAddresses(false, icx);
    }
  }, [currPage, isConnected, hasLedgerSupport]);

  useEffect(() => {
    if (wallets.length) {
      setWalletConnection(true);
    }
  }, [wallets]);

  const handleConfirmation = async (selectedWallet, path) => {
    dispatch(setWalletAddress(selectedWallet));
    dispatch(setLedgerPath(path));
    dispatch(setLedgerTransport(transport));
    setCurrPage(1);
  };

  const handleClose = () => {
    setWalletConnection(false);
    setCurrPage(1);
  };

  return (
    <>
      <Modal style={{maxWidth: '400px'}} setPopupOpen={setConnectWalletPopup} title="Connect With:">
        {isConnecting ? (
          <p style={{textAlign: 'center'}}>Ledger is connecting...</p>
        ) : (
          <div className="wallet-connect-options-wrapper">
            <div className="wallet-connect-options">
              {/* <div
                onClick={() => {
                  handleLogin();
                  setConnectWalletPopup(false);
                }}
                className="connect-icon-div">
                <img className="connect-icon" src={HANA} alt="icon-logo" />
                <p>Hana</p>
              </div>
              <div className="connect-or-line">
                <hr className="or-seperator" />
                <p>or</p>
                <hr className="or-seperator" />
              </div> */}
              <div
                onClick={() => {
                  handleLogin();
                  setConnectWalletPopup(false);
                }}
                className="connect-icon-div">
                <img className="connect-icon" src={ICON} alt="icon-logo" />
                <p>ICON</p>
              </div>
              <div className="connect-or-line">
                <hr className="or-seperator" />
                <p>or</p>
                <hr className="or-seperator" />
              </div>
              <div
                onClick={() => {
                  connectToLedger();
                }}
                className="connect-ledger-div">
                <img className="connect-ledger" src={LEDGER} alt="ledger-logo" />
                <p>Ledger</p>
              </div>
            </div>
            <div className="connect-revert-btn">
              <Button
                onClick={() => {
                  setConnectWalletPopup(false);
                }}
                style={{width: '200px'}}
                text="Cancel"
                className="primary-outline"
              />
            </div>
          </div>
        )}
      </Modal>
      {walletConnection && (
        <LedgerAddressModal
          isVisible={walletConnection}
          onClose={handleClose}
          onConfirm={handleConfirmation}
          onNext={onNext}
          onPrevious={onPrevious}
          firstPage={firstPage}
          currentPage={currPage}
          addressList={wallets}
          walletPaths={walletPaths}
          isAddressLoading={isAddressLoading}
          setConnectWalletPopup={setConnectWalletPopup}
        />
      )}
    </>
  );
};

export default ConnectWith;
