/*eslint-disable */
import {MANSION} from '@constants/images';

interface ICarouselCoontrolsProps {
  totalPages: number;
  activePage: number;
  setActivePage: any;
}

const CarouselControls = ({totalPages, activePage, setActivePage}: ICarouselCoontrolsProps) => {
  return (
    <>
      {totalPages > 1 && (
        <div className="carousel-controls">
          <div className="carousel-control left">
            <div className="animate__control-bg"></div>
            <img
              onClick={() => {
                activePage === 0 ? setActivePage(totalPages - 1) : setActivePage(activePage - 1);
              }}
              className="carousel-next-left"
              src={MANSION.CAROUSEL_NEXT_ICON}
              alt="next-icon-left"
            />
          </div>
          <div className="carousel-control right">
            <div className="animate__control-bg"></div>
            <img
              onClick={() => {
                activePage === totalPages - 1 ? setActivePage(0) : setActivePage(activePage + 1);
              }}
              className="carousel-next-right"
              src={MANSION.CAROUSEL_NEXT_ICON}
              alt="next-icon-right"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CarouselControls;
