/* eslint-disable */
import {getFilteredLand, getLandMetadataByNftId, getMintedLandWithCount} from '@api/API';
import PropertyCard from '@components/cards/PropertyCard.component';
import Button from '@components/common/Button.component';
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import {DEV_LAND_REVEAL_BUCKET} from '@constants/constants';
import {CERTIFICATE_LOADING, ICON_COLLAPSE, ICON_PLUS} from '@constants/images';
import {getLandMintedCount} from '@services/Icon.service';
import {useEffect, useRef, useState} from 'react';
import {AiFillFilter} from 'react-icons/ai';
import {IoIosCloseCircleOutline} from 'react-icons/io';

const Land = () => {
  const BATCH_SIZE = 20;

  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
  const [topValue, setTopValue] = useState(65);
  const [landData, setLandData] = useState<any>([]);
  const [filteredLandData, setFilteredLandData] = useState<any>([]);
  const [toggleFilter, setToggleFilter] = useState(window.innerWidth > 1730 ? true : false);
  const [collapse, setCollapse] = useState({
    Road: false,
    Ground: false,
    Vegetation: false,
    Vehicle: false,
    Watercraft: false,
    Monument: false,
    Aircraft: false,
    Backyard: false,
    Infrastructure: false,
  });
  const [roadCategories, setRoadCategories] = useState<any>([]);
  const [groundCategories, setGroundCategories] = useState<any>([]);
  const [vegetationCategories, setVegetationCategories] = useState<any>([]);
  const [vehicleCategories, setVehicleCategories] = useState<any>([]);
  const [watercraftCategories, setWatercraftCategories] = useState<any>([]);
  const [monumentCategories, setMonumentCategories] = useState<any>([]);
  const [aircraftCategories, setAircraftCategories] = useState<any>([]);
  const [backyardCategories, setBackyardCategories] = useState<any>([]);
  const [infrastructureCategories, setInfrastructureCategories] = useState<any>([]);
  const [categoriesCount, setCategoreisCount] = useState<any>({});
  const [isFilteredLand, setIsFilteredLand] = useState(false);
  const dataFetchStartIdRef = useRef<number>(1);

  const fetchBatchLandMetadata = async () => {
    const landMintedCount = await getLandMintedCount();
    let endId;

    if (endId === landMintedCount) return;

    endId = dataFetchStartIdRef.current + BATCH_SIZE;
    if (endId > landMintedCount) {
      endId = landMintedCount + 1;
    }

    const promises = [];

    for (let i = dataFetchStartIdRef.current; i < endId; i++) {
      promises.push(getLandMetadataByNftId(i));
    }

    dataFetchStartIdRef.current = endId;
    const metadataArray = await Promise.all(promises);
    const metadata = metadataArray.map((res: any) => res.data);
    setLandData((prevData: any) => [...prevData, ...metadata]);
  };

  const fetchMintedLandWIthCount = async () => {
    const response = await getMintedLandWithCount();
    setCategoreisCount(response['data'].data);
  };

  useEffect(() => {
    fetchMintedLandWIthCount();
  }, []);

  const observerTarget = useRef(null);

  useEffect(() => {
    setShowSkeleton(false);
  }, [landData]);

  useEffect(() => {
    if (
      roadCategories.length <= 0 &&
      groundCategories.length <= 0 &&
      vegetationCategories.length <= 0 &&
      vehicleCategories.length <= 0 &&
      watercraftCategories.length <= 0 &&
      monumentCategories.length <= 0 &&
      aircraftCategories.length <= 0 &&
      backyardCategories.length <= 0 &&
      infrastructureCategories.length <= 0
    ) {
      setFilteredLandData([]);
      setIsFilteredLand(false);
    }
    applyBtnHandler();
  }, [
    roadCategories,
    groundCategories,
    vegetationCategories,
    vehicleCategories,
    watercraftCategories,
    monumentCategories,
    aircraftCategories,
    backyardCategories,
    infrastructureCategories,
  ]);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Adjust the topValue based on the scroll position
      if (scrollY > 10) {
        setTopValue(0); // Reduce the top value when scrolling a little
      } else {
        setTopValue(65); // Restore the original top value
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.scrollY]);

  useEffect(() => {
    if (filteredLandData.length <= 0) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setShowSkeleton(true);
            fetchBatchLandMetadata();
          }
        },
        {threshold: 0.5, rootMargin: '50% 0px'}
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }
  }, [observerTarget]);

  const handleCollapse = (value: string) => {
    switch (value) {
      case 'Road':
        if (!collapse.Road) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Road: !prev.Road,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Road: !prev.Road,
            };
          });
        }
        break;

      case 'Ground':
        if (!collapse.Ground) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Ground: !prev.Ground,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Ground: !prev.Ground,
            };
          });
        }
        break;

      case 'Vegetation':
        if (!collapse.Vegetation) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Vegetation: !prev.Vegetation,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Vegetation: !prev.Vegetation,
            };
          });
        }
        break;

      case 'Vehicle':
        if (!collapse.Vehicle) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Vehicle: !prev.Vehicle,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Vehicle: !prev.Vehicle,
            };
          });
        }
        break;

      case 'Watercraft':
        if (!collapse.Watercraft) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Watercraft: !prev.Watercraft,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Watercraft: !prev.Watercraft,
            };
          });
        }
        break;

      case 'Monument':
        if (!collapse.Monument) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Monument: !prev.Monument,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Monument: !prev.Monument,
            };
          });
        }
        break;

      case 'Aircraft':
        if (!collapse.Aircraft) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Aircraft: !prev.Aircraft,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Aircraft: !prev.Aircraft,
            };
          });
        }
        break;

      case 'Backyard':
        if (!collapse.Backyard) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Backyard: !prev.Backyard,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Backyard: !prev.Backyard,
            };
          });
        }
        break;

      case 'Infrastructure':
        if (!collapse.Infrastructure) {
          setTimeout(() => {
            setCollapse((prev) => {
              return {
                ...prev,
                Infrastructure: !prev.Infrastructure,
              };
            });
          }, 300);
        } else {
          setCollapse((prev) => {
            return {
              ...prev,
              Infrastructure: !prev.Infrastructure,
            };
          });
        }
        break;
    }
  };

  const handleCheckBox = (type: string, subType: string) => {
    switch (type) {
      case 'road':
        if (!roadCategories.includes(subType)) {
          const updatedRoadCategories = [...roadCategories, subType];
          setRoadCategories(updatedRoadCategories);
        } else {
          const filteredArray = roadCategories.filter((roadCat: string) => roadCat !== subType);
          setRoadCategories(filteredArray);
        }
        break;

      case 'ground':
        if (!groundCategories.includes(subType)) {
          const updatedRoadCategories = [...groundCategories, subType];
          setGroundCategories(updatedRoadCategories);
        } else {
          const filteredArray = groundCategories.filter(
            (groundCat: string) => groundCat !== subType
          );
          setGroundCategories(filteredArray);
        }
        break;

      case 'vegetation':
        if (!vegetationCategories.includes(subType)) {
          const updatedVegetationCategories = [...vegetationCategories, subType];
          setVegetationCategories(updatedVegetationCategories);
        } else {
          const filteredArray = vegetationCategories.filter(
            (vegerationCat: string) => vegerationCat !== subType
          );
          setVegetationCategories(filteredArray);
        }
        break;

      case 'vehicle':
        if (!vehicleCategories.includes(subType)) {
          const updatedVehicleCategories = [...vehicleCategories, subType];
          setVehicleCategories(updatedVehicleCategories);
        } else {
          const filteredArray = vehicleCategories.filter(
            (vehicleCat: string) => vehicleCat !== subType
          );
          setVehicleCategories(filteredArray);
        }
        break;

      case 'watercraft':
        if (!watercraftCategories.includes(subType)) {
          const updatedWatercraftCategories = [...watercraftCategories, subType];
          setWatercraftCategories(updatedWatercraftCategories);
        } else {
          const filteredArray = watercraftCategories.filter(
            (watercraftCat: string) => watercraftCat !== subType
          );
          setWatercraftCategories(filteredArray);
        }
        break;

      case 'monument':
        if (!monumentCategories.includes(subType)) {
          const updatedMonumentCategories = [...monumentCategories, subType];
          setMonumentCategories(updatedMonumentCategories);
        } else {
          const filteredArray = monumentCategories.filter(
            (monumentCat: string) => monumentCat !== subType
          );
          setMonumentCategories(filteredArray);
        }
        break;

      case 'aircraft':
        if (!aircraftCategories.includes(subType)) {
          const updatedAircraftCategories = [...aircraftCategories, subType];
          setAircraftCategories(updatedAircraftCategories);
        } else {
          const filteredArray = aircraftCategories.filter(
            (aircraftCat: string) => aircraftCat !== subType
          );
          setAircraftCategories(filteredArray);
        }
        break;

      case 'backyard':
        if (!backyardCategories.includes(subType)) {
          const updatedBackyardCategories = [...backyardCategories, subType];
          setBackyardCategories(updatedBackyardCategories);
        } else {
          const filteredArray = backyardCategories.filter(
            (backyardCat: string) => backyardCat !== subType
          );
          setBackyardCategories(filteredArray);
        }
        break;

      case 'infrastructure':
        if (!infrastructureCategories.includes(subType)) {
          const updatedInfrastructureCategories = [...infrastructureCategories, subType];
          setInfrastructureCategories(updatedInfrastructureCategories);
        } else {
          const filteredArray = infrastructureCategories.filter(
            (infrastructureCat: string) => infrastructureCat !== subType
          );
          setInfrastructureCategories(filteredArray);
        }
        break;
    }
  };

  const applyBtnHandler = async () => {
    setShowSkeleton(true);
    await prepareFilterPayloadAndFiltered();
  };

  //update filterParams
  const prepareFilterPayloadAndFiltered = async () => {
    let objectToSend: {[key: string]: string} = {};
    if (roadCategories) {
      let roadPayload = '';
      for (let count = 0; count < roadCategories.length; count++) {
        if (count == 0) {
          roadPayload += roadCategories[0];
        } else {
          roadPayload += ',' + roadCategories[count];
        }
      }
      objectToSend['Road'] = roadPayload;
    }

    if (groundCategories) {
      let groundPayload = '';
      for (let count = 0; count < groundCategories.length; count++) {
        if (count == 0) {
          groundPayload += groundCategories[0];
        } else {
          groundPayload += ',' + groundCategories[count];
        }
      }
      objectToSend['Ground'] = groundPayload;
    }

    if (vegetationCategories) {
      let vegetationPayload = '';
      for (let count = 0; count < vegetationCategories.length; count++) {
        if (count == 0) {
          vegetationPayload += vegetationCategories[0];
        } else {
          vegetationPayload += ',' + roadCategories[count];
        }
      }
      objectToSend['Vegetation'] = vegetationPayload;
    }

    if (vehicleCategories) {
      let vehiclePayload = '';
      for (let count = 0; count < vehicleCategories.length; count++) {
        if (count == 0) {
          vehiclePayload += vehicleCategories[0];
        } else {
          vehiclePayload += ',' + vehicleCategories[count];
        }
      }
      objectToSend['Vehicle'] = vehiclePayload;
    }

    if (watercraftCategories) {
      let waterCraftPayload = '';
      for (let count = 0; count < watercraftCategories.length; count++) {
        if (count == 0) {
          waterCraftPayload += watercraftCategories[0];
        } else {
          waterCraftPayload += ',' + watercraftCategories[count];
        }
      }
      objectToSend['Watercraft'] = waterCraftPayload;
    }

    if (monumentCategories) {
      let monumentPayload = '';
      for (let count = 0; count < monumentCategories.length; count++) {
        if (count == 0) {
          monumentPayload += monumentCategories[0];
        } else {
          monumentPayload += ',' + monumentCategories[count];
        }
      }
      objectToSend['Monument'] = monumentPayload;
    }

    if (aircraftCategories) {
      let aircraftPayload = '';
      for (let count = 0; count < aircraftCategories.length; count++) {
        if (count == 0) {
          aircraftPayload += aircraftCategories[0];
        } else {
          aircraftPayload += ',' + aircraftCategories[count];
        }
      }
      objectToSend['Aircraft'] = aircraftPayload;
    }

    if (backyardCategories) {
      let backyardPayload = '';
      for (let count = 0; count < backyardCategories.length; count++) {
        if (count == 0) {
          backyardPayload += backyardCategories[0];
        } else {
          backyardPayload += ',' + backyardCategories[count];
        }
      }
      objectToSend['Backyard'] = backyardPayload;
    }

    if (infrastructureCategories) {
      let infrastructurePayload = '';
      for (let count = 0; count < infrastructureCategories.length; count++) {
        if (count == 0) {
          infrastructurePayload += infrastructureCategories[0];
        } else {
          infrastructurePayload += ',' + infrastructureCategories[count];
        }
      }
      objectToSend['Infrastructure'] = infrastructurePayload;
    }

    const getFilteredLandResponse = await getFilteredLand(objectToSend);
    setFilteredLandData(getFilteredLandResponse.data.filterLocations);
    setShowSkeleton(false);
    setIsFilteredLand(true);
  };

  return (
    <div className="land-filter">
      {toggleFilter && (
        <div className="land-filter-wrapper" style={{position: 'fixed', top: topValue}}>
          <div className="filter-close-btn-wrapper">
            <IoIosCloseCircleOutline
              className="filter-close-icon"
              onClick={() => setToggleFilter(!toggleFilter)}
            />
            <h5 className="land-filter-title">Filter</h5>
            <div className="filter-wrapper-inside">
              {/* FOR Road */}

              {!categoriesCount['Road'] &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(() => (
                  <>
                    <div className="skeleton loading-skeleton filter-header"></div>
                    {[1, 2, 3, 4].map(() => (
                      <div className="skeleton-wrapper">
                        <p className=" skeleton sk-subtitile"></p>
                        <p className="skeleton sk-checkbox"></p>
                      </div>
                    ))}
                  </>
                ))}

              {/* Road filer content */}
              {categoriesCount['Road'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Road</p>
                    {!collapse.Road ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Road')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Road')}
                      />
                    )}
                  </div>
                  {!collapse.Road ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Road']).map((roadCat: any, index: number) => {
                        return (
                          <div className="filter-body" key={index}>
                            <div className="filter-item">
                              <div className="container">
                                <input
                                  type="checkbox"
                                  id={roadCat}
                                  checked={roadCategories.includes(roadCat) ? true : false}
                                  onClick={() => handleCheckBox('road', roadCat)}
                                />
                                <label htmlFor={`${roadCat}`} className="checkmark"></label>
                                <label htmlFor={`${roadCat}`} className="label">
                                  {roadCat}
                                </label>
                              </div>
                            </div>
                            <p className="filtered-result-count">
                              {categoriesCount['Road'][roadCat]}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}
              {/* Ground Filter content */}
              {categoriesCount['Ground'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Ground</p>
                    {!collapse.Ground ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Ground')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Ground')}
                      />
                    )}
                  </div>
                  {!collapse.Ground ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Ground']).map(
                        (groundCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="filter-item">
                                <div className="container">
                                  <input
                                    type="checkbox"
                                    id={groundCat}
                                    checked={groundCategories.includes(groundCat) ? true : false}
                                    onClick={() => handleCheckBox('ground', groundCat)}
                                  />
                                  <label htmlFor={groundCat} className="checkmark"></label>
                                  <label htmlFor={groundCat} className="label">
                                    {groundCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Ground'][groundCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}

              {/* Vegetation Filter content */}
              {categoriesCount['Vegetation'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Vegetation</p>
                    {!collapse.Vegetation ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Vegetation')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Vegetation')}
                      />
                    )}
                  </div>
                  {!collapse.Vegetation ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Vegetation']).map(
                        (vegetaionCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="filter-item">
                                <div className="container">
                                  <input
                                    type="checkbox"
                                    id={vegetaionCat}
                                    checked={
                                      vegetationCategories.includes(vegetaionCat) ? true : false
                                    }
                                    onClick={() => handleCheckBox('vegetation', vegetaionCat)}
                                  />
                                  <label htmlFor={vegetaionCat} className="checkmark"></label>
                                  <label className="label" htmlFor={vegetaionCat}>
                                    {vegetaionCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Vegetation'][vegetaionCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}

              {/* Vehicle Filter content */}
              {categoriesCount['Vehicle'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Vehicle</p>
                    {!collapse.Vehicle ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Vehicle')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Vehicle')}
                      />
                    )}
                  </div>
                  {!collapse.Vehicle ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Vehicle']).map(
                        (vehicleCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="filter-item">
                                <div className="container">
                                  <input
                                    type="checkbox"
                                    id={vehicleCat}
                                    checked={vehicleCategories.includes(vehicleCat) ? true : false}
                                    onClick={() => handleCheckBox('vehicle', vehicleCat)}
                                  />
                                  <label htmlFor={vehicleCat} className="checkmark"></label>
                                  <label className="label" htmlFor={vehicleCat}>
                                    {vehicleCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Vehicle'][vehicleCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}

              {/* Watercraft Filter content */}
              {categoriesCount['Watercraft'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Watercraft</p>
                    {!collapse.Watercraft ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Watercraft')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Watercraft')}
                      />
                    )}
                  </div>
                  {!collapse.Watercraft ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Watercraft']).map(
                        (watercraftCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="container">
                                <div className="filter-item">
                                  <input
                                    type="checkbox"
                                    id={watercraftCat}
                                    checked={
                                      watercraftCategories.includes(watercraftCat) ? true : false
                                    }
                                    onClick={() => handleCheckBox('watercraft', watercraftCat)}
                                  />
                                  <label htmlFor={watercraftCat} className="checkmark"></label>
                                  <label className="label" htmlFor={watercraftCat}>
                                    {watercraftCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Watercraft'][watercraftCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}

              {/* Monument Filter content */}
              {categoriesCount['Monument'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Monument</p>
                    {!collapse.Monument ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Monument')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Monument')}
                      />
                    )}
                  </div>
                  {!collapse.Monument ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Monument']).map(
                        (monumentCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="filter-item">
                                <div className="container">
                                  <input
                                    type="checkbox"
                                    id={monumentCat}
                                    checked={
                                      monumentCategories.includes(monumentCat) ? true : false
                                    }
                                    onClick={() => handleCheckBox('monument', monumentCat)}
                                  />
                                  <label htmlFor={monumentCat} className="checkmark"></label>
                                  <label className="label" htmlFor={monumentCat}>
                                    {monumentCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Monument'][monumentCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}

              {/* Aircraft Filter content */}
              {categoriesCount['Aircraft'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Aircraft</p>
                    {!collapse.Aircraft ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Aircraft')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Aircraft')}
                      />
                    )}
                  </div>
                  {!collapse.Aircraft ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Aircraft']).map(
                        (aircraftCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="filter-item">
                                <div className="container">
                                  <input
                                    type="checkbox"
                                    id={aircraftCat}
                                    checked={
                                      aircraftCategories.includes(aircraftCat) ? true : false
                                    }
                                    onClick={() => handleCheckBox('aircraft', aircraftCat)}
                                  />
                                  <label htmlFor={aircraftCat} className="checkmark"></label>
                                  <label className="label" htmlFor={aircraftCat}>
                                    {aircraftCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Aircraft'][aircraftCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}

              {/* Monument Backyard content */}
              {categoriesCount['Backyard'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Backyard</p>
                    {!collapse.Backyard ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Backyard')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Backyard')}
                      />
                    )}
                  </div>
                  {!collapse.Backyard ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Backyard']).map(
                        (backyardCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="filter-item">
                                <div className="container">
                                  <input
                                    type="checkbox"
                                    id={backyardCat}
                                    checked={
                                      backyardCategories.includes(backyardCat) ? true : false
                                    }
                                    onClick={() => handleCheckBox('backyard', backyardCat)}
                                  />
                                  <label htmlFor={backyardCat} className="checkmark"></label>
                                  <label className="label" htmlFor={backyardCat}>
                                    {backyardCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Backyard'][backyardCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}

              {/* Infrastructure Filter content */}
              {categoriesCount['Infrastructure'] && (
                <div>
                  <div className="filter-header">
                    <p className="filter-header-titile">Infrastructure</p>
                    {!collapse.Infrastructure ? (
                      <img
                        src={ICON_COLLAPSE.SOURCE}
                        alt="header-icon"
                        className="header-icon"
                        onClick={() => handleCollapse('Infrastructure')}
                      />
                    ) : (
                      <img
                        src={ICON_PLUS.SOURCE}
                        alt="collapse-plus"
                        className="header-icon-plus"
                        onClick={() => handleCollapse('Infrastructure')}
                      />
                    )}
                  </div>
                  {!collapse.Infrastructure ? (
                    <div className="filtered-content">
                      {Object.keys(categoriesCount['Infrastructure']).map(
                        (infrastructureCat: any, index: number) => {
                          return (
                            <div className="filter-body" key={index}>
                              <div className="filter-item">
                                <div className="container">
                                  <input
                                    type="checkbox"
                                    id={infrastructureCat}
                                    checked={
                                      infrastructureCategories.includes(infrastructureCat)
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      handleCheckBox('infrastructure', infrastructureCat)
                                    }
                                  />
                                  <label htmlFor={infrastructureCat} className="checkmark"></label>
                                  <label className="label" htmlFor={infrastructureCat}>
                                    {infrastructureCat}
                                  </label>
                                </div>
                              </div>
                              <p className="filtered-result-count">
                                {categoriesCount['Infrastructure'][infrastructureCat]}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <hr className="horizontal-line" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="all-lands-outer-wrapper">
        <StylishSeperator text="Lands" className="stylish-info-seperator" />

        <div className="filer-btn-wrapper">
          <Button
            text="Filter"
            icon={<AiFillFilter className="filter-icon" />}
            className="primary filter-toggle-btn"
            iconPosition="end"
            onClick={() => {
              setToggleFilter(!toggleFilter);
            }}
            style={{width: '150px', height: '50px'}}
          />
        </div>
        <br />
        <StylishDecor className="all-lands-inner-wrapper bg-backdrop" style={{margin: '28px 20px'}}>
          <div className="owned-nfts">
            {isFilteredLand && filteredLandData.length <= 0 && !showSkeleton && (
              <div>
                <p className="land__not__found"> Land with selected category not found.</p>
              </div>
            )}

            {Object.values(filteredLandData.length >= 1 && isFilteredLand && filteredLandData).map(
              (property: any, index: number) => {
                return (
                  <PropertyCard
                    key={`${index}_key`}
                    imgSource={`${
                      DEV_LAND_REVEAL_BUCKET
                        ? DEV_LAND_REVEAL_BUCKET + property.preview
                        : property.image_url
                    }`}
                    zone={property.zone}
                    name={property.name ? property.name : `Emerald City Land #${property.nft_Id}`}
                    district={property.district}
                    number={property.balance}
                    nftId={property.nft_Id}
                  />
                );
              }
            )}

            {Object.values(landData.length >= 1 && !isFilteredLand && landData).map(
              (property: any, index: number) => {
                return (
                  <PropertyCard
                    key={`${index}_key`}
                    imgSource={`${
                      DEV_LAND_REVEAL_BUCKET
                        ? DEV_LAND_REVEAL_BUCKET + property.preview
                        : property.image_url
                    }`}
                    zone={property.zone}
                    name={property.name ? property.name : `Emerald City Land #${property.nft_Id}`}
                    district={property.district}
                    number={property.balance}
                    nftId={property.nft_id}
                  />
                );
              }
            )}

            {showSkeleton &&
              filteredLandData.length <= 0 &&
              [1, 2, 3, 4].map((index) => {
                const key = `key_${index}`;
                return <img src={CERTIFICATE_LOADING} width="250px" height={'250px'} />;
              })}
          </div>
          <div ref={observerTarget} />
        </StylishDecor>
      </div>
    </div>
  );
};

export default Land;
