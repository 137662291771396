/*eslint-disable */
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

interface mintProgressTypes {
  mintCount: number;
  mintLoading: boolean;
  mintStatus: 'start' | 'complete' | 'failed';
}

interface mintState {
  mintProgress: mintProgressTypes;
  recentMintedNFT: Array<String>;
  isMintCompleted: boolean;
}

const initialState: mintState = {
  mintProgress: {
    mintCount: 1,
    mintLoading: false,
    mintStatus: 'start',
  },
  recentMintedNFT: [],
  isMintCompleted: false,
};

export const mintSlice = createSlice({
  name: 'mint',
  initialState,
  reducers: {
    increment: (state) => {
      state.mintProgress.mintCount += 1;
    },
    decrement: (state) => {
      state.mintProgress.mintCount -= 1;
    },
    setMintCount: (state, action: PayloadAction<number>) => {
      state.mintProgress.mintCount = action.payload;
    },
    setRecentMintedNFT: (state, action: PayloadAction<Array<String>>) => {
      state.recentMintedNFT = action.payload;
    },
    setMintLoading: (state, action: PayloadAction<boolean>) => {
      state.mintProgress.mintLoading = action.payload;
    },
    setMintStatus: (state, action: PayloadAction<any>) => {
      state.mintProgress.mintStatus = action.payload;
    },
    setMintCompleted: (state, action: PayloadAction<boolean>) => {
      state.isMintCompleted = action.payload;
    },
  },
});

export const {
  increment,
  decrement,
  setMintLoading,
  setMintCount,
  setRecentMintedNFT,
  setMintStatus,
  setMintCompleted,
} = mintSlice.actions;

export default mintSlice.reducer;
