import BribeCard from '@components/cards/BribeCard.component';

const Bribe = ({addressBribes, mintedZoneCountBribe, showSkeleton, tabType}: any) => {
  return (
    <div>
      {Math.max(...(Object.values(addressBribes) as any)) > 0 &&
        !showSkeleton &&
        tabType === 'BRIBE' && (
          <div className="owned-nfts">
            {/* eslint-disable */}
            {Object.keys(addressBribes).map((key, index) => {
              const indexs = `key_${index}`;
              const number = Object.values(addressBribes)[index] as string;
              if (Number(number) > 0) {
                return (
                  <BribeCard
                    key={indexs}
                    type={key}
                    number={number}
                    totalCount={mintedZoneCountBribe[key]}
                  />
                );
              }
            })}
          </div>
        )}
      <div className="show-skeleton-inventory">
        {showSkeleton &&
          tabType === 'BRIBE' &&
          [1, 2, 3, 4].map((index) => {
            const key = `key_${index}`;
            return (
              <div
                key={key}
                style={{width: '247px', height: '335px', borderRadius: '5px'}}
                className="skeleton skeleton-default-width"></div>
            );
          })}
      </div>
    </div>
  );
};
export default Bribe;
