import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '@redux/store';
import {
  setLandRevealCount,
  setRevealCompleted,
  setRevealStatus,
  setRevealedNFT,
  setRevealedNftsMetadata,
  setRevealLoading,
} from '@redux/features/landReveal/landRevealSlice';

import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import Button from '@components/common/Button.component';
import CongratulationCarousel from '@components/landReveal/congratulation/CongratulationCarousel.component';
import CongratulationSummary from '@components/landReveal/congratulation/CongratulationSummary.component';
import toasterService from '@components/common/toaster/ToasterService.component';

const Congratulation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [summaryPage, setSummaryPage] = useState<boolean>(false);
  const [showIndex, setShowIndex] = useState(0);
  const {revealedNFT, revealedNftsMetadata} = useSelector((state: RootState) => state.landReveal);
  const {walletAddress} = useSelector((state: RootState) => state.inventory);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    toasterService.set({
      title: 'INFO',
      message:
        'Share your lands on Twitter (X) with the tag #EmeraldCityLands, tag us (@GangstaVerse) and get a chance to win a Golden Key or a  LootCrate on Base blockchain.',
      type: 'success',
    });
  }, []);

  const resetLandReveal = () => {
    dispatch(setLandRevealCount(1));
    dispatch(setRevealStatus('start'));
    dispatch(setRevealedNFT([]));
    dispatch(setRevealedNftsMetadata([]));
    dispatch(setRevealCompleted(false));
    dispatch(setRevealLoading(false));
  };

  return (
    <div className="congrat-wrapper">
      <div className="congrat-content-wrapper">
        <div className="congrat-header">
          <StylishSeperator className="stylish-info-seperator" text="Congratulations!" />
          <div className="r-body-1 text-center">
            <p className="nft__receive__message">
              {revealedNFT.length === 1
                ? `Congratulations! You are the owner of a ${revealedNftsMetadata[0]?.zone} property in The Emerald City.`
                : `Congratulations! You are the owner of ${revealedNFT.length} ${revealedNftsMetadata[0]?.zone} properties in The Emerald City.`}
            </p>
            {!summaryPage && (
              <p style={{paddingTop: '10px', fontSize: '12px', color: 'grey'}}>
                <span style={{color: 'var(--primary-color)'}}>Info:</span> Scroll down to see more
                details about the property.
              </p>
            )}
          </div>
        </div>
        <div className="congrat-body">
          {!summaryPage ? (
            <CongratulationCarousel
              showIndex={showIndex}
              setShowIndex={setShowIndex}
              setSummaryPage={setSummaryPage}
            />
          ) : (
            <CongratulationSummary />
          )}
        </div>
        <div className="congrat-btns">
          {summaryPage && (
            // <Button
            //   className="primary"
            //   text="Reveal Again"
            //   onClick={() => {
            //     resetLandReveal();
            //     // navigate(`/land`);
            //   }}
            //   style={{width: '301px'}}
            // />
            <Button
              text="Go to inventory"
              onClick={() => {
                resetLandReveal();
                navigate(`/inventory/${walletAddress}`);
              }}
              className="primary-outline"
              style={{width: '301px'}}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
