/* eslint-disable */
import {useNavigate, useLocation} from 'react-router-dom';
import ReactModal from 'react-modal';
import {useState} from 'react';
import {Unity, useUnityContext} from 'react-unity-webgl';
import CustomModal from '@components/modal/Modal.component';
import {AiOutlineCloseCircle} from 'react-icons/ai';

const LandViewer = ({setShowModal, showModal, queryParams = ''}: any) => {
  const navigate = useNavigate();
  const [popupOpen, setPopupOpen] = useState(true);
  const location = useLocation();

  const handleCloseModal = async () => {
    await unload();
    navigate(`${location.pathname}?${new URLSearchParams(queryParams)}`);
    setShowModal(false);
    setPopupOpen(true);
  };
  const buildUrl = 'Build';

  const {unityProvider, isLoaded, loadingProgression, unload} = useUnityContext({
    dataUrl: buildUrl + '/WebGL.data.unityweb',
    frameworkUrl: buildUrl + '/WebGL.framework.js.unityweb',
    codeUrl: buildUrl + '/WebGL.wasm.unityweb',
    loaderUrl: buildUrl + '/WebGL.loader.js',
    // streamingAssetsUrl: "StreamingAssets",
    // companyName: "DefaultCompany",
    // productName: "EmeraldCityViewer",
    // productVersion: "0.1",
  });
  const loadingPercentage = Math.round(loadingProgression * 100);
  return (
    <ReactModal
      isOpen={showModal}
      className="Modal"
      contentLabel="Minimal Modal Example"
      overlayClassName="Overlay"
      onRequestClose={handleCloseModal}>
      {!isLoaded && popupOpen && (
        <CustomModal extraFunctions={handleCloseModal} setPopupOpen={setPopupOpen}>
          <div className="loading-percentage-div">
            <p>Loading</p>
            <p className="loading-percentage"> {loadingPercentage}%</p>
          </div>
        </CustomModal>
      )}
      {/* {isLoaded === false && (
          <div className="loading-overlay">
            <img id="loadingGIF" src={BANK_3D_VIEW_LOADER} />
            <p className="loading-percentage">Loading... ({loadingPercentage}%)</p>
          </div>
        )} */}
      {isLoaded && (
        <AiOutlineCloseCircle
          onClick={() => {
            handleCloseModal();
          }}
          size={20}
          className="modal-close-btn"
        />
      )}
      <Unity
        style={{width: '60vw', height: '80vh', borderRadius: '14px'}}
        unityProvider={unityProvider}
      />
    </ReactModal>
  );
};

export default LandViewer;
