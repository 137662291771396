/* eslint-disable */
import {useState, useEffect} from 'react';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import {
  getOwnedNFTs,
  checkICONaddressValidity,
  getBribesOf,
  getZoneDetailsOfBribe,
  getZoneDetailsOfCertificate,
  getOwnedProperties,
  getLandDetail,
  getBalanceOfLand,
} from '@services/Icon.service';
import {useParams, useNavigate} from 'react-router-dom';
import Bribe from '@components/Inventory/Bribe.component';
import Certificate from '@components/Inventory/Certificate.component';
import Button from '@components/common/Button.component';
import {FiExternalLink} from 'react-icons/fi';
import Property from '@components/Inventory/Property.component';
import {getLandMetadata, syncUserAddress} from '@api/API';
import Pagination from '@pagination/Pagination';
import toasterService from '@components/common/toaster/ToasterService.component';
import {useSelector} from 'react-redux';
import {RootState} from '@redux/store';

const Inventory = () => {
  interface ItabType {
    type: 'BRIBE' | 'CERTIFICATE' | 'PROPERTY';
  }

  const [showSkeletonCertificate, setShowSkeletonCertificate] = useState(false);
  const [showSkeletonBribe, setShowSkeletonBribe] = useState(false);
  const [showSkeletonProperty, setShowSkeletonProperty] = useState(false);

  const [addressCertificates, setAddressCertificates] = useState({});
  const [addressBribes, setAddressBribes] = useState({});
  const [addressProperties, setAddressProperties] = useState<any>([]);
  const [mintedZoneCountCertificate, setMintedZoneCountCertificate] = useState<any>({});
  const [mintedZoneCountBribe, setMintedZoneCountBribe] = useState<any>({});

  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [addressSync, setAddressSync] = useState<boolean>(false);

  const {revealStarted} = useSelector((state: RootState) => state.revealStatus);
  const [tabType, setTabType] = useState<ItabType>(
    revealStarted ? {type: 'PROPERTY'} : {type: 'CERTIFICATE'}
  );

  const {address} = useParams();
  const navigate = useNavigate();

  const fetchMetadataWithRetries = async (metaId: any, nftId: any, name: string) => {
    const MAX_RETRIES = 3;
    let attempts = 0;

    while (attempts < MAX_RETRIES) {
      try {
        const {data} = await getLandMetadata(metaId);
        data['balance'] = await getBalanceOfLand(address, nftId);
        data['nft_id'] = +nftId;
        data['name'] = name;
        return data;
      } catch (err) {
        attempts++;
        if (address && !addressSync) {
          setAddressSync(true);
        }
        await new Promise((resolve) => setTimeout(resolve, 10000));
      }
    }
    toasterService.set({
      title: 'ERROR',
      message: `Land Sync Started! Please try again later.`,
      type: 'error',
    });
    return null;
  };

  useEffect(() => {
    const getOwnedNft = async () => {
      const isValid = await checkICONaddressValidity(address);
      if (isValid) {
        setShowSkeletonCertificate(true);
        const ownedNfts = await getOwnedNFTs(address as any);
        const certificateMintedZoneCount: any = {};

        for (let i = 0; i < 8; i++) {
          const zoneDetailsCertificate = await getZoneDetailsOfCertificate(i + 1);
          certificateMintedZoneCount[zoneDetailsCertificate.zoneName] = Number(
            zoneDetailsCertificate.totalSupply
          );
        }
        setMintedZoneCountCertificate({...certificateMintedZoneCount});
        setAddressCertificates(ownedNfts);

        setShowSkeletonCertificate(false);
      } else {
        setAddressCertificates({});
        navigate('/error/');
      }
    };
    getOwnedNft();
  }, []);

  useEffect(() => {
    const getOwnedNft = async () => {
      const isValid = await checkICONaddressValidity(address);
      if (isValid) {
        setShowSkeletonBribe(true);
        setAddressBribes({});
        setMintedZoneCountBribe({});
        const bribeWithMapping: any = {};
        const bribeWithTotalCount: any = {};
        for (let i = 0; i < 8; i++) {
          const bribes = await getBribesOf(address, i + 1);
          const bribeZoneDetails = await getZoneDetailsOfBribe(i + 1);
          bribeWithMapping[bribeZoneDetails.zoneName] = bribes;
          bribeWithTotalCount[bribeZoneDetails.zoneName] = Number(bribeZoneDetails.totalSupply);
        }
        setAddressBribes({...bribeWithMapping});
        setMintedZoneCountBribe({...bribeWithTotalCount});
        setShowSkeletonBribe(false);
      } else {
        setAddressBribes({});
        console.log('address is not valid');
        navigate('/error/');
      }
    };
    getOwnedNft();
  }, []);

  useEffect(() => {
    if (address && addressSync) {
      syncUserAddress(address);
    }
  }, [addressSync]);

  useEffect(() => {
    (async () => {
      const isValid = await checkICONaddressValidity(address);

      if (isValid) {
        setShowSkeletonProperty(true);
        setAddressProperties([]);
        const {nftList, nftOwned, totalPage} = await getOwnedProperties(address, currentPage + 1);
        setTotalPages(totalPage);

        const propertiesMetadata: any = [];
        if (totalPage > 0) {
          for (const nftId of nftList) {
            try {
              const {metaId, name} = await getLandDetail(nftId);
              const metadata = await fetchMetadataWithRetries(metaId, nftId, name);
              if (metadata) {
                setAddressProperties((prevAddressProperties: any) => [
                  ...prevAddressProperties,
                  metadata,
                ]);
              }
            } catch (err) {
              console.error('Error fetching metadata: ', err);
            }
          }
        }
        setShowSkeletonProperty(false);
      } else {
        setAddressProperties([]);
        navigate('/error/');
      }
    })();
  }, [currentPage, address]);

  useEffect(() => {
    if (addressProperties.length > 0) setShowSkeletonProperty(false);
  }, [addressProperties]);

  return (
    <div className="inventory-wrapper">
      <StylishSeperator text="Inventory" className="stylish-info-seperator" />
      {/* <p className="text-center r-body-1">All your assets are available here</p> */}
      <div style={{textAlign: 'center'}}>
        <Button
          text="View profile in Craft"
          icon={<FiExternalLink />}
          onClick={() => {
            window.open(`https://craft.network/user/${address}/collectibles/owned`, '_blank');
          }}
          className="primary desk-view-craft-link-btn"
          style={{maxWidth: '247px', width: '100%'}}
        />
      </div>
      <div
        className="filter-tab-inventory"
        style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
        <div className="tab-inventory">
          {revealStarted && (
            <button
              onClick={() => {
                setTabType({type: 'PROPERTY'});
              }}
              type="button"
              className={`inventory-tab-left ${
                tabType.type === 'PROPERTY' ? 'property-selected' : 'property-unselected'
              }`}>
              Land Property
            </button>
          )}
          <button
            onClick={() => {
              setTabType({type: 'CERTIFICATE'});
            }}
            type="button"
            className={`${
              !revealStarted
                ? `inventory-tab-left ${
                    tabType.type === 'CERTIFICATE' ? '' : 'property-unselected'
                  }`
                : 'inventory-tab-mid'
            } ${
              tabType.type === 'CERTIFICATE' ? 'certificate-selected' : 'certificate-unSelected'
            }`}>
            Land Certificate
          </button>
          <button
            onClick={() => {
              setTabType({type: 'BRIBE'});
            }}
            type="button"
            className={`inventory-tab-right ${
              tabType.type === 'BRIBE' ? 'bribe-selected' : 'bribe-unSelected'
            }`}>
            Bribe Token
          </button>
        </div>
        <Button
          text="View profile in Craft"
          icon={<FiExternalLink />}
          onClick={() => {
            window.open(`https://craft.network/user/${address}/collectibles/owned`, '_blank');
          }}
          className="primary mob-view-craft-link-btn"
          style={{
            maxWidth: '247px',
            width: '100%',
            height: '43px',
            padding: '12px 16px',
          }}
        />
        {/* <div className="filter-inventory">
          <div className="filter-property">
            <label className="r-body-2 filter-text" htmlFor="filterProperty">
              Property type: {'  '}
            </label>
            <select
              onChange={(e) => {
                console.log(e.target.value);
              }}
              className="r-subtitle-2"
              id="filterProperty">
              <option value="All">All</option>
              <option value="Airport">Airport</option>
              <option value="Commercial">Commercial</option>
              <option value="Education">Education</option>
              <option value="Governmental">Governmental</option>
              <option value="Health">Health</option>
              <option value="Industrial">Industrial</option>
              <option value="Recreational">Recreational</option>
              <option value="Residential">Residential</option>
            </select>
          </div>
          <div className="sort-by">
            <label className="r-body-2 filter-text" htmlFor="sortBy">
              Sort by:{'  '}
            </label>
            <select
              onChange={(e) => {
                console.log(e.target.value);
              }}
              className="r-subtitle-2"
              id="sortBy">
              <option value="Low to high">Low to high</option>
              <option value="Low to high">High to low</option>
            </select>
          </div>
        </div> */}
      </div>
      <StylishDecor style={{margin: '28px 20px'}} className="inventory-stylish-decor bg-backdrop">
        <Certificate
          addressCertificates={addressCertificates}
          showSkeleton={showSkeletonCertificate}
          tabType={tabType.type}
          mintedZoneCountCertificate={mintedZoneCountCertificate}
        />

        <Bribe
          addressBribes={addressBribes}
          showSkeleton={showSkeletonBribe}
          tabType={tabType.type}
          mintedZoneCountBribe={mintedZoneCountBribe}
        />
        <Property
          addressProperties={addressProperties}
          showSkeleton={showSkeletonProperty}
          tabType={tabType.type}
        />

        {Object.keys(addressCertificates).length <= 0 &&
          !showSkeletonCertificate &&
          tabType.type === 'CERTIFICATE' && (
            <p className="no-nft-found">
              It seems that you don’t have any Land Certificate Collection yet.
            </p>
          )}
        {Math.max(...(Object.values(addressBribes) as any)) <= 0 &&
          !showSkeletonBribe &&
          tabType.type === 'BRIBE' && (
            <p className="no-nft-found">It seems that you don’t have any Bribe Collection yet.</p>
          )}

        {addressProperties.length <= 0 && !showSkeletonProperty && tabType.type === 'PROPERTY' && (
          <p className="no-nft-found">It seems that you don’t have any Land Collection yet.</p>
        )}

        {addressProperties.length > 0 && !showSkeletonProperty && tabType.type === 'PROPERTY' && (
          <Pagination
            pageCount={totalPages}
            handlePageClick={(event: any) => setCurrentPage(event.selected)}
            currentPage={currentPage}
          />
        )}
      </StylishDecor>
    </div>
  );
};

export default Inventory;
