/*eslint-disable */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import PropertyCarousel from '@components/property/PropertyCarousel.component';
import PropertyInfo from '@components/property/PropertyInfo.component';
import CarouselPagination from '@components/Carousel/CarouselPagination.component';
import CarouselControls from '@components/Carousel/CarouselControls.component';
import {RootState} from '@redux/store';
import {Link, useNavigate} from 'react-router-dom';
import {TbArrowUpRight} from 'react-icons/tb';
import LandViewer from '@components/land-viewer/LandViewer';
import toasterService from '@components/common/toaster/ToasterService.component';
import {DEV_LAND_REVEAL_BUCKET, LAND_CRAFT_URL} from '@constants/constants';
import Button from '@components/common/Button.component';

interface ICongratulationCarousel {
  showIndex: number;
  setShowIndex: any;
  setSummaryPage: any;
}

const CongratulationCarousel = ({
  showIndex,
  setShowIndex,
  setSummaryPage,
}: ICongratulationCarousel) => {
  const navigate = useNavigate();
  const {revealedNftsMetadata} = useSelector((state: RootState) => state.landReveal);

  // const [showIndex, setShowIndex] = useState(0);
  const [showNft, setShowNft] = useState<any>(revealedNftsMetadata[showIndex]);
  const [show3DModal, setShow3DModal] = useState<boolean>(false);
  const [propertyImgObjects, setPropertyImgObjects] = useState<any>({});

  const [scrollToastrShown, setScrollToastrShown] = useState<any>(false);

  useEffect(() => {
    setShowNft(revealedNftsMetadata[showIndex]);
  }, [showIndex]);

  useEffect(() => {
    if (revealedNftsMetadata.length === 0 || revealedNftsMetadata === null) return;

    const cacheImages = async () => {
      try {
        const promises = revealedNftsMetadata.map((metadata) => {
          return new Promise((resolve, reject) => {
            const imagesLoaded = [];
            const totalImages = metadata.images.length;

            metadata.images.forEach((image: any) => {
              if (!propertyImgObjects[image]) {
                const img = new Image();
                // img.src = process.env.REACT_APP_LAND_BUCKET_API + '/' + image;
                // img.src = metadata.image_uri + image;
                img.src = DEV_LAND_REVEAL_BUCKET
                  ? DEV_LAND_REVEAL_BUCKET + image
                  : metadata.image_uri + image;

                img.onload = () => {
                  imagesLoaded.push(image);
                  setPropertyImgObjects((prevPropertyImgObjects: any) => ({
                    ...prevPropertyImgObjects,
                    [image]: img,
                  }));
                  if (imagesLoaded.length === totalImages) {
                    resolve('');
                  }
                };

                img.onerror = (error) => {
                  reject(error);
                };
              } else {
                resolve('');
              }
            });
          });
        });

        await Promise.all(promises);
      } catch (error) {
        console.error('Error caching images:', error);
      }
    };

    cacheImages();
  }, [revealedNftsMetadata, propertyImgObjects]);

  const handleOpen3DModal = () => {
    // TODO: modal view make it dynamic
    console.log(`${location.pathname}?${showNft.coordinates.x}x${showNft.coordinates.y}`);
    // navigate(`${location.pathname}?${showNft.coordinates.x}x${showNft.coordinates.y}`);
    navigate(`${location.pathname}?5023`);
    setShow3DModal(true);
  };

  useEffect(() => {
    if (scrollToastrShown) return;
    if (showIndex === revealedNftsMetadata.length - 1) {
      toasterService.set({
        title: 'INFO',
        message: 'Scroll down to Continue',
        type: 'success',
      });
      setScrollToastrShown(true);
    }
  }, [showIndex]);

  return (
    <div className="congrat-carousel carousel-wrapper">
      {revealedNftsMetadata.length > 1 && (
        <CarouselPagination
          totalPages={revealedNftsMetadata.length}
          activePage={showIndex}
          setActivePage={setShowIndex}
        />
      )}
      <div className="carousel-content carousel-inner">
        <div className="carousel-items">
          <div className="property-detail-wrapper">
            <div className="left-section">
              <PropertyCarousel
                images={showNft.images}
                previewImage={showNft.preview}
                imageUrl={DEV_LAND_REVEAL_BUCKET ? DEV_LAND_REVEAL_BUCKET : showNft.image_uri}
                // render3DObject
                spotlight
                // handleOpen3DModal={handleOpen3DModal}
              />
              <div className="detail-link-wrapper">
                <Link
                  className="links property-detail-link primary-color"
                  to={`/land?token=${+showNft.nftId}`}
                  style={{paddingRight: '20px'}}>
                  View details page <TbArrowUpRight />
                </Link>
                <Link
                  onClick={() => {
                    // window.open(`${LAND_CRAFT_URL}`);
                    window.open(`https://craft.network/nft/emerald-city-land:${+showNft.nftId}`);
                  }}
                  className="links property-detail-link primary-color"
                  to="#">
                  View in Craft <TbArrowUpRight />
                </Link>
              </div>
            </div>
            {showIndex === revealedNftsMetadata.length - 1 && (
              <div style={{margin: '0 auto'}}>
                <Button
                  className="primary"
                  text="show all revealed lands"
                  onClick={() => setSummaryPage(true)}
                  style={{width: '301px'}}
                />
              </div>
            )}
            <div className="right-section">
              <PropertyInfo propertyMetadata={showNft} />
            </div>
          </div>
        </div>
        {revealedNftsMetadata.length > 1 && (
          <CarouselControls
            totalPages={revealedNftsMetadata.length}
            activePage={showIndex}
            setActivePage={setShowIndex}
          />
        )}
      </div>

      {show3DModal && <LandViewer setShowModal={setShow3DModal} showModal={show3DModal} />}
    </div>
  );
};

export default CongratulationCarousel;
