import Modal from '@components/modal/Modal.component';
import Button from '@components/common/Button.component';

const WalletSuccess = ({setConnectWalletModal, walletAddress}: any) => {
  return (
    <Modal title="Congratulations!" setPopupOpen={setConnectWalletModal}>
      <div className="connect-success">
        <p className="text-center wallet-connected-success">
          You are successfully connected to your wallet.
        </p>
        <p className="text-center wallet-connected-address">{walletAddress}</p>
        <Button
          onClick={() => {
            setConnectWalletModal(false);
          }}
          style={{width: '200px'}}
          className="primary"
          text="Continue"
        />
      </div>
    </Modal>
  );
};
export default WalletSuccess;
