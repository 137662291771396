/*eslint-disable */
interface ICarouselPaginationProps {
  totalPages: number;
  activePage: number;
  setActivePage: any;
}

const CarouselPagination = ({totalPages, activePage, setActivePage}: ICarouselPaginationProps) => {
  return (
    <div className="dot-wrapper">
      {[...Array(totalPages).keys()].map((index) => {
        return (
          <div
            className={`carousel-dot ${activePage == index ? 'active-dot' : ''}`}
            key={index}
            onClick={() => setActivePage(index)}></div>
        );
      })}
    </div>
  );
};

export default CarouselPagination;
