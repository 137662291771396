import {CERTIFICATE_TYPES} from '@constants/images';
import PlainDecor from '@components/common/decorators/PlainDecor.component';
import ImageLoader from '@loader/ImageLoader';

interface congratsProps {
  type: string;
}

const CongratulationCard = ({type}: congratsProps) => {
  return (
    <div className="card-wrapper">
      <div className="card-certificate">
        <ImageLoader src={CERTIFICATE_TYPES[type]} alt="card-certificate" />
      </div>
      <div className="card-box-part">
        <PlainDecor padding="15px">
          <div className="card-decor-box">
            <div className="card-type">
              <p className="r-caption card-type-text">Type</p>
              <p className="r-body-1 card-type-certificate-text">{type}</p>
            </div>
          </div>
        </PlainDecor>
      </div>
    </div>
  );
};

export default CongratulationCard;
