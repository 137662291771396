/* eslint-disable */

import {useEffect} from 'react';
import {MINT_STATES} from '@constants/states';
import {RootState} from '@redux/store';
import {useDispatch, useSelector} from 'react-redux';
import Congratulation from '@pages/LandReveal/Congratulation.component';
import LandReveal from '@pages/LandReveal/LandReveal.component';
import {getLandMetadata} from '@api/API';
import {
  setRevealLoading,
  setRevealedNftsMetadata,
} from '@redux/features/landReveal/landRevealSlice';
import {getLandDetail} from '@services/Icon.service';
import {PLAY_LAND_REVEAL_SOUND} from '@constants/audio';

const MintLand = () => {
  const dispatch = useDispatch();
  const {revealedNFT, revealedNftsMetadata, revealProgress} = useSelector(
    (state: RootState) => state.landReveal
  );

  useEffect(() => {
    if (revealedNFT.length < 1 && revealProgress.revealStatus !== MINT_STATES.COMPLETE) return;
    (async () => {
      const promises = [];

      for (const nft of revealedNFT) {
        promises.push(
          getLandMetadata(+nft.nftMetaId).then(async ({data}) => {
            const {name} = await getLandDetail(nft.nftId);

            data['name'] = name;
            data['nftId'] = nft.nftId;
            data['nftMetaId'] = nft.nftMetaId;
            return data;
          })
        );
      }

      await Promise.all(promises).then((metadata) => {
        dispatch(setRevealedNftsMetadata(metadata));
        dispatch(setRevealLoading(false));
        PLAY_LAND_REVEAL_SOUND();
      });
    })();
  }, [revealedNFT, revealProgress]);

  return (
    <div>
      {revealedNFT.length > 0 &&
      revealProgress.revealStatus === MINT_STATES.COMPLETE &&
      revealedNftsMetadata.length ? (
        <Congratulation />
      ) : (
        <LandReveal />
      )}
    </div>
  );
};

export default MintLand;
