import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
/* eslint-disable */
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import { CROWN } from '@constants/images';
import { toFixedNoRounding } from '@services/Misc.service';
import { useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { addressPoolWiseTotalAllocated } from '@services/Icon.service';

const CrownSources = ({
  isClaimComplete,
  setRewardPopupOpen,
  stakeRewardDB,
  lpRewardDB,
  boom500kRewardDB,
  lpCrownRewardDB,
}: any) => {
  const { walletAddress } = useSelector((state: RootState) => state.inventory);

  const [airdropAmount, setAirdropAmount] = useState<number | string>('-');

  useEffect(() => {
    const getCrownDetails = async () => {
      if (walletAddress) {
        // Setting airdrop amount
        setAirdropAmount(await addressPoolWiseTotalAllocated(walletAddress));
      }
    };

    getCrownDetails();
  }, [isClaimComplete]);

  return (
    <div className="crown__earning__sources">
      <StylishDecor width="100%">
        <p className="r-body-1">How do I earn CROWN?</p>
        <PlainSeperator width="50%" />
        {/* <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime perferendis a sed
          cupiditate totam, eos, nesciunt.
        </p> */}
        <div className="crown__source__item">
          <div className="crown__source__top">
            <div className="crown__source__name">
              <b>
                <a
                  href="https://docs.gangstabet.io/tutorials/earn-usdcrown/voting-gangstabet-prep#how-to-delegate-icx-votes-to-gangstabet-p-rep"
                  rel="noreferrer"
                  target="_blank">
                  {' '}
                  1. Staking to Gangstabet Prep <FiExternalLink />
                </a>
              </b>
            </div>
            <div className="crown__source__total">
              <img src={CROWN} alt="crown" />
              <span>
                <span>
                  {typeof stakeRewardDB?.rewards?.staking_gg_reward === 'number'
                    ? toFixedNoRounding(
                      Number(stakeRewardDB.rewards.staking_gg_reward)
                    ).toLocaleString()
                    : '-'}
                </span>
              </span>
              &nbsp;
              <span> CROWN</span>
              {typeof stakeRewardDB?.rewards?.staking_gg_reward === 'number' &&
                Number(stakeRewardDB.rewards.staking_gg_reward) > 0 && (
                  // eslint-disable-next-line
                  <div className="crown__show__details" onClick={() => setRewardPopupOpen(true)}>
                    Show Details
                  </div>
                )}
            </div>
          </div>
          <div className="crown__source__bottom">
            The Gangstabet team is an ICON P-Rep and is continuously building and contributing to
            the ICON community. Anyone who delegates their ICX votes to the GangstaBet P-Rep will
            earn CROWN tokens from a monthly pool of 600,000 CROWN tokens which will run for at
            least six months. The CROWN reward will depend on the quantity and period of the ICX
            vote. Delegate your ICX votes to GangstaBet P-Rep to earn CROWN.
          </div>
          <PlainSeperator width="100%" style={{ margin: '20px 0 10px 0' }} />
        </div>

        <div className="crown__source__item">
          <div className="crown__source__top">
            <div className="crown__source__name">
              <b>
                <a
                  // href="https://docs.gangstabet.io/tutorials/earn-usdcrown/adding-liquidity-supply#how-to-add-liquidity"
                  rel="noreferrer"
                  target="_blank">
                  {' '}
                  2. Providing Liquidity (GBET/bnUSD)
                </a>
              </b>
            </div>
            <div className="crown__source__total">
              <img src={CROWN} alt="crown" />
              <span>{toFixedNoRounding(Number(lpRewardDB)).toLocaleString()}</span>
              &nbsp;
              <span className='grayScaleTheText'> CROWN</span>
              {Number(lpRewardDB) > 0 && (
                // eslint-disable-next-line
                <div className="crown__show__details" onClick={() => setRewardPopupOpen(true)}>
                  Show Details
                </div>
              )}
            </div>
          </div>
          <div className="crown__source__bottom">
            We have stopped providing incentives for GBET/bnUSD pool currently.
          </div>
          <PlainSeperator width="100%" style={{ margin: '20px 0 10px 0' }} />
        </div>

        <div className="crown__source__item">
          <div className="crown__source__top">
            <div className="crown__source__name">
              <b>
                <a target="_blank" href='https://app.balanced.network/trade/supply/CROWN_sICX' rel="noreferrer" >3. Providing Liquidity (CROWN/sICX) <FiExternalLink /></a>
              </b>
            </div>
            <div className="crown__source__total">
              <img src={CROWN} alt="crown" />
              <span>{toFixedNoRounding(Number(lpCrownRewardDB)).toLocaleString()}</span>
              &nbsp;
              <span> CROWN</span>
            </div>
          </div>
          <div className="crown__source__bottom">
            CROWN is distributed among those who supply liquidity in the CROWN/sICX pool. Provide
            liquidity to CROWN/sICX pair in Balanced.
          </div>
          <PlainSeperator width="100%" style={{ margin: '20px 0 10px 0' }} />
        </div>

        <div className="crown__source__item">
          <div className="crown__source__top">
            <div className="crown__source__name">
              <b>
                <a
                  href="https://gangstabet.medium.com/crown-token-land-ownership-certificates-bank-forex-1e1a31eb247e"
                  rel="noreferrer"
                  target="_blank">
                  {' '}
                  3. Airdrop
                  <FiExternalLink />
                </a>
              </b>
            </div>
            <div className="crown__source__total">
              <img src={CROWN} alt="crown" />
              <span>
                {' '}
                {typeof airdropAmount === 'number'
                  ? toFixedNoRounding(Number(airdropAmount)).toLocaleString()
                  : '-'}
              </span>
              &nbsp;
              <span> CROWN</span>
            </div>
          </div>
          <div className="crown__source__bottom">
            One time airdrop by holding GangstaBet NFTs (Snapshot taken on 5th August, 2022) The
            CROWN will also be airdropped to the GangstaBet holders of Level 2 and higher. The
            snapshot has already been taken on the 5th of August, 2022.
          </div>
          <PlainSeperator width="100%" style={{ margin: '20px 0 10px 0' }} />
        </div>
        <div className="crown__source__item">
          <div className="crown__source__top">
            <div className="crown__source__name">
              <b>Total CROWN</b>
            </div>
            <div className="crown__source__total">
              <img src={CROWN} alt="crown" />
              <span>
                {typeof stakeRewardDB?.rewards?.staking_gg_reward === 'number'
                  ? toFixedNoRounding(
                    Number(lpRewardDB) +
                    Number(stakeRewardDB?.rewards?.staking_gg_reward) +
                    Number(airdropAmount) +
                    Number(lpCrownRewardDB)
                  ).toLocaleString()
                  : '-'}
              </span>
              &nbsp;
              <span> CROWN</span>
            </div>
          </div>
        </div>
      </StylishDecor>
    </div>
  );
};

export default CrownSources;
