import LAND_REVEAL from '@assets/audio/landReveal.mp3';

const SOUNDS = {
  LAND_REVEAL: new Audio(LAND_REVEAL),
};

const PLAY_LAND_REVEAL_SOUND = () => SOUNDS.LAND_REVEAL.play();

// eslint-disable-next-line
export {PLAY_LAND_REVEAL_SOUND};
