import Modal from '@components/modal/Modal.component';
import Button from '@components/common/Button.component';

const DisconnectWarn = ({setDisconnectWalletModal, disconnectUserWallet}: any) => {
  return (
    <Modal
      title="Disconnect your wallet
  "
      setPopupOpen={setDisconnectWalletModal}>
      <div className="disconnect-wallet">
        <p className="text-center">Please confirm to disconnect your wallet. </p>
        <div className="confirm-cancel-btns">
          <Button
            onClick={disconnectUserWallet}
            style={{width: '150px'}}
            className="primary"
            text="Confirm"
          />
          <Button
            onClick={() => {
              setDisconnectWalletModal(false);
            }}
            style={{width: '150px'}}
            className="primary-outline"
            text="Cancel"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DisconnectWarn;
