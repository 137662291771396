/*eslint-disable*/
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import type {RootState} from '@redux/store';
import {FiExternalLink} from 'react-icons/fi';
import {RiHistoryFill} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';
import {BANK_PAGE, CROWN} from '@constants/images';
import ImageLoader from '@loader/ImageLoader';
import ReactTooltip from 'react-tooltip';

import Decor from '@components/common/decorators/StylishDecor.component';
import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import DepositWithdrawModal from '@components/modal/DepositWithdraw.modal';
import PlainDecor from '@components/common/decorators/PlainDecor.component';
import Button from '@components/common/Button.component';
import ArtView from '@components/common/ArtView.component';
import {getAvailableCrown, getCrownPerXCrown, getTotalXCrown} from '@services/Crown.service';
import {
  setWalletConnection,
  setWalletAddress,
  setAvailableCrown,
} from '@redux/features/wallet/walletSlice';
import Modal from '@components/modal/Modal.component';
import {setBankAmount, setBankLoading, setBankStatus} from '@redux/features/bank/bankSlice';
import {monthAbbrev, toFixedNoRounding} from '@services/Misc.service';
import {getTxHistory, getBankCountTransaction} from '@services/Icon.service';
import LandViewer from '@components/land-viewer/LandViewer';
import {GiArrowhead} from 'react-icons/gi';

const Bank = () => {
  const [crownPerXCrown, setCrownPerXCrown] = useState<Number>(0);
  const [txType, setTxType] = useState<string>('');
  const {bankProcess} = useSelector((state: RootState) => state.bank);
  const [totalUserXCrown, setTotalUserXCrown] = useState<Number>(0);
  const [bankEarning, setBankEarning] = useState<Number>(0);
  const [bankTotalDeposit, setBankTotalDeposit] = useState<Number>(0);
  const [bankTotalWithdraw, setBankTotalWithdraw] = useState<Number>(0);
  const [crownInBank, setCrownInBank] = useState<Number>(0);

  // Modals
  // Primary modal for prompting deposit/withdraw amount.
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  // Deposit/Withdraw failure/success modal.
  const [isNestedModalVisible, setNestedModalVisible] = useState<boolean>(true);
  // Transaction modal.
  const [isTxModalVisible, setTxModalVisible] = useState<boolean>(false);

  const [showBankArtView, setShowBankArtView] = useState(false);
  const [txHistoryData, setTxHistoryData] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [txHistoryCurrentPage, setTxHistoryCurrentPage] = useState(1);
  const [txHistoryTotalPageCount, setTotalPage] = useState(1);
  const txHistoryLimit = 10;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {walletAddress, isWalletConnected, availableCrown} = useSelector(
    (state: RootState) => state.inventory
  );

  const eventHandler = (event: any) => {
    if (event.detail.type === 'RESPONSE_ADDRESS') {
      if (event.detail.payload) {
        dispatch(setWalletConnection(true));
        dispatch(setWalletAddress(event.detail.payload));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  useEffect(() => {
    const getPagination = async () => {
      if (isWalletConnected) {
        const getTxHistoryCount = await getBankCountTransaction(walletAddress);
        setTotalPage(Math.ceil(Number(getTxHistoryCount) / txHistoryLimit));
        const txHistory = await getTxHistory(
          walletAddress!,
          txHistoryLimit,
          txHistoryLimit * (txHistoryCurrentPage - 1)
        );
        setTxHistoryData(txHistory);
      } else {
        setTxHistoryCurrentPage(1);
        setTotalUserXCrown(0);
        setBankEarning(0);
        setBankTotalDeposit(0);
        setBankTotalWithdraw(0);
        setCrownInBank(0);
        setTxHistoryData([]);
      }
    };
    getPagination();
  }, [txHistoryCurrentPage, isWalletConnected, isModalVisible]);

  useEffect(() => {
    const getCrownInfo = async () => {
      const price = await getCrownPerXCrown();
      setCrownPerXCrown(price as Number);
      if (isWalletConnected) {
        const totalXCrown = await getTotalXCrown(walletAddress!);
        setTotalUserXCrown(totalXCrown as Number);

        dispatch(
          setAvailableCrown(
            toFixedNoRounding(Number(await getAvailableCrown(walletAddress!)), 4) as string
          )
        );
        const getTxHistoryCount = await getBankCountTransaction(walletAddress);
        const count = Math.ceil(Number(getTxHistoryCount) / txHistoryLimit);
        const promises = [];
        for (let i = 0; i < count; i++) {
          const txHistory = getTxHistory(walletAddress!, txHistoryLimit, txHistoryLimit * i);
          promises.push(txHistory);
        }
        // let totalEarning = 0;
        let totalDepoist = 0;
        let totalWithdraw = 0;
        await Promise.all(promises).then((res) => {
          res.map((totalTx) => {
            totalTx.map((val: any) => {
              if (val.type === 'deposit') {
                totalDepoist += Number(val.amount);
                // totalEarning -= Number(val.amount);
              } else if (val.type === 'withdraw') {
                totalWithdraw += Number(val.amount);
                // totalEarning += Number(val.amount);
              }
            });
          });
        });
        const userTotalCrown: any = totalXCrown * price;
        const earning = (totalWithdraw - totalDepoist) / 10 ** 18;
        const totalUserEarning = userTotalCrown + earning;
        setBankEarning(Number(totalUserEarning));
        setBankTotalDeposit(totalDepoist / 10 ** 18);
        setBankTotalWithdraw(totalWithdraw / 10 ** 18);
        setCrownInBank(userTotalCrown);
      } else {
        setTotalUserXCrown(0);
        setBankEarning(0);
        setBankTotalDeposit(0);
        setBankTotalWithdraw(0);
        setCrownInBank(0);
      }
    };
    getCrownInfo();
  }, [isModalVisible, isWalletConnected]);

  // useEffect(() => {
  //   if (!isLedger) {
  //     setTotalUserXCrown(0);
  //     setTxHistoryData([]);
  //   }
  // }, []);

  const changeVisibilityWithtxType = async (type: string) => {
    setTxType(type);
    setModalVisible(true);
  };

  const handleOpenModal = () => {
    navigate('/bank?3178');
    setShowModal(true);
  };

  const clearRedux = () => {
    dispatch(setBankAmount(0));
    dispatch(setBankLoading(false));
    dispatch(setBankStatus('start'));
    setNestedModalVisible(true);
  };

  return (
    <div className="bank-wrapper">
      <ReactTooltip className="opaque" multiline={true} />

      <div className="bank-card">
        <Decor className="bank__main__background">
          <StylishSeperator className="stylish-info-seperator" text="BANK" />
          <div className="bank__main">
            <div className="bank__image">
              <ImageLoader
                onClick={() => {
                  setShowBankArtView(true);
                }}
                skeletonStyle={{
                  width: '300px',
                  height: '270px',
                  marginBottom: '130px',
                  borderRadius: '5px',
                }}
                src={BANK_PAGE.BANK}
                alt="bank"
              />
              {/* <img
                onClick={() => {
                  setShowBankArtView(true);
                }}
                src={BANK_PAGE.BANK}
                alt="bank"
              /> */}
              <div className="bank-landViewer-div">
                <Button
                  style={{width: '300px'}}
                  text="View Bank"
                  className="primary-outline"
                  onClick={() => {
                    handleOpenModal();
                  }}
                />
                <p className="bank__image_bottom_text">
                  <a
                    href="https://gangstabet.medium.com/a-step-by-step-guide-to-depositing-withdrawing-crowns-to-from-the-bank-84b451a46102"
                    target="_blank">
                    Learn more about bank <FiExternalLink />
                  </a>
                </p>
              </div>
            </div>
            <div className="bank__details">
              <strong style={{color: 'var(--primary-color)'}}>Stake your&nbsp;</strong>
              {'  '}
              <img src={CROWN} alt="crown logo" />
              <strong style={{color: '#079B9D', margin: '0 5px'}}>CROWN</strong> <br />
              <p className="r-body-1 bank__info_text">
                The bank collects 10% from all activities in the Emerald City.
              </p>
              <PlainSeperator />
              <p className="crown__ratio">xCROWN - CROWN Ratio</p>
              <div style={{color: '#068385'}}>
                <div className="crown__ratio__value">
                  ( 1 xCROWN ={' '}
                  {Number(crownPerXCrown) === 0
                    ? '-'
                    : toFixedNoRounding(Number(crownPerXCrown), 4)}{' '}
                  CROWN )
                </div>
              </div>
              <PlainDecor edgeStyleEnabled>
                <div className="bank__deposit__box">
                  <p className="r-subtitle-2 bank__deposit_info">Your bank deposits</p>
                  <div className="bank__deposit__xcrown">
                    <img src={CROWN} alt="crown logo" />
                    <p className="bank__xcrown__deposit_amount h6">
                      {toFixedNoRounding(Number(totalUserXCrown)).toLocaleString()}
                    </p>
                    <p className="bank__xcrown__unit r-caption">xCROWN</p>
                  </div>
                  <div>=</div>
                  <div className="bank__deposit__crown">
                    <img src={CROWN} alt="crown logo" />
                    <p className="bank__crown__deposit_amount h6">
                      {toFixedNoRounding(
                        Number(totalUserXCrown) * Number(crownPerXCrown),
                        4
                      ).toLocaleString()}
                    </p>
                    <p className="bank__crown__unit r-caption">CROWN</p>
                  </div>
                  <div
                    className="total_earning"
                    data-tip={` Total Deposit (till date): ${toFixedNoRounding(
                      bankTotalDeposit,
                      4
                    ).toLocaleString()} CROWN <br/>Total Withdraw (till date): ${toFixedNoRounding(
                      bankTotalWithdraw,
                      4
                    ).toLocaleString()} CROWN <br/> In Bank: ${toFixedNoRounding(
                      crownInBank,
                      4
                    ).toLocaleString()} CROWN `}
                    data-place="top">
                    <p className="total_earning_text">Earning (till date): </p>
                    <img src={CROWN} alt="crown logo" />
                    <p className="total_earning_amt">
                      {toFixedNoRounding(Number(bankEarning), 4).toLocaleString()}
                    </p>
                    <p className=" earning_uint r-caption">CROWN</p>
                  </div>
                </div>
              </PlainDecor>
              <br />
              {txHistoryData.length === 0 ? (
                <>
                  {/* eslint-disable-next-line */}
                  <strong
                    className="crown__tx__history disabled"
                    onClick={() => {}}
                    style={{color: 'silver'}}>
                    <RiHistoryFill /> View transaction history
                  </strong>
                </>
              ) : (
                <>
                  {/* eslint-disable-next-line */}
                  <strong className="crown__tx__history" onClick={() => setTxModalVisible(true)}>
                    {' '}
                    <RiHistoryFill /> View transaction history
                  </strong>
                </>
              )}
              <div className="bank-btns">
                <Button
                  style={{width: '301px'}}
                  onClick={() => {
                    if (isWalletConnected) {
                      changeVisibilityWithtxType('Deposit');
                    }
                  }}
                  text="Deposit"
                  className={`primary ${!isWalletConnected && 'disabled'}`}
                />
                <Button
                  style={{width: '301px'}}
                  onClick={() => {
                    if (isWalletConnected) {
                      changeVisibilityWithtxType('Withdraw');
                    }
                  }}
                  text="Withdraw"
                  className={`primary-outline  ${!isWalletConnected && 'disabled'}`}
                />{' '}
              </div>
            </div>
          </div>
        </Decor>
      </div>
      <div className="bank__text__extra">
        Your CROWN increases as the xCROWN multiplier goes up, and can be withdrawn at any time.
      </div>

      {isModalVisible && (
        <DepositWithdrawModal
          crown={availableCrown}
          text={txType}
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          totalUserXCrown={totalUserXCrown}
          crownPerXCrown={crownPerXCrown}
        />
      )}

      {isTxModalVisible && (
        <Modal
          style={{maxWidth: '680px', width: '95%', margin: 'auto'}}
          title="Transaction history"
          setPopupOpen={setTxModalVisible}>
          {/* <p style={{textAlign: 'center'}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames diam facilisi sodales
            adipiscing. Dictumst
          </p> */}
          <br />
          {txHistoryData.length > 0 ? (
            <div className="bank__breakdown">
              <div className="individual__breakdown">
                <div>Timestamp</div>
                <div>CROWN</div>
                <div>xCROWN</div>
              </div>
              {txHistoryData.map((item: any) => (
                <div className="individual__breakdown" key={item?.timestamp}>
                  <div>
                    {monthAbbrev[new Date(item.timestamp * 0.001).getMonth()]}
                    {'  '}
                    {new Date(item.timestamp * 0.001).getDate()}
                    {'  '}
                    {new Date(item.timestamp * 0.001).getFullYear()}
                  </div>
                  <div>
                    {toFixedNoRounding(item.amount / 10 ** 18)} &nbsp;
                    {item.type === 'deposit' ? (
                      <GiArrowhead style={{transform: 'rotate(270deg)'}} color="red" />
                    ) : (
                      <GiArrowhead style={{transform: 'rotate(90deg)'}} color="green" />
                    )}
                  </div>
                  <div>
                    {toFixedNoRounding(item.xCrown / 10 ** 18)} &nbsp;
                    {item.type === 'withdraw' ? (
                      <GiArrowhead style={{transform: 'rotate(270deg)'}} color="red" />
                    ) : (
                      <GiArrowhead style={{transform: 'rotate(90deg)'}} color="green" />
                    )}
                  </div>
                </div>
              ))}
              <div className="txHistoryPagination">
                <p
                  onClick={() => {
                    1 < txHistoryCurrentPage && setTxHistoryCurrentPage(txHistoryCurrentPage - 1);
                  }}
                  className={` ${
                    txHistoryCurrentPage === 1 ? 'disable-previous-page' : 'tx-previous-page'
                  }`}>
                  {'<'}
                </p>
                <p>{txHistoryCurrentPage}</p>
                <p
                  className={` ${
                    txHistoryCurrentPage === txHistoryTotalPageCount
                      ? 'disable-next-page'
                      : 'tx-next-page'
                  }`}
                  onClick={() => {
                    txHistoryTotalPageCount > txHistoryCurrentPage &&
                      setTxHistoryCurrentPage(txHistoryCurrentPage + 1);
                  }}>
                  {'>'}
                </p>
              </div>
            </div>
          ) : (
            <p style={{textAlign: 'center', color: '#aa9b46'}}> Transaction History is empty!</p>
          )}
        </Modal>
      )}

      {showBankArtView && <ArtView setShowImage={setShowBankArtView} image={BANK_PAGE.BANK} />}

      {bankProcess.bankStatus === 'complete' && isNestedModalVisible ? (
        <Modal
          isCloseHidden
          title={`${txType} successful!`}
          // setPopupOpen={setNestedModalVisible}
          // extraFunctions={clearRedux}
        >
          <p style={{textAlign: 'center'}}>
            {txType === 'Withdraw'
              ? `${bankProcess.amount} CROWN has been withdrawn successfully.`
              : `${bankProcess.amount} CROWN has been deposited successfully.`}
          </p>
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <Button
              text="Continue"
              className="primary"
              style={{width: '280px'}}
              onClick={() => {
                setNestedModalVisible(false);
                clearRedux();
              }}
            />
          </div>
        </Modal>
      ) : (
        <div>
          {bankProcess.bankStatus === 'failed' && isNestedModalVisible ? (
            <Modal
              title={`${txType} Failed!`}
              setPopupOpen={setNestedModalVisible}
              extraFunctions={clearRedux}>
              <p>
                {txType === 'Withdraw'
                  ? `There is an issue withdrawing your amount. Please try again`
                  : `There is an issue depositing your amount. Please try again`}
              </p>
            </Modal>
          ) : null}
        </div>
      )}

      {showModal && <LandViewer setShowModal={setShowModal} showModal={showModal} />}
    </div>
  );
};

export default Bank;
