import {CERTIFICATE_TYPES} from '@constants/images';
import PlainDecor from '@components/common/decorators/PlainDecor.component';
import ImageLoader from '@loader/ImageLoader';
import {MAPPING_LOC_WITH_TYPES} from '@services/Misc.service';
import {config} from '@config/config';

interface inventoryProps {
  type: string;
  number: string;
  totalCount: string;
}

const CertificateCard = ({type, number, totalCount}: inventoryProps) => {
  return (
    /* eslint-disable-next-line */
    <div
      onClick={() => {
        window.open(
          `https://craft.network/nft/${config.scores.LOC_SCORE}:${MAPPING_LOC_WITH_TYPES[type]}`,
          '_blank'
        );
      }}
      className="card-wrapper certificate-card-wrapper">
      <div className="card-certificate">
        <ImageLoader src={CERTIFICATE_TYPES[type]} alt="card-certificate" />
      </div>
      <div className="card-box-part">
        <PlainDecor padding="15px">
          <div className="card-decor-box">
            <div className="card-type">
              <p className="r-caption card-type-text">Type</p>
              <p className="r-body-1 card-type-certificate-text">{type}</p>
              <p className="inventory-type-num">
                {Number(number)} of {Number(totalCount)}
              </p>
            </div>
          </div>
        </PlainDecor>
      </div>
    </div>
  );
};

export default CertificateCard;
