import ImageLoader from '@loader/ImageLoader';
import {CANVAS_CERTIFICATE} from '@constants/images';

interface inventoryProps {
  type: string;
  number: number;
  totalCount: number;
}

const Certificate = ({type, number, totalCount}: inventoryProps) => {
  return (
    <div className="certificate-wrapper">
      <div className="certificate-content">
        <ImageLoader
          src={CANVAS_CERTIFICATE[type]}
          alt="certificate-canvas"
          className="canvas-certificate-skeleton"
          skeletonStyle={{width: '528px', height: '355px'}}
        />
        <div className="certificate-subtitle">
          you own <span>{number}</span> out of {totalCount} {type} zone certificate
        </div>
      </div>
    </div>
  );
};

export default Certificate;
