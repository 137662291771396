import {DECORATOR} from '@constants/images';

interface plainDecorProps {
  width?: string;
  height?: string;
  children: any;
  padding?: string;
  edgeStyleEnabled?: boolean;
  onClick?: any;
}

const PlainDecor = ({
  width = '100%',
  padding,
  height,
  children,
  edgeStyleEnabled,
  onClick,
}: plainDecorProps) => {
  return (
    <div style={{width, height}} className="plain-decor-wrapper">
      {edgeStyleEnabled && (
        <>
          <img
            className="plain-decor-edge-tl"
            src={DECORATOR.PLAIN_EDGES}
            alt="plai edge seperator"
          />

          <img
            className="plain-decor-edge-tr"
            src={DECORATOR.PLAIN_EDGES}
            alt="plai edge seperator"
          />

          <img
            className="plain-decor-edge-bl"
            src={DECORATOR.PLAIN_EDGES}
            alt="plai edge seperator"
          />

          <img
            className="plain-decor-edge-br"
            src={DECORATOR.PLAIN_EDGES}
            alt="plai edge seperator"
          />
        </>
      )}

      {/* eslint-disable-next-line */}
      <div style={{padding}} className="plain-decor-content" onClick={onClick}>
        {children}
      </div>
    </div>
  );
};

export default PlainDecor;
