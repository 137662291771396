/*eslint-disable */
import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
import LocationMarkMap from '@components/map/locationMarkMap.component';
import PropertyLocation from '@components/property/PropertyLocation.component';

interface IPropertyInfo {
  propertyMetadata: any;
}

const PropertyInfo = ({propertyMetadata}: IPropertyInfo) => {
  const {x, y} = propertyMetadata.coordinates;

  return (
    <div className="property-detail-content">
      <p className="property-name">{propertyMetadata.name}</p>
      <PlainSeperator style={{marginTop: '18px'}} />
      <PropertyLocation
        coordinate={propertyMetadata.coordinates}
        district={propertyMetadata.district}
        zone={propertyMetadata.zone}
        infrastructure={
          propertyMetadata.attributes[
            propertyMetadata.attributes.findIndex((attr: any) => attr.key === 'Infrastructure')
          ]['value']
        }
        landAttributes={propertyMetadata.attributes}
      />
      <PlainSeperator style={{marginTop: '18px'}} />
      <p className="property-description">{propertyMetadata.description}</p>
      <PlainSeperator style={{marginTop: '18px'}} />
      <LocationMarkMap lat={+x} lng={+y} />
    </div>
  );
};

export default PropertyInfo;
