/* eslint-disable no-unused-vars */
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import {LAND_REVEAL_MEDIUM_ARTICLE_URL} from '@constants/constants';
import {setLandRevealStarted} from '@redux/features/landReveal/revealStatusSlice';
import {useEffect, useState} from 'react';
import {HiOutlineExternalLink} from 'react-icons/hi';
import {useDispatch} from 'react-redux';

const CountdownTime = () => {
  const [dayRemaning, setDayRemaining] = useState<number>(0);
  const [hourRemaning, setHourRemaining] = useState<number>(0);
  const [minuteRemaning, setMinuteRemaining] = useState<number>(0);
  const [secondRemaning, setSecondRemaining] = useState<number>(0);

  const dispatch = useDispatch();

  const revealDate = Date.parse(
    process.env.REACT_APP_REVEAL_START_TIME || '2023-08-03 19:59:00+0545'
  );

  // const revealDate = Date.parse(REVEAL_START_TIME);

  const calculateTimeRemaining = () => {
    const currentTs = Math.floor(new Date().getTime());
    const timeDiff = revealDate - currentTs;

    if (timeDiff < 0) {
      dispatch(setLandRevealStarted(true));
      return;
    }

    setDayRemaining(Math.floor(timeDiff / (1000 * 60 * 60 * 24)));
    setHourRemaining(Math.floor((timeDiff / (1000 * 60 * 60)) % 24));
    setMinuteRemaining(Math.floor((timeDiff / 1000 / 60) % 60));
    setSecondRemaining(Math.floor((timeDiff / 1000) % 60));
  };
  useEffect(() => {
    // calculateTimeRemaining();
    const interval = setInterval(() => {
      calculateTimeRemaining();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StylishDecor className="mint-status-wrapper" style={{width: '100%'}}>
      <div className="mint-status-inner-wrapper">
        <p className="heading-name">land reveal will be live in</p>
        <div className="counter-wrapper">
          <p>
            {dayRemaning} days, {hourRemaning} hours, {minuteRemaning} minutes, {secondRemaning}{' '}
            seconds
          </p>
        </div>
        {/* eslint-disable-next-line */}
        <div
          className="medium-link"
          onClick={() => {
            window.open(LAND_REVEAL_MEDIUM_ARTICLE_URL, '_blank');
          }}>
          <p>Read More about Land Reveal in our Medium article</p>
          <HiOutlineExternalLink />
        </div>
      </div>
    </StylishDecor>
  );
};

export default CountdownTime;
