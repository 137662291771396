/*eslint-disable */
import {MANSION} from '@constants/images';
import {useState} from 'react';
import ArtView from '@components/common/ArtView.component';
import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
import {LAND_VIEWER} from '@constants/images';
import {useNavigate, useLocation} from 'react-router-dom';
import LandViewer from '@components/land-viewer/LandViewer';

const Carousel = () => {
  const [imageExternalIndex, setImageExternalIndex] = useState(0);
  const [imageInternalIndex, setImageInternalIndex] = useState(0);
  const [showArtView, setShowArtView] = useState(false);
  const imagesList = MANSION.CAROUSEL_IMAGES;
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const indexes = [5023, 4999, 3075, 2853, 4225];
  // const names = ['East Carolina Avenue', 'West Carolina Avenue', 'North Carolina Avenue', 'South Carolina Avenue', 'North-South Carolina Avenue'];
  const handleOpenModal = () => {
    navigate(`${location.pathname}?${indexes[imageExternalIndex]}`);
    setShowModal(true);
  };

  return (
    <div className="carousel-wrapper" style={{marginTop: '100px'}}>
      <div className="carousel-text">
        {/* <p className="carousel-heading">{names[imageExternalIndex]}</p> */}
        <p className="carousel-heading">Emerald City Land Sneak Peeks</p>
        <PlainSeperator />
        <p className="carousel-paragraph">
          The images below are samples and may vary from final look.
        </p>
      </div>
      <div className="carousel-image-div">
        <img
          className="carousle-selected-image"
          width={'420px'}
          height={'591px'}
          src={imagesList[imageExternalIndex][imageInternalIndex]}
          alt={`carousel-${imageExternalIndex}`}
          onClick={() => {
            setShowArtView(true);
          }}
        />

        <img
          onClick={() => {
            setImageInternalIndex(0);
            imageExternalIndex === 0
              ? setImageExternalIndex(imagesList.length - 1)
              : setImageExternalIndex(imageExternalIndex - 1);
          }}
          className="carousel-next-left"
          src={MANSION.CAROUSEL_NEXT_ICON}
          alt="next-icon-left"
        />
        <img
          onClick={() => {
            setImageInternalIndex(0);
            imageExternalIndex === imagesList.length - 1
              ? setImageExternalIndex(0)
              : setImageExternalIndex(imageExternalIndex + 1);
          }}
          className="carousel-next-right"
          src={MANSION.CAROUSEL_NEXT_ICON}
          alt="next-icon-right"
        />
      </div>
      <div className={`image-diff-view-wrapper ${!showModal && 'set-image-top'}`}>
        {[0, 1, 2, 3].map((value, index) => {
          return (
            <div key={index} className="image-diff-view">
              <img
                width={'100%'}
                className={`diff-view ${value === imageInternalIndex && 'selected-carousel'}`}
                onClick={() => {
                  setImageInternalIndex(value);
                }}
                src={imagesList[imageExternalIndex][value]}
                alt={`carousel-${imageExternalIndex}`}
              />
            </div>
          );
        })}
        {imageExternalIndex === 0 && (
          <div className="image-diff-view land-viewer-mansion">
            <img
              width={'100%'}
              className={`diff-view`}
              onClick={handleOpenModal}
              src={LAND_VIEWER}
              alt={`landViewer`}
            />
          </div>
        )}
      </div>

      {showArtView && (
        <ArtView
          setShowImage={setShowArtView}
          image={imagesList[imageExternalIndex][imageInternalIndex]}
        />
      )}
      {showModal && <LandViewer setShowModal={setShowModal} showModal={showModal} />}
    </div>
  );
};

export default Carousel;
