/* eslint-disable */
import {useEffect, useState} from 'react';
import ImageLoader from '@loader/ImageLoader';
import {WHITE_LAND_VIEWER, MANSION} from '@constants/images';
import ArtView from '@components/common/ArtView.component';

interface IPropertyImage {
  images: Array<any>;
  previewImage: string;
  render3DObject?: boolean;
  imageUrl: string;
  spotlight?: boolean;
  handleOpen3DModal?: any;
}

const PropertyCarousel = ({
  images,
  previewImage,
  render3DObject,
  imageUrl,
  spotlight,
  handleOpen3DModal,
}: IPropertyImage) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showArtView, setShowArtView] = useState(false);
  const [selectedImg, setSelectedImg] = useState<any>(previewImage);
  const [viewImagePath, setViewImagePath] = useState<any>('');

  // const imageUri = process.env.REACT_APP_LAND_BUCKET_API;

  useEffect(() => {
    setSelectedImg(previewImage);
  }, [previewImage]);

  return (
    <div className="property-img-carousel-wrapper carousel-wrapper">
      {spotlight && (
        <div className="spotlight-img">
          <img src={MANSION.SPOTLIGHT} alt="spotlight" />
        </div>
      )}
      <div className="property-img">
        <ImageLoader
          className="carousel-selected-img"
          src={imageUrl + selectedImg}
          // src={imageUri + '/' + selectedImg}
          alt="carousel-selected-image"
          onClick={() => {
            setViewImagePath(imageUrl + selectedImg);
            // setViewImagePath(imageUri + '/' + selectedImg);
            setShowArtView(true);
          }}
          skeletonStyle={{width: '600px', height: '600px'}}
          gifSkeleton
        />
      </div>
      <div className={`image-diff-view-wrapper ${!showModal && 'set-image-top'}`}>
        {images &&
          images.map((imagePath, index) => {
            return (
              <div key={index} className="image-diff-view">
                <ImageLoader
                  width={'100%'}
                  className={`diff-view ${imagePath === selectedImg && 'selected-carousel'}`}
                  onClick={() => {
                    setSelectedImg(imagePath);
                  }}
                  src={imageUrl + imagePath}
                  // src={imageUrl + '/' + imagePath}
                  alt={`carousel-${index}`}
                  skeletonStyle={{width: '90px', height: '90px'}}
                  gifSkeleton
                />
              </div>
            );
          })}
        {render3DObject && (
          <div
            className="image-diff-view"
            style={{filter: 'brightness(0.4) saturate(0)', cursor: 'not-allowed'}}>
            <img
              width="100%"
              className="diff-view"
              src={WHITE_LAND_VIEWER}
              alt="landViewer"
              // onClick={handleOpen3DModal}
            />
          </div>
        )}
      </div>
      {showArtView && viewImagePath && (
        <ArtView setShowImage={setShowArtView} image={viewImagePath} />
      )}
    </div>
  );
};

export default PropertyCarousel;
