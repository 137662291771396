import Button from '@components/common/Button.component';
import Modal from '@components/modal/Modal.component';
import {CROWN} from '@constants/images';
import {toFixedNoRounding} from '@services/Misc.service';
// import Confetti from 'react-confetti';

const ClaimCompleteModal = ({isClaimComplete, setClaimComplete, claimedAmount}: any) => {
  return (
    isClaimComplete && (
      <Modal setPopupOpen={() => {}} isCloseHidden style={{overflow: 'hidden'}}>
        <div className="claim__box">
          <p className="crown-loader">YOU HAVE CLAIMED</p>
          <img src={CROWN} alt="crown" width={30} />
          <br />
          <div className="amt__wrap">
            <p className="crown-loader-amount">{toFixedNoRounding(claimedAmount)}</p>
            <span> CROWN </span>
          </div>
          <br />
          <Button
            text="Continue"
            className="primary"
            style={{width: '280px'}}
            onClick={() => {
              setClaimComplete(false);
            }}
          />
          {/* <Confetti width={1024} height={768} /> */}
        </div>
      </Modal>
    )
  );
};

export default ClaimCompleteModal;
