import Modal from '@components/modal/Modal.component';
import {monthAbbrev} from '@services/Misc.service';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getAddressClaimHistory, getCrownClaimsTransactionCount} from '@services/Icon.service.js';
import type {RootState} from '@redux/store';

const ClaimHistoryModal = ({setClaimHistoryVisible, isClaimHistoryVisible}: any) => {
  const [txHistoryCurrentPage, setTxHistoryCurrentPage] = useState<number>(1);
  const [txHistoryTotalPageCount, setTxHistoryTotalPageCount] = useState(1);
  const txHistoryLimit = 10;
  const {walletAddress} = useSelector((state: RootState) => state.inventory);
  const [txHistoryData, setTxHistoryData] = useState<any>([]);

  useEffect(() => {
    const getPagination = async () => {
      const getTxHistoryCount = await getCrownClaimsTransactionCount(walletAddress);
      setTxHistoryTotalPageCount(Math.ceil(Number(getTxHistoryCount) / txHistoryLimit));
      const txHistory = await getAddressClaimHistory(
        walletAddress!,
        txHistoryLimit,
        txHistoryLimit * (txHistoryCurrentPage - 1)
      );
      setTxHistoryData(txHistory);
    };

    getPagination();
  }, [txHistoryCurrentPage]);

  return (
    isClaimHistoryVisible && (
      <Modal
        style={{maxWidth: '680px', width: '95%', margin: 'auto'}}
        title="Claim History"
        setPopupOpen={setClaimHistoryVisible}>
        {/* <p style={{textAlign: 'center'}}>
          {' '}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames diam facilisi sodales
          adipiscing. Dictumst nec quis{' '}
        </p> */}
        <br />
        <div className="crown__breakdown">
          <div className="individual__breakdown">
            <div>Timestamp</div>
            <div>Amount</div>
          </div>
          {txHistoryData.map((item: any) => (
            <div className="individual__breakdown" key={item?.timestamp}>
              <div>
                {monthAbbrev[new Date(item.timestamp * 0.001).getMonth()]}
                {'  '}
                {new Date(item.timestamp * 0.001).getDate()}
                {'  '}
                {new Date(item.timestamp * 0.001).getFullYear()}
              </div>
              <div>{item.amount / 10 ** 18}</div>
            </div>
          ))}
        </div>
        <div className="txHistoryPagination">
          {/* eslint-disable-next-line */}
          <p
            onClick={() => {
              // eslint-disable-next-line
              1 < txHistoryCurrentPage && setTxHistoryCurrentPage(txHistoryCurrentPage - 1);
            }}
            className={` ${
              // eslint-disable-next-line
              txHistoryCurrentPage === 1 ? 'disable-previous-page' : 'tx-previous-page'
            }`}>
            {'<'}
          </p>
          <p>{txHistoryCurrentPage}</p>
          <button
            type="button"
            className={`borderless-btn ${
              txHistoryCurrentPage === txHistoryTotalPageCount
                ? 'disable-next-page'
                : 'tx-next-page'
            }`}
            onClick={() => {
              if (txHistoryTotalPageCount > txHistoryCurrentPage)
                setTxHistoryCurrentPage(txHistoryCurrentPage + 1);
            }}>
            {'>'}
          </button>
        </div>
      </Modal>
    )
  );
};

export default ClaimHistoryModal;
