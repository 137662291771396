import {TosterInterface} from '@core/interfaces/interfaces';
import {BehaviorSubject} from 'rxjs';

const toaster = new BehaviorSubject<TosterInterface | []>([]);
const toasterService = {
  set: (state: any) => {
    let li: any = [];
    const reState = state;
    toaster.asObservable().subscribe((data) => {
      li = data;
    });
    reState.id = li.length + 1;
    li.push(state);
    toaster.next(li);
  },
  get: () => toaster.asObservable(),
  update: (list: any) => {
    toaster.next(list);
  },
};
export default toasterService;
