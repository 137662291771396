/* eslint-disable */
import PropertyCard from '@components/cards/PropertyCard.component';
import {DEV_LAND_REVEAL_BUCKET} from '@constants/constants';
import {CERTIFICATE_LOADING} from '@constants/images';
// import {getZoneMintedCount} from '@services/Icon.service';
// import {MAPPING_LOC_WITH_TYPES} from '@services/Misc.service';
// import {useState, useEffect} from 'react';

const Property = ({addressProperties, showSkeleton, tabType}: any) => {
  // const [zoneMintedCount, setZoneMintedCount] = useState<any>({});

  // useEffect(() => {
  //   (async () => {
  //     const zoneTotalMintCount: any = {};
  //     for (let i = 1; i <= 8; i++) {
  //       const mintedCount = await getZoneMintedCount(i);
  //       zoneTotalMintCount[i] = mintedCount;
  //     }
  //     setZoneMintedCount({...zoneTotalMintCount});
  //   })();
  // }, []);

  return (
    <div>
      {Object.keys(addressProperties).length > 0 && !showSkeleton && tabType === 'PROPERTY' && (
        <div className="owned-nfts">
          {Object.values(addressProperties).map((property: any, index: number) => {
            return (
              <PropertyCard
                key={`${index}_key`}
                // imgSource={`${property.image_url}`}
                imgSource={`${
                  DEV_LAND_REVEAL_BUCKET
                    ? DEV_LAND_REVEAL_BUCKET + property.preview
                    : property.image_url
                }`}
                // imgSource={`${process.env.REACT_APP_LAND_BUCKET_API}/${property.preview}`}
                zone={property.zone}
                name={property.name}
                district={property.district}
                // totalCount={zoneMintedCount[MAPPING_LOC_WITH_TYPES[property.zone]]}
                number={property.balance}
                nftId={property.nft_id}
                lazyLoading
              />
            );
          })}
        </div>
      )}
      <div className="show-skeleton-inventory">
        {showSkeleton &&
          tabType === 'PROPERTY' &&
          [1, 2, 3, 4].map((index) => {
            const key = `key_${index}`;
            return (
              // <img src={CERTIFICATE_LOADING} width="250px" height={'250px'} />
              <div
                key={key}
                style={{width: '247px', height: '335px', borderRadius: '5px'}}
                className="skeleton skeleton-default-width"></div>
            );
          })}
      </div>
    </div>
  );
};

export default Property;
