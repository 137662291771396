/*eslint-disable */
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

interface revealProgressTypes {
  landRevealCount: number;
  revealStatus: 'start' | 'complete' | 'failed';
  revealLoading: boolean;
}

interface IRevealedNFTDetail {
  nftId: string;
  nftMetaId: string;
}

interface landRevealState {
  revealProgress: revealProgressTypes;
  revealedNFT: IRevealedNFTDetail[];
  revealedNftsMetadata: Array<any>;
  isRevealCompleted: boolean;
}

const initialState: landRevealState = {
  revealProgress: {
    landRevealCount: 1,
    revealStatus: 'start',
    revealLoading: false,
  },
  revealedNFT: [],
  revealedNftsMetadata: [],
  isRevealCompleted: false,
};

export const landRevealSlice = createSlice({
  name: 'landReveal',
  initialState,
  reducers: {
    increment: (state) => {
      state.revealProgress.landRevealCount += 1;
    },
    decrement: (state) => {
      state.revealProgress.landRevealCount -= 1;
    },
    setLandRevealCount: (state, action: PayloadAction<number>) => {
      state.revealProgress.landRevealCount = action.payload;
    },
    setRevealStatus: (state, action: PayloadAction<any>) => {
      state.revealProgress.revealStatus = action.payload;
    },
    setRevealLoading: (state, action: PayloadAction<any>) => {
      state.revealProgress.revealLoading = action.payload;
    },
    setRevealedNFT: (state, action: PayloadAction<IRevealedNFTDetail[]>) => {
      state.revealedNFT = action.payload;
    },
    setRevealedNftsMetadata: (state, action: PayloadAction<Array<any>>) => {
      state.revealedNftsMetadata = action.payload;
    },
    setRevealCompleted: (state, action: PayloadAction<boolean>) => {
      state.isRevealCompleted = action.payload;
    },
  },
});

export const {
  increment,
  decrement,
  setLandRevealCount,
  setRevealStatus,
  setRevealCompleted,
  setRevealedNFT,
  setRevealedNftsMetadata,
  setRevealLoading,
} = landRevealSlice.actions;

export default landRevealSlice.reducer;
