/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import CongratulationCard from '@components/cards/CongratulationCard';
// import {getZoneInformation} from '@services/Icon.service.js';
import {RootState} from '@redux/store';
import {toFixedNoRounding} from '@services/Misc.service';
import {getAvailableCrown} from '@services/Crown.service';
import {setAvailableCrown} from '@redux/features/wallet/walletSlice';
import {
  setRecentMintedNFT,
  setMintCount,
  setMintStatus,
  setMintLoading,
} from '@redux/features/mint/mintSlice';
import Button from '@components/common/Button.component';
import {getGoldenKeyRewardStatus} from '@api/API';
import {getTotalSupply} from '@services/Icon.service';
import GKModal from './modals/GKModal';
import GKModalWinner from './modals/GKModal';

const Congratulation = ({NFTMintedList}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [zoneInfo, setZoneInfo] = useState<object>({});
  const [isGoldenKeyWon, setGoldenKeyWon] = useState<boolean | null>(null);

  const {isWalletConnected, walletAddress} = useSelector((state: RootState) => state.inventory);

  const MAPPING_LOC = {
    '1': 'Recreational',
    '2': 'Airport',
    '3': 'Industrial',
    '4': 'Governmental',
    '5': 'Education',
    '6': 'Health',
    '7': 'Commercial',
    '8': 'Residential',
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const getZone = async () => {
      // const zones = await getZoneInformation();
      setZoneInfo(MAPPING_LOC);
    };
    getZone();

    return () => {
      dispatch(setRecentMintedNFT([]));
      dispatch(setMintCount(1));
      dispatch(setMintStatus('start'));
      dispatch(setMintLoading(false));
    };
  }, []);

  useEffect(() => {
    const checkGKStatus = async () => {
      try {
        const totalSupplyForGK = await getTotalSupply();
        console.log(parseInt(totalSupplyForGK, 16));
        const user_status = await getGoldenKeyRewardStatus(
          walletAddress!,
          parseInt(totalSupplyForGK, 16),
          NFTMintedList.length
        );
        console.log(user_status);
        if (user_status?.data.won_status) {
          setGoldenKeyWon(true);
        } else {
          setGoldenKeyWon(false);
        }
      } catch (e) {
        setGoldenKeyWon(false);
      }
    };

    checkGKStatus();
  }, []);

  useEffect(() => {
    const getCrownInfo = async () => {
      if (isWalletConnected) {
        dispatch(
          setAvailableCrown(
            toFixedNoRounding(Number(await getAvailableCrown(walletAddress!)), 4) as string
          )
        );
      }
    };
    getCrownInfo();
  }, []);

  return (
    <div className="congratulation-wrapper">
      <StylishSeperator className="stylish-info-seperator" text="Congratulations!" />
      <p className="r-body-1 text-center">
        {NFTMintedList.length === 1 ? (
          <p className="nft__receive__message">You received a land certificate!</p>
        ) : (
          <p className="nft__receive__message">
            {' '}
            You received {NFTMintedList.length} land certificates.
          </p>
        )}
      </p>
      <div className="congrats-cards">
        {Object.keys(zoneInfo).length > 0 &&
          NFTMintedList.map((nftId: string, index: number) => {
            const id = Number(nftId);
            const type = Object.values(zoneInfo)[id - 1];
            const keys = `key_${index}`;
            return <CongratulationCard key={keys} type={type} />;
          })}
      </div>

      <div className="congratulation-btns">
        <Button
          text="Mint Again"
          onClick={() => {
            dispatch(setRecentMintedNFT([]));
            dispatch(setMintCount(1));
          }}
          className="primary"
          style={{width: '247px'}}
        />

        <Button
          text="Go to inventory"
          onClick={() => {
            navigate(`/inventory/${walletAddress}`);
          }}
          className="primary-outline"
          style={{width: '247px'}}
        />
      </div>

      <GKModalWinner isWinner={isGoldenKeyWon} setVisible={setGoldenKeyWon} />
    </div>
  );
};

export default Congratulation;
