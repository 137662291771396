/*eslint-disable */
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

interface bankProcessTypes {
  amount: number;
  bankLoading: boolean;
  bankStatus: 'start' | 'complete' | 'failed';
}

interface bankState {
  bankProcess: bankProcessTypes;
}

const initialState: bankState = {
  bankProcess: {
    amount: 0,
    bankLoading: false,
    bankStatus: 'start',
  },
};

export const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    setBankAmount: (state, action: PayloadAction<number>) => {
      state.bankProcess.amount = action.payload;
    },
    setBankLoading: (state, action: PayloadAction<boolean>) => {
      state.bankProcess.bankLoading = action.payload;
    },

    setBankStatus: (state, action: PayloadAction<any>) => {
      state.bankProcess.bankStatus = action.payload;
    },
  },
});

export const {setBankAmount, setBankLoading, setBankStatus} = bankSlice.actions;

export default bankSlice.reducer;
