interface ICoordinate {
  x: string;
  y: string;
}
interface IPropertyLocationProps {
  coordinate?: ICoordinate;
  propertyName?: string;
  district: string;
  zone: string;
  infrastructure?: string;
  landAttributes?: any;
}
const PropertyLocation = ({
  coordinate,
  propertyName,
  district,
  zone,
  // eslint-disable-next-line no-unused-vars
  infrastructure,
  landAttributes,
}: IPropertyLocationProps) => {
  return (
    <div className="property-location-detail">
      {coordinate && (
        <div className="coordinate">
          <p className="location-title primary-color">Co-ordinates</p>
          <p className="location">{`${coordinate?.x}, ${coordinate?.y}`}</p>
        </div>
      )}
      {propertyName && (
        <div className="property-name">
          <p className="location-title primary-color">Property</p>
          <p className="location">{propertyName}</p>
        </div>
      )}
      <div className="district">
        <p className="location-title primary-color">District</p>
        <p className="location">{district}</p>
      </div>
      <div className="zone">
        <p className="location-title primary-color">Zone</p>
        <p className="location">{zone}</p>
      </div>
      {landAttributes &&
        landAttributes.map(({key, value}: any) => {
          return (
            <div className="infrastructure">
              <p className="location-title primary-color">{key}</p>
              <p className="location">{value}</p>
            </div>
          );
        })}
    </div>
  );
};

export default PropertyLocation;
