import Button from '@components/common/Button.component';
import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
import StylishDecor from '@components/common/decorators/StylishDecor.component';
// import toasterService from '@components/common/toaster/ToasterService.component';
import {CROWN} from '@constants/images';
import {ICON_STATES} from '@constants/states';
import {RootState} from '@redux/store';
import {
  addressTotalClaimed,
  claimAmount,
  getClaimableAmount,
  getTransactionResult,
} from '@services/Icon.service';
import {toFixedNoRounding} from '@services/Misc.service';
import {useEffect, useState} from 'react';
import {CgInfo} from 'react-icons/cg';
import {FiExternalLink} from 'react-icons/fi';
import {RiHistoryFill} from 'react-icons/ri';
import {useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';

const AccountOverview = ({
  isClaimLoading,
  setClaimLoading,
  setClaimedAmount,
  setClaimComplete,
  setClaimHistoryVisible,
  claimHistoryData,
  isClaimComplete,
}: any) => {
  const {walletAddress, ledgerTransport, ledgerPath, isLedger} = useSelector(
    (state: RootState) => state.inventory
  );

  const [totalClaimedAmount, setTotalClaimedAmount] = useState<number | string>('-');
  const [availableForClaim, setAvailableForClaim] = useState<number | string>('-');

  useEffect(() => {
    const getCrownDetails = async () => {
      if (walletAddress) {
        setAvailableForClaim((await getClaimableAmount(walletAddress)) as number);

        setTotalClaimedAmount((await addressTotalClaimed(walletAddress)) as number);
      }
    };

    getCrownDetails();
  }, [isClaimComplete]);

  const getTx = async (tx: string) => {
    const res: any = await getTransactionResult(tx);

    if (res.status === ICON_STATES.PENDING) {
      /* eslint-disable-next-line */
      getTxResultWithTimer(tx);
    } else if (res.status === ICON_STATES.SUCCESS) {
      setClaimedAmount((res.eventLogs[0].indexed[3] / 10 ** 18) as number);

      setClaimComplete(true);
      setClaimLoading(false);
      // toasterService.set({
      //   title: 'Information!',
      //   message: 'CROWN claim success!',
      //   type: 'success',
      // });
      // toast.success('CROWN claim success!');
    } else {
      setClaimLoading(false);
      // toasterService.set({
      //   title: 'Error!',
      //   message: 'Error in claiming CROWN!',
      //   type: 'error',
      // });
    }
  };

  const getTxResultWithTimer = (txHash: string) => {
    setTimeout(() => {
      getTx(txHash);
    }, 5000);
  };

  const eventHandler: any = (event: any) => {
    if (event.detail.type === 'RESPONSE_JSON-RPC') {
      const txHash = event.detail.payload.result;
      getTxResultWithTimer(txHash);
    } else {
      setClaimLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  return (
    <>
      <div className="sub__heading__crown">
        {' '}
        <p className="r-body-1">Account Overview</p>
        <PlainSeperator width="30%" />
      </div>
      <div className="ac-overview">
        <div className="wd-bal-overview">
          <StylishDecor>
            <div className="wd-info">
              <b>Claimed </b>
              <CgInfo
                style={{paddingTop: '3px'}}
                className="info__icon"
                data-tip="The amount that has been withdrawn by the user."
                data-effect="solid"
                data-place="right"
              />
              <br />
              <div className="bal-amt-ov">
                <img src={CROWN} alt="crown" />
                <span>
                  {typeof totalClaimedAmount === 'number'
                    ? toFixedNoRounding(totalClaimedAmount, 4).toLocaleString()
                    : '-'}
                  &nbsp;/&nbsp;
                  {typeof totalClaimedAmount === 'number' && typeof availableForClaim === 'number'
                    ? toFixedNoRounding(totalClaimedAmount + availableForClaim, 4).toLocaleString()
                    : '-'}
                </span>
                &nbsp;
                <span> CROWN</span>
              </div>
            </div>
          </StylishDecor>
        </div>
        <div className="available__balance-overview">
          <StylishDecor>
            <div className="available__balance-content">
              <div className="balance__flex">
                <b>Available for claim </b>
                <CgInfo
                  style={{paddingTop: '3px'}}
                  className="info__icon"
                  data-tip="The amount that is ready for being claimed by the user."
                  data-effect="solid"
                  data-place="right"
                />
                <br />
                <div className="bal-amt-ov">
                  <img src={CROWN} alt="crown" />
                  <span>
                    {typeof availableForClaim === 'number'
                      ? toFixedNoRounding(availableForClaim).toLocaleString()
                      : '-'}
                  </span>
                  &nbsp;
                  <span> CROWN</span>
                </div>
              </div>

              <div className="withdraw-btn-available__balance">
                <Button
                  text="Claim"
                  className={
                    typeof availableForClaim === 'number' && Number(availableForClaim) > 0
                      ? 'primary-outline btn__claim'
                      : 'primary-outline btn__claim disabled'
                  }
                  style={{width: '100%'}}
                  // eslint-disable-next-line
                  isProgress={isClaimLoading}
                  onClick={async () => {
                    if (typeof availableForClaim === 'number' && Number(availableForClaim) > 0) {
                      setClaimLoading(true);
                      console.log(walletAddress!, ledgerTransport, ledgerPath, isLedger);
                      // eslint-disable-next-line
                      const txHash: any = await claimAmount(
                        walletAddress!,
                        ledgerTransport,
                        ledgerPath,
                        isLedger
                      );

                      if (isLedger) {
                        if (txHash?.name === 'TransportStatusError') {
                          console.log('Ledger cancelled!!!');
                          alert('Cancelled');
                        } else if (txHash) {
                          getTxResultWithTimer(txHash as string);
                        } else {
                          console.log('Error on ledger');
                        }
                      }
                    }
                  }}
                />
                {claimHistoryData.length > 0 ? (
                  <button
                    className="view__claim__history"
                    type="button"
                    onClick={() => {
                      setClaimHistoryVisible(true);
                    }}>
                    <RiHistoryFill /> View Claim History
                  </button>
                ) : (
                  <button
                    className="view__claim__history disabled"
                    type="button"
                    onClick={() => {}}>
                    <RiHistoryFill /> View Claim History
                  </button>
                )}
              </div>
            </div>
          </StylishDecor>
        </div>
      </div>

      <div className="crown__desc">
        {/* <p>
          Upon the listing of the CROWN token $CRWN, the vesting contacts will become active. 10% of
          the tokens are unlocked up front and the remainder will be vested 9 months Daily Linear.
          Tokens will be released Block-By-Block, available to claim at your choosing.{' '}
        </p> */}

        <a
          href="https://gangstabet.medium.com/tokenomics-of-crown-formerly-gg-67893c1cd462"
          className="link"
          target="_blank"
          rel="noreferrer">
          Know more about CROWN.
        </a>
      </div>

      <div style={{textAlign: 'center'}}>
        <Button
          text="Buy Crown on Balanced"
          icon={<FiExternalLink />}
          className="primary"
          style={{width: '280px'}}
          onClick={() => {
            window.open('https://app.balanced.network/trade', '_blank');
          }}
        />
      </div>

      <div style={{margin: '35px 0'}}>
        {' '}
        <PlainSeperator width="100%" />
      </div>
      {/* eslint-disable */}

      <ReactTooltip className="opaque" multiline={true} />
    </>
  );
};

export default AccountOverview;
