/*eslint-disable*/
import {useState} from 'react';
import Modal from '@components/modal/Modal.component';
import Button from '@components/common/Button.component';
import {useDispatch} from 'react-redux';
import {setIsLedger} from '@redux/features/wallet/walletSlice';

const LedgerAddressModal = ({
  isVisible,
  onClose,
  isAddressLoading,
  onNext,
  onPrevious,
  firstPage,
  onConfirm,
  currentPage,
  addressList,
  walletPaths,
  setConnectWalletPopup,
}) => {
  const [active, setActive] = useState('');
  const [activePath, setActivePath] = useState('');

  const dispatch = useDispatch();

  const handleSelect = (item, index) => {
    setActive(item);
    setActivePath(walletPaths[index]);
  };

  const onConfirmation = () => {
    onClose();
    onConfirm(active, activePath);
    setActive('');
    setActivePath('');
    dispatch(setIsLedger(true));
    setConnectWalletPopup(false);
  };

  const onCloseHandler = () => {
    onClose();
    setActive('');
    setActivePath('');
  };

  return (
    <Modal setPopupOpen={isVisible} title="Select Address">
      <div className="heading  px-4 mb-1">
        <p>Choose a wallet from your Ledger:</p>
      </div>
      <div className="body py-3 px-4 ledger-address-body">
        <ul className="ledger-address-list">
          {!isAddressLoading ? (
            addressList.map((item, index) => (
              <li
                className={`address-container ${active === item ? 'active' : ''}`}
                key={index}
                tabIndex={0}
                onClick={() => handleSelect(item, index)}>
                <p className="col-10  text-truncate mb-0">
                  <span className="address-text">{item}</span>
                </p>
              </li>
            ))
          ) : (
            <p className="address-text">Fetching addresses...</p>
          )}
        </ul>
      </div>
      {/* <p className="address-text col-12 text-truncate px-4 pt-2">
        Selected Wallet:{' '}
        {active ? (
          <span className="text-primary">{active}</span>
        ) : (
          <span className="cancel">none</span>
        )}
      </p> */}
      <div className="mt-3 px-4">
        <div className="include-flex button-group d-flex">
          <Button
            className="btn confirm px-3 primary"
            onClick={onConfirmation}
            text="Confirm"
            style={{width: '150px'}}
          />

          <div style={{marginRight: '20px'}}></div>

          <Button
            className="btn mx-1 cancel py-0 px-2 mb-2 primary-outline"
            onClick={onCloseHandler}
            text="Cancel"
            style={{width: '150px'}}
          />
        </div>
        <nav aria-label="Page navigation">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                aria-label="Previous"
                onClick={onPrevious}
                disabled={currentPage == 1}
                style={{width: '40px', height: '30px'}}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={firstPage}
                style={{width: '40px', height: '30px'}}>
                {currentPage}
              </button>
            </li>

            <li className="page-item">
              <button
                className="page-link"
                aria-label="Next"
                onClick={onNext}
                style={{width: '40px', height: '30px'}}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </Modal>
  );
};

export default LedgerAddressModal;
