/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import {useEffect, useRef} from 'react';
import {IRippleCanvas, ICircle} from '@interfaces/Canvas.interface';
import ScaleManager from './ScaleManager';

const RippleCanvas = ({canvasProps, circle, speed, maxRadius, className}: IRippleCanvas) => {
  // const {width, height} = canvasProps;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const rippleSpeed = maxRadius / 40;
  let batch: ICircle[] = [];

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const devicePixelRatio = window.devicePixelRatio || 1;

    const width = canvasProps?.width || canvas.parentElement?.offsetWidth || 400;
    const height = canvasProps?.height || canvas.parentElement?.offsetHeight || 400;

    const scaleManager = new ScaleManager(width, height);
    const scaledCords = scaleManager.getScaledCoords(+circle.x, +circle.y);
    const {x, y} = scaledCords;

    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;

    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    ctx.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

    ctx.translate(width / 2, height / 2);

    ctx.clearRect(-width / 2, -height / 2, width, height);

    const ripple = () => {
      if (batch.length > 5) return;

      if (batch.push(circle) === 1) {
        const tick = () => {
          batch = batch.map((c) => ({...c, radius: c.radius + rippleSpeed}));

          ctx.clearRect(-width / 2, -height / 2, width, height);
          batch.forEach((circle) => {
            ctx.fillStyle = `rgba(219, 172, 31, ${(1 - circle.radius / maxRadius) * 0.8})`;
            ctx.beginPath();
            ctx.arc(x - maxRadius / 2, -(y - maxRadius / 2), circle.radius, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();
          });
          batch = batch.filter((circle) => circle.radius < maxRadius);
          if (batch.length) {
            requestAnimationFrame(tick);
          }
        };
        requestAnimationFrame(tick);
      }
    };
    const intervalId = setInterval(ripple, speed);

    return () => {
      clearInterval(intervalId);
      batch.length = 0;
    };
  }, [maxRadius, circle, speed]);

  return <canvas ref={canvasRef} className={className} />;
};

export default RippleCanvas;
