import {DECORATOR} from '@constants/images';

interface stylishSep {
  text: string;
  width?: string;
  className?: string;
}

const StylishSeperator = ({text, className, width = '100%'}: stylishSep) => {
  return (
    <div className={className} style={{width, margin: 'auto', marginBottom: '10px'}}>
      <div className="border-bottom-main">
        <p className="heading-name">{text}</p>
        <div className="border-bottom">
          <img className="line" src={DECORATOR.STYLISH_SEPERATOR} alt="stylish line" />
        </div>
      </div>
    </div>
  );
};

export default StylishSeperator;
