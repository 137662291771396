export const MINT_STATES = {
  FAILED: 'failed',
  COMPLETE: 'complete',
};

export const ICON_STATES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};
