import React, {useEffect, useState} from 'react';
import {TOASTER_ICONS} from '@constants/images';
import toasterService from '@components/common/toaster/ToasterService.component';
import {TosterInterface} from '@core/interfaces/interfaces';

const Toaster: React.FC<TosterInterface> = (props) => {
  const {toastList, position, autoDelete} = props;
  const [list, setList] = useState(toastList);
  const deleteToast = (id: any) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const toastListItem = toastList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    toasterService.update(list);
    setList([...list]);
  };
  useEffect(() => {
    toasterService.get().subscribe((data: any) => {
      setList([...data]);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, list]);

  return (
    <div className={`notification-container ${position}`}>
      {list.map((toast) => (
        <div key={toast.id} className={`notification toast ${position} ${toast.type}`}>
          <button type="button" className="close-toaster" onClick={() => deleteToast(toast.id)}>
            <img src={TOASTER_ICONS.CLOSE} alt="" />
          </button>
          <div className="toaster-body">
            <div className="notification-image">
              {toast.type === 'success' || toast.type === 'warn' || toast.type === 'error' ? (
                <img
                  src={
                    toast.type === 'success'
                      ? TOASTER_ICONS.SUCCESS
                      : toast.type === 'warn'
                      ? TOASTER_ICONS.WARN
                      : toast.type === 'error'
                      ? TOASTER_ICONS.ERROR
                      : ''
                  }
                  alt=""
                />
              ) : null}
            </div>
            <div>
              <p className="notification-title r-subtitle-1">{toast.title}</p>
              <p className="notification-message r-caption">{toast.message}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toaster;
