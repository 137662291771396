/*eslint-disable */
import {useState, useEffect} from 'react';
import {MANSION} from '@constants/images';
import CarouselControls from './CarouselControls.component';
import {Link} from 'react-router-dom';
import {FiExternalLink} from 'react-icons/fi';

interface carouselProps {
  children: any;
  width: string;
  setActiveZone: any;
}

const Carousel = ({children, width, setActiveZone}: carouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const carouselItemsCount = children.length;

  useEffect(() => {
    const activeChild = children[activeIndex];
    setActiveZone(activeChild?.props.children.props.type);
  }, [activeIndex, children]);

  return (
    <div className="carousel-wrapper" style={{width: `${width}%`}}>
      <div className="carousel-inner">
        <div className="carousel-items-wrapper">
          <div className="carousel-items" style={{transform: `translateX(-${activeIndex * 100}%`}}>
            {children}
          </div>
        </div>
        <Link
          onClick={() => {
            window.open(`https://craft.network/collection/land-certificate`);
          }}
          className="links land-link primary-color"
          to="#">
          View in Craft <FiExternalLink />
        </Link>
        <CarouselControls
          totalPages={children.length}
          activePage={activeIndex}
          setActivePage={setActiveIndex}
        />
        <div className="dot-wrapper">
          {[...Array(carouselItemsCount).keys()].map((index) => {
            return (
              <div
                className={`carousel-dot ${activeIndex == index ? 'active-dot' : ''}`}
                key={index}
                onClick={() => setActiveIndex(index)}></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
