import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import Button from '@components/common/Button.component';
import {FiExternalLink} from 'react-icons/fi';
import StylishDecor from '@components/common/decorators/StylishDecor.component';

const EmeraldInfo = () => {
  return (
    <StylishDecor style={{margin: '24px 0px 0px 0px'}} className="gangster-info-wrapper">
      <div className="emerald-info-bg">
        <div className="emerald-info-wrapper">
          <StylishSeperator
            className="stylish-info-seperator extra-sm-seperator"
            text="The Emerald City"
          />
          <p className="text-center r-body-1 emerald-info-p" style={{padding: '20px'}}>
            The Emerald City is the first virtual city to be launched as a part of GangstaVerse.
            This city is where our GangstaBets and Civilians reside, where stories within
            GangstaVerse take place. Equipped with a vast array of different structures for its
            inhabitants which serve them within GangstaVerse, the only question is who will own
            them.
          </p>

          <iframe
            width="75%"
            height="340"
            src="https://www.youtube.com/embed/dg8hclFntDw?modestbranding=1&amp;playlist=dg8hclFntDw&amp;hd=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />

          <Button
            onClick={() =>
              window.open('https://docs.gangstabet.io/gangstaverse/the-emerald-city', '_blank')
            }
            icon={<FiExternalLink />}
            style={{width: '250px'}}
            text="Learn More"
            className="primary"
          />
        </div>
      </div>
    </StylishDecor>
  );
};

export default EmeraldInfo;
