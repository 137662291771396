import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
import Modal from '@components/modal/Modal.component';
import {dateFormatter, monthAbbrev, toFixedNoRounding} from '@services/Misc.service';
// import {CgInfo} from 'react-icons/cg';
import ReactTooltip from 'react-tooltip';

const RewardDisributionModal = ({
  isRewardPopupOpen,
  setRewardPopupOpen,
  stakeRewardDB,
  lpRewardDB,
  boom500kRewardDB,
}: any) => {
  return (
    isRewardPopupOpen && (
      <Modal title="CROWN Reward Distribution" setPopupOpen={setRewardPopupOpen}>
        <p style={{textAlign: 'center', marginBottom: '10px'}}>
          Governance Token distribution details.
        </p>
        <PlainSeperator width="100%" />
        <div className="crown__breakdown">
          <b>CROWN earned by staking (ICON & bOMM)</b>
          {stakeRewardDB?.reward_logs?.map(
            // eslint-disable-next-line
            ({date_stamp, gg_reward, balance}: any, i: any, row: any) => (
              // eslint-disable-next-line
              <div key={date_stamp}>
                <div className="individual__breakdown">
                  <div>{`${
                    monthAbbrev[new Date(dateFormatter(date_stamp)).getUTCMonth()]
                  }, ${new Date(dateFormatter(date_stamp)).getUTCFullYear()}`}</div>
                  <div>
                    {/* eslint-disable-next-line  */}
                    {gg_reward.toFixed(4)} <span className="gg_token_unit">CROWN</span>
                  </div>
                </div>
                {i + 1 === row.length && (
                  <div className="individual__breakdown">
                    <div>Total</div>
                    <div>
                      {stakeRewardDB?.rewards?.staking_gg_reward?.toFixed(4)}{' '}
                      <span className="gg_token_unit">CROWN</span>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          <b>CROWN earned by adding Liquidity</b>
          <div className="individual__breakdown">
            <div>Total</div>
            <div>
              {lpRewardDB?.toFixed(4)} <span className="gg_token_unit">CROWN</span>
            </div>
          </div>
          <b>CROWN earned by 500k July Pool</b>
          <small style={{display: 'flex', justifyContent: 'center'}}>
            (Provided with airdrop pool)
          </small>

          <div className="individual__breakdown">
            <div>Total</div>
            <div>
              {boom500kRewardDB?.toFixed(4)} <span className="gg_token_unit">CROWN</span>
            </div>
          </div>
          <PlainSeperator width="100%" />
          <div className="individual__breakdown">
            <div>Total</div>
            <div>
              {typeof stakeRewardDB?.rewards?.staking_gg_reward === 'number' &&
                toFixedNoRounding(
                  Number(boom500kRewardDB) +
                    Number(lpRewardDB) +
                    Number(stakeRewardDB?.rewards?.staking_gg_reward)
                ).toLocaleString()}{' '}
              <span className="gg_token_unit">CROWN</span>
            </div>
          </div>
        </div>
        {/* eslint-disable */}
        <ReactTooltip className="opaque" multiline={true} />
      </Modal>
    )
  );
};

export default RewardDisributionModal;
