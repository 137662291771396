interface PlainSepProps {
  width?: string;
  style?: any;
}

const PlainSeperator = ({width = '100%', style}: PlainSepProps) => {
  return (
    <div className="plain__separator" style={style}>
      <hr style={{width, margin: 'auto', marginTop: '2px'}} />
    </div>
  );
};

export default PlainSeperator;
