import StylishDecor from '@components/common/decorators/StylishDecor.component';
import {FiExternalLink} from 'react-icons/fi';

const ForexLiveSoon = () => {
  return (
    <StylishDecor className="forexLiveSoon-wrapper">
      <div className="forex-live-text-div">
        <p className="forex-live-soon-text">Forex will be live soon</p>
        <a
          className="forex-live-soon-redirect borderless-btn"
          href="https://gangstabet.medium.com/emerald-city-introducing-forex-9e1cf4e4ee4f"
          target="_blank"
          rel="noreferrer">
          Read More in our Medium article <FiExternalLink />
        </a>
      </div>
    </StylishDecor>
  );
};
export default ForexLiveSoon;
