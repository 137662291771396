import {useState, useEffect} from 'react';
import Button from '@components/common/Button.component';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import {CERTIFICATE} from '@constants/images';
import ImageLoader from '@loader/ImageLoader';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  setRevealLoading,
  setLandRevealCount,
  setRevealedNFT,
  setRevealStatus,
  setRevealCompleted,
  setRevealedNftsMetadata,
} from '@redux/features/landReveal/landRevealSlice';
import {getLandMintedCount} from '@services/Icon.service';
import CountdownTime from '@pages/LandReveal/CountdownTime.component';
import {RootState} from '@redux/store';
import AllLandRevealed from '@pages/LandReveal/allLandRevealed.component';
import {LAND_REVEAL_CAP} from '@constants/constants';

const RevealProperty = () => {
  const [isSoldOut, setIsSoldOut] = useState<boolean>(false);

  const {revealStarted} = useSelector((state: RootState) => state.revealStatus);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSoldOut) return;
    (async () => {
      const landMintedCount = await getLandMintedCount();
      if (landMintedCount === +LAND_REVEAL_CAP) setIsSoldOut(true);
    })();
  }, []);

  return (
    <div className="reveal-property-wrapper">
      <StylishSeperator className="stylish-info-separator" text="Reveal Your Property" />
      <div className="reveal-property-content">
        <p className="text-center reveal-property-text">
          Land Ownership Certificate holders can use their certificates to reveal the land they own
          in The Emerald City.
        </p>
        {revealStarted ? (
          !isSoldOut ? (
            <>
              <div className="general-certificate-img-wrapper">
                <ImageLoader src={CERTIFICATE} alt="general-certificate" />
              </div>
              <Button
                text="Reveal Now"
                className="primary"
                onClick={() => {
                  dispatch(setLandRevealCount(1));
                  dispatch(setRevealStatus('start'));
                  dispatch(setRevealedNFT([]));
                  dispatch(setRevealedNftsMetadata([]));
                  dispatch(setRevealCompleted(false));
                  dispatch(setRevealLoading(false));
                  navigate('/reveal');
                }}
                style={{width: '190px', height: '50px'}}
              />
            </>
          ) : (
            <AllLandRevealed />
          )
        ) : (
          <CountdownTime />
        )}
      </div>
    </div>
  );
};

export default RevealProperty;
