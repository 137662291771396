/*eslint-disable */
const IconService = require('icon-sdk-js').default;
import {config} from '@config/config';
import AppIcx from '@ledgerhq/hw-app-icx';
import {objectToHexWithPrefix, toFixedNoRounding} from '@services/Misc.service';

const {IconBuilder, HttpProvider, IconAmount, IconConverter, IconValidator, IconUtil} = IconService;

const {CallBuilder} = IconBuilder;

const httpProvider = new HttpProvider(config.icon.WALLET_URL);
const iconService = new IconService(httpProvider);
const callBuilder = new CallBuilder();

const DEX_SCORE = 'cx0931135862b91a720f93611e662bd5d05a30c9ce';
const STAKED_LP_SCORE = 'cxc174354f3b76c7e0244812f600cb24109ff40b83';
const CROWN_CLAIM_NEW_SCORE = 'cx71b1bd79d50bc9557744945a8f18d83d49177ac1';
const CROWN_REWARD_NEW_SCORE = 'cx344eba5e64f81b2374a87a4f000a94bec74cac9a';

const signTransaction = async (transaction, walletPath, transport) => {
  const rawTransaction = IconConverter.toRawTransaction(transaction);
  const hashKey = IconUtil.generateHashKey(rawTransaction);
  const icx = new AppIcx(transport);
  const {signedRawTxBase64} = await icx.signTransaction(walletPath, hashKey);

  rawTransaction.signature = signedRawTxBase64;

  return {
    getProperties: () => rawTransaction,
    getSignature: () => signedRawTxBase64,
  };
};

export const handleLogin = () => {
  window.dispatchEvent(
    new CustomEvent('ICONEX_RELAY_REQUEST', {
      detail: {
        type: 'REQUEST_ADDRESS',
      },
    })
  );
};

export const checkICONaddressValidity = async (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const validity = IconValidator.isEoaAddress(address);
      resolve(validity);
    } catch (e) {
      reject(false);
    }
  });
};

export const getZoneInformation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder.to(config.scores.LOC_SCORE).method('getZoneInfo').params({}).build();
      const result = await iconService.call(call).execute();
      resolve(result);
    } catch {
      resolve([]);
    }
  });
};

export const getMintedZoneCountBribe = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BRIBE_SCORE)
        .method('getMintedZoneCount')
        .params({})
        .build();
      const result = await iconService.call(call).execute();
      resolve(result);
    } catch (error) {
      console.log(error);
      resolve([]);
    }
  });
};

export const getMintedZoneCountCertificate = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('getMintedZoneCount')
        .params({})
        .build();
      const result = await iconService.call(call).execute();
      resolve(result);
    } catch (error) {
      console.log(error);
      resolve([]);
    }
  });
};

export const getTotalSupply = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder.to(config.scores.LOC_SCORE).method('totalSupply').params({}).build();
      const result = await iconService.call(call).execute();
      resolve(result);
    } catch (error) {
      console.log(error);
      resolve([]);
    }
  });
};

export const getOwnedNFTs = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('getOwnedNft')
        .params({
          address: address,
        })
        .build();
      const ownedNft = await iconService.call(call).execute();
      resolve(ownedNft);
    } catch (e) {
      resolve([]);
    }
  });
};

export const crownPerXCrown = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BANK_SCORE)
        .method('getCrownPerXCrown')
        .params({})
        .build();
      const price = await iconService.call(call).execute();
      resolve(price / 10 ** 18);
    } catch (error) {
      resolve(0);
    }
  });
};

export const getTotalXCrownByUser = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BANK_SCORE)
        .method('balanceOf')
        .params({_owner: address})
        .build();
      const XCrown = await iconService.call(call).execute();
      // resolve(Number(toFixedNoRounding(XCrown / 10 ** 18, 10)).replace(/\.?0+$/, ""));
      resolve(Number(toFixedNoRounding(XCrown / 10 ** 18, 10)));
    } catch (e) {
      resolve(0);
    }
  });
};

export const availableCrown = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.CROWN_SCORE)
        .method('balanceOf')
        .params({owner: address})
        .build();
      const availableCrown = await iconService.call(call).execute();
      const parsedCrown = parseInt(availableCrown) / 10 ** 18;
      resolve(parsedCrown);
    } catch (e) {
      resolve(0);
    }
  });
};

export const minimumDepositAmount = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BANK_SCORE)
        .method('getMinimumDepositAmount')
        .params({})
        .build();
      const minAmount = await iconService.call(call).execute();
      resolve(parseInt(minAmount) / 10 ** 18);
    } catch (e) {
      resolve(0);
    }
  });
};

export const depositIntoBank = async (address, amount, ledgerTransport, ledgerPath, isLedger) => {
  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.CROWN_SCORE)
      .stepLimit(IconConverter.toBigNumber('5000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('transfer')
      .params({
        _to: config.scores.BANK_SCORE,
        _value: IconConverter.toHex(amount * 10 ** 18),
        _data: objectToHexWithPrefix({deposit: true}),
      })
      .build();

    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      let scoreData = {};
      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (e) {
    return -1;
  }
};

export const withdrawFromBank = async (address, amount, ledgerTransport, ledgerPath, isLedger) => {
  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();
    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.BANK_SCORE)
      .stepLimit(IconConverter.toBigNumber('5000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('withdraw')
      .params({
        share: IconConverter.toHex(amount * 10 ** 18),
      })
      .build();

    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      let scoreData = {};
      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (error) {
    return -1;
  }
};

export const getMaxBatchMintCount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('getMaxBatchMintCount')
        .params({})
        .build();
      const result = await iconService.call(call).execute();
      resolve(result);
    } catch (e) {
      resolve(0);
    }
  });
};

export const certificateMintCost = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('getMintCost')
        .params({nftCount: IconConverter.toBigNumber(1)})
        .build();
      const cost = await iconService.call(call).execute();
      const parseCost = cost / 10 ** 18;
      resolve(parseCost);
    } catch (error) {
      resolve(0);
    }
  });
};

export const mintWithCrown = async (
  address,
  mintCount,
  price,
  ledgerTransport,
  ledgerPath,
  isLedger
) => {
  let obj = {
    tokenCount: mintCount,
    destinationContract: config.scores.LOC_SCORE, //LOC contract
    // mint: true,
    deposit: false,
  };

  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.CROWN_SCORE)
      .stepLimit(IconConverter.toBigNumber('60000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('transfer')
      .params({
        _to: config.scores.TOKEN_DISTRIBUTION_SCORE, // Token Distribution Contract
        _value: IconConverter.toHex(IconAmount.of(price, IconAmount.Unit.ICX).toLoop()),
        _data: objectToHexWithPrefix(obj),
      })
      .build();
    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      const scoreData = {};
      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (e) {
    return -1;
  }
};

export const mintWithCrownAndBribe = async (
  address,
  price,
  bribeTokenId,
  bribeTokenCount,
  ledgerTransport,
  ledgerPath,
  isLedger
) => {
  let obj = {
    destinationContract: config.scores.LOC_SCORE, //LOC contract
    // mint: true,
    deposit: false,
    bribeTokenIds: bribeTokenId,
    bribeTokenCounts: bribeTokenCount,
  };

  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.CROWN_SCORE)
      .stepLimit(IconConverter.toBigNumber('60000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('transfer')
      .params({
        _to: config.scores.TOKEN_DISTRIBUTION_SCORE, // Token Distribution Contract
        _value: IconConverter.toHex(IconAmount.of(price, IconAmount.Unit.ICX).toLoop()),
        _data: objectToHexWithPrefix(obj),
      })
      .build();
    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      const scoreData = {};
      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (e) {
    return -1;
  }
};

export const getLatestBlock = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let block = await iconService.getLastBlock().execute();
      resolve(block);
    } catch (e) {
      reject({msg: 'Invalid'});
    }
  });
};

export const getBlock = (blockNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      let block = await iconService.getBlockByHeight(blockNumber).execute();
      resolve(block);
    } catch (e) {
      reject({msg: 'Invalid'});
    }
  });
};

export const getTransactionResult = async (tx) => {
  try {
    const transactionResult = await iconService.getTransactionResult(tx).execute();
    return {
      status: transactionResult.status === 1 ? 'success' : 'error',
      msg: transactionResult.status === 1 ? 'Success' : transactionResult.failure.message,
      tx: tx,
      eventLogs: transactionResult.status === 1 ? transactionResult.eventLogs : 'no_logs',
    };
  } catch (e) {
    if (
      e.includes('Pending') ||
      e.includes('Executing') ||
      e === '[RPC ERROR] Invalid params txHash'
    ) {
      return {
        status: 'pending',
        msg: 'Pending Transaction',
        tx: tx,
      };
    }

    return {
      status: 'error',
      msg: 'Invalid Transaction',
      tx: tx,
    };
  }
};

export const getBalanceOf = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.CROWN_SCORE)
        .method('balanceOf')
        .params({_owner: address})
        .build();
      const response = await iconService.call(call).execute();
      resolve(toFixedNoRounding(response / 10 ** 18, 10));
    } catch (e) {
      resolve(0);
    }
  });
};

export const isAddressApprovedForBribeMint = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BRIBE_SCORE)
        .method('isApprovedForAll')
        .params({_owner: address, _operator: config.scores.LOC_SCORE})
        .build();
      const hex = await iconService.call(call).execute();
      resolve(parseInt(hex, 16));
    } catch (e) {
      resolve(0);
    }
  });
};

export const approveAddressForBribeMint = async (
  address,
  ledgerTransport,
  ledgerPath,
  isLedger
) => {
  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.BRIBE_SCORE)
      .stepLimit(IconConverter.toBigNumber('900000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('setApprovalForAll')
      .params({
        _operator: config.scores.LOC_SCORE,
        _approved: '0x1',
      })
      .build();
    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      const scoreData = {};
      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (e) {
    return -1;
  }
};

export const getBribesOf = (address, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BRIBE_SCORE)
        .method('balanceOf')
        .params({_owner: address, _id: '0x' + type})
        .build();
      const hex = await iconService.call(call).execute();
      resolve(parseInt(hex, 16));
    } catch (e) {
      resolve(0);
    }
  });
};

export const getZoneDetailsOfBribe = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(id);
      const call = callBuilder
        .to(config.scores.BRIBE_SCORE)
        .method('getZoneDetail')
        .params({id: IconConverter.toHex(id)})
        .build();
      const result = await iconService.call(call).execute();
      resolve(result);
    } catch (e) {
      console.log(e);
      resolve(0);
    }
  });
};

export const getZoneDetailsOfCertificate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(id);
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('getZoneDetail')
        .params({id: IconConverter.toHex(id)})
        .build();
      const result = await iconService.call(call).execute();
      resolve(result);
    } catch (e) {
      console.log(e);
      resolve(0);
    }
  });
};

export const getTotalMintCount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('totalMintCount')
        .params({})
        .build();
      const count = await iconService.call(call).execute();
      resolve(parseInt(count.TOTAL, 16));
    } catch (e) {
      resolve(0);
    }
  });
};

export const getMintStatus = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('getMintStatus')
        .params({})
        .build();
      const count = await iconService.call(call).execute();
      resolve(parseInt(count, 16));
    } catch (e) {
      resolve(0);
    }
  });
};

export const getBankCountTransaction = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BANK_SCORE)
        .method('countTransactions')
        .params({address: address})
        .build();
      const getTotalCount = await iconService.call(call).execute();
      resolve(getTotalCount);
    } catch (e) {
      console.log(e);
      reject({msg: 'error requesting'});
    }
  });
};

export const getCrownClaimsTransactionCount = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.CROWN_CLAIM_SCORE)
        .method('countAddressClaims')
        .params({address: address})
        .build();
      const getTotalCount = await iconService.call(call).execute();
      resolve(getTotalCount);
    } catch (e) {
      console.log(e);
      reject({msg: 'error requesting'});
    }
  });
};

export const claimAmount = async (address, ledgerTransport, ledgerPath, isLedger) => {
  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.CROWN_CLAIM_SCORE)
      .value(IconAmount.of(0, IconAmount.Unit.ICX).toLoop())
      .stepLimit(IconConverter.toBigNumber('5000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('claim')
      .params({})
      .build();

    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      const scoreData = {};

      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (e) {
    return -1;
  }
};

export const getClaimableAmount = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.CROWN_CLAIM_SCORE)
        .method('getClaimableAmount')
        .params({address: address})
        .build();
      const response = await iconService.call(call).execute();
      resolve(response / 10 ** 18);
    } catch (e) {
      resolve(0);
    }
  });
};

export const addressPoolWiseTotalAllocated = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.CROWN_CLAIM_SCORE)
        .method('addressPoolWiseTotalAllocated')
        .params({address: address, pool: 'AIRDROP'})
        .build();
      const response = await iconService.call(call).execute();
      console.log(response);
      resolve(response / 10 ** 18);
    } catch (e) {
      resolve(0);
    }
  });
};

export const getAddressClaimHistory = (address, limit, offset) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.CROWN_CLAIM_SCORE)
        .method('getAddressClaimHistory')
        .params({
          address: address,
          limit: limit.toString(),
          offset: offset.toString(),
          order: 'desc',
        })
        .build();
      const response = await iconService.call(call).execute();
      resolve(response);
    } catch (e) {
      resolve([]);
    }
  });
};

export const getTxHistory = (address, limit, offset) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.BANK_SCORE)
        .method('getTxHistory')
        .params({
          address: address,
          limit: limit.toString(),
          offset: offset.toString(),
          order: 'desc',
        })
        .build();
      const response = await iconService.call(call).execute();
      resolve(response);
    } catch (e) {
      resolve([]);
    }
  });
};

export const addressTotalClaimed = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.CROWN_CLAIM_SCORE)
        .method('addressTotalClaimed')
        .params({address: address})
        .build();
      const response = await iconService.call(call).execute();
      resolve(response / 10 ** 18);
    } catch (e) {
      resolve(0);
    }
  });
};

export const getGoldenKeysOf = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.GOLDEN_KEY_SCORE)
        .method('balanceOf')
        .params({_owner: address, _id: '0x1'})
        .build();
      const count = await iconService.call(call).execute();
      resolve(parseInt(count, 16));
    } catch (e) {
      resolve(0);
    }
  });
};

export const getPoolStats = (pool_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder.to(DEX_SCORE).method('getPoolStats').params({_id: pool_id}).build();
      const stats = await iconService.call(call).execute();
      resolve(stats);
    } catch (e) {
      resolve({msg: 'invalid'});
    }
  });
};

export const getStakedLPBalance = (address, pool_id) => {
  return new Promise(async (resolve) => {
    try {
      const call = callBuilder
        .to(STAKED_LP_SCORE)
        .method('balanceOf')
        .params({_owner: address, _id: pool_id})
        .build();
      const stats = await iconService.call(call).execute();
      resolve(stats);
    } catch (e) {
      resolve({msg: 'invalid'});
    }
  });
};

export const getTotalLPBalance = (address, pool_id) => {
  return new Promise(async (resolve) => {
    try {
      const call = callBuilder
        .to(DEX_SCORE)
        .method('balanceOf')
        .params({_owner: address, _id: pool_id})
        .build();
      const stats = await iconService.call(call).execute();
      resolve(stats);
    } catch (e) {
      resolve({msg: 'invalid'});
    }
  });
};

export const getTotalAllocatedFromLP = (user_address, asset_address) => {
  return new Promise(async (resolve) => {
    try {
      const call = callBuilder
        .to(CROWN_REWARD_NEW_SCORE)
        .method('assetUserTotalAllocation')
        .params({userAddress: user_address, assetAddress: asset_address})
        .build();
      const stats = await iconService.call(call).execute();
      resolve(stats);
    } catch (e) {
      resolve({msg: 'invalid'});
    }
  });
};

export const stakeLP = async (address, ledgerTransport, ledgerPath, isLedger, lp, pool_id) => {
  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(DEX_SCORE)
      .value(IconAmount.of(0, IconAmount.Unit.ICX).toLoop())
      .stepLimit(IconConverter.toBigNumber('5000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('transfer')
      .params({
        _to: STAKED_LP_SCORE,
        _value: lp * 10 ** 18,
        _id: pool_id,
        _data: objectToHexWithPrefix({method: 'stake'}),
      })
      .build();

    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      const scoreData = {};

      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (e) {
    return -1;
  }
};

export const unstakeLP = async (address, ledgerTransport, ledgerPath, isLedger, lp, pool_id) => {
  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(STAKED_LP_SCORE)
      .value(IconAmount.of(0, IconAmount.Unit.ICX).toLoop())
      .stepLimit(IconConverter.toBigNumber('5000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('unstake')
      .params({
        _value: lp,
        _id: pool_id,
      })
      .build();

    if (isLedger) {
      const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
      const res = await iconService.sendTransaction(signedTransaction).execute();

      return res;
    } else {
      const scoreData = {};

      scoreData.value = JSON.stringify({
        jsonrpc: '2.0',
        method: 'icx_sendTransaction',
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });

      const parsed = JSON.parse(scoreData.value);

      window.dispatchEvent(
        new CustomEvent('ICONEX_RELAY_REQUEST', {
          detail: {
            type: 'REQUEST_JSON-RPC',
            payload: parsed,
          },
        })
      );
    }
  } catch (e) {
    return -1;
  }
};

const icxSendTransaction = async (txObj, ledgerTransport, ledgerPath, isLedger) => {
  if (isLedger) {
    const signedTransaction = await signTransaction(txObj, ledgerPath, ledgerTransport);
    const res = await iconService.sendTransaction(signedTransaction).execute();

    return res;
  }

  const scoreData = {};
  scoreData.value = JSON.stringify({
    jsonrpc: '2.0',
    method: 'icx_sendTransaction',
    params: IconConverter.toRawTransaction(txObj),
    id: 50889,
  });

  const parsed = JSON.parse(scoreData.value);

  window.dispatchEvent(
    new CustomEvent('ICONEX_RELAY_REQUEST', {
      detail: {
        type: 'REQUEST_JSON-RPC',
        payload: parsed,
      },
    })
  );
};

export const approveLandAddressForReveal = async (
  address,
  ledgerTransport,
  ledgerPath,
  isLedger
) => {
  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.LOC_SCORE)
      .stepLimit(IconConverter.toBigNumber('20000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('setApprovalForAll')
      .params({
        _operator: config.scores.LAND_SCORE,
        _approved: '0x1',
      })
      .build();

    const res = await icxSendTransaction(txObj, ledgerTransport, ledgerPath, isLedger);
    return res;
  } catch (e) {
    return -1;
  }
};

export const isAddressApprovedForLandReveal = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LOC_SCORE)
        .method('isApprovedForAll')
        .params({_owner: address, _operator: config.scores.LAND_SCORE})
        .build();
      const hex = await iconService.call(call).execute();
      resolve(parseInt(hex, 16));
    } catch (e) {
      resolve(0);
    }
  });
};

export const mintLand = async (
  address,
  zone,
  revealCount,
  ledgerTransport,
  ledgerPath,
  isLedger
) => {
  let revealZoneCounts = {
    count: '0x' + revealCount.toString(16),
    zoneId: '0x' + zone.toString(16),
  };
  console.log(revealZoneCounts);

  try {
    const callTransactionBuilder = new IconBuilder.CallTransactionBuilder();

    const txObj = callTransactionBuilder
      .from(address)
      .to(config.scores.LAND_SCORE)
      .stepLimit(IconConverter.toBigNumber('80000000'))
      .nid(IconConverter.toBigNumber(config.icon.NETWORK_ID))
      .nonce(IconConverter.toBigNumber('1'))
      .version(IconConverter.toBigNumber('3'))
      .timestamp(new Date().getTime() * 1000)
      .method('mintLand')
      .params({zoneCertificateCount: revealZoneCounts})
      .build();

    const res = await icxSendTransaction(txObj, ledgerTransport, ledgerPath, isLedger);
    return res;
  } catch (e) {
    return -1;
  }
};

export const getLandDetail = (landId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LAND_SCORE)
        .method('getLandInfo')
        .params({
          nftId: landId,
        })
        .build();
      const landDetail = await iconService.call(call).execute();
      resolve(landDetail);
    } catch (e) {
      resolve([]);
    }
  });
};

export const getZoneMintedCount = (zoneId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LAND_SCORE)
        .method('getZoneMintedCount')
        .params({
          zoneId: IconConverter.toHex(zoneId),
        })
        .build();
      const mintedCount = await iconService.call(call).execute();
      resolve(mintedCount);
    } catch (e) {
      console.log(e);
      resolve([]);
    }
  });
};

export const getOwnedProperties = (address, page) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LAND_SCORE)
        .method('getOwnersNfts')
        .params({
          address: address,
          page: IconConverter.toHex(page),
        })
        .build();
      const ownedNfts = await iconService.call(call).execute();
      resolve(ownedNfts);
    } catch (e) {
      resolve([]);
    }
  });
};

export const getBalanceOfLand = async (address, nftId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LAND_SCORE)
        .method('balanceOf')
        .params({
          _owner: address,
          _id: nftId,
        })
        .build();
      const balance = await iconService.call(call).execute();
      resolve(balance);
    } catch (e) {
      resolve([]);
    }
  });
};

export const getLandMintedCount = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const call = callBuilder
        .to(config.scores.LAND_SCORE)
        .method('getMintedCount')
        .params({})
        .build();
      const count = await iconService.call(call).execute();
      resolve(+count);
    } catch (e) {
      console.log(e);
      resolve(-1);
    }
  });
};
