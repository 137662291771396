import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from 'src/redux/store';
import {config} from '@config/config';
import {getTransactionResult} from '@services/Icon.service.js';
import {setBankAmount, setBankLoading, setBankStatus} from '@redux/features/bank/bankSlice';
import Modal from '@components/modal/Modal.component';
import Button from '@components/common/Button.component';
import {
  commaAfterThreeDigits,
  toFixedNoRounding,
  avoidScientificNotation,
} from '@services/Misc.service';
import {
  depositCrownIntoBank,
  withdrawCrownFromBank,
  getMinimumDepositAmount,
} from '@services/Crown.service';

interface depositWithdrawProps {
  text: string;
  crown: any;
  isModalVisible: boolean;
  setModalVisible: any;
  totalUserXCrown: any;
  crownPerXCrown: Number;
}

const DepositWithdrawModal = ({
  text,
  crown,
  isModalVisible,
  setModalVisible,
  totalUserXCrown,
  crownPerXCrown,
}: depositWithdrawProps) => {
  const [userEnteredAmount, setUserEnteredAmount] = useState<string>('');
  const [depositErrorMessage, setDepositErrorMessage] = useState('');
  const [minDepositAmount, setMinDepositAmount] = useState<any>(0);
  // const [isNestedModalVisible, setNestedModalVisible] = useState<boolean>(true);
  const dispatch = useDispatch();
  const {isWalletConnected, walletAddress, ledgerTransport, ledgerPath, isLedger} = useSelector(
    (state: RootState) => state.inventory
  );
  const {bankProcess} = useSelector((state: RootState) => state.bank);

  const eventHandler = (event: any) => {
    if (event.detail.type === 'RESPONSE_JSON-RPC') {
      // eslint-disable-next-line no-use-before-define
      getTxResultWithTimer(event.detail.payload.result);
    } else if (event.detail.type === 'CANCEL_JSON-RPC') {
      dispatch(setBankLoading(false));
    }
  };

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  useEffect(() => {
    const getMinDepositAmount = async () => {
      const minAmountToDeposit = await getMinimumDepositAmount();
      setMinDepositAmount(minAmountToDeposit);
    };
    getMinDepositAmount();
  }, []);

  const getTx = async (tx: string) => {
    const res: unknown | any = await getTransactionResult(tx);

    if (res.status === 'pending') {
      // eslint-disable-next-line no-use-before-define
      getTxResultWithTimer(tx);
      console.log('pending');
    } else if (res?.status === 'success') {
      res.eventLogs?.forEach((event: any) => {
        if (
          event.indexed[0] === config.events.DEPOSIT_INTO_BANK ||
          event.indexed[0] === config.events.WITHDRAW_FROM_BANK
        ) {
          const parsedAmount = Number(event.indexed[2]) / 10 ** 18;
          dispatch(setBankAmount(parsedAmount));
          dispatch(setBankStatus('complete'));
          dispatch(setBankLoading(false));
        }
      });
      setModalVisible(false);
      // setNestedModalVisible(true);
    } else {
      dispatch(setBankStatus('failed'));
      setModalVisible(false);
      // setNestedModalVisible(true);
      dispatch(setBankLoading(false));
    }
  };

  const getTxResultWithTimer = (txHash: string) => {
    setTimeout(() => {
      getTx(txHash);
    }, 5000);
  };

  const depositOrWithdrawAmount = async (txType: string) => {
    if (txType === 'Deposit') {
      if (userEnteredAmount > minDepositAmount) {
        dispatch(setBankLoading(true));
        setDepositErrorMessage('');
        if (walletAddress && isWalletConnected) {
          const txHash: any = await depositCrownIntoBank(
            walletAddress,
            Number(userEnteredAmount),
            ledgerTransport,
            ledgerPath,
            isLedger
          );
          console.log(txHash);
          if (isLedger) {
            if (txHash?.name === 'TransportStatusError') {
              console.log('Ledger cancelled!!!');
            } else if (txHash) {
              getTxResultWithTimer(txHash as string);
            } else {
              console.log('Error on ledger');
            }
          }
        }
      } else {
        setDepositErrorMessage(`Deposit amount should be greater than ${minDepositAmount}.`);
      }
    }

    if (txType === 'Withdraw') {
      setDepositErrorMessage('');
      if (Number(userEnteredAmount) > 0) {
        dispatch(setBankLoading(true));
        if (walletAddress && isWalletConnected) {
          const txHash: any = await withdrawCrownFromBank(
            walletAddress,
            Number(userEnteredAmount),
            ledgerTransport,
            ledgerPath,
            isLedger
          );
          if (isLedger) {
            if (txHash?.name === 'TransportStatusError') {
              console.log('Ledger cancelled!!!');
            } else if (txHash) {
              getTxResultWithTimer(txHash as string);
            } else {
              console.log('Error on ledger');
            }
          }
          console.log(txHash);
        }
      } else {
        setDepositErrorMessage(`Withdraw amount should be greater than 1.`);
      }
    }
  };

  const setBankLoaderFalse = () => {
    dispatch(setBankLoading(false));
  };

  return (
    <div>
      {isModalVisible ? (
        <Modal title={text} setPopupOpen={setModalVisible} extraFunctions={setBankLoaderFalse}>
          <p className="model-custom-text">
            Your CROWN increases as the xCROWN multiplier goes up, and can be withdrawn at any time.
          </p>
          <div className="modal-input-box">
            <input
              value={
                Number(userEnteredAmount) === 0
                  ? ''
                  : commaAfterThreeDigits(userEnteredAmount.toString())
              }
              onChange={(e) => {
                setDepositErrorMessage('');
                const value = e.target.value.replaceAll(',', '');
                if (!Number.isNaN(Number(value)) && value.length <= 15) {
                  setUserEnteredAmount(value);
                }
              }}
              disabled={bankProcess.bankLoading}
              className={`modal-input-amt ${
                bankProcess.bankLoading && 'disable-deposit-withdraw-box'
              }`}
              type="text"
            />
            <p className="error-message">{depositErrorMessage}</p>
          </div>
          <div className="modal-available-crown">
            {text === 'Deposit' ? (
              <>
                <p className="available-text r-caption" style={{color: 'silver'}}>
                  {Number(userEnteredAmount) !== 0 &&
                    !Number.isNaN(Number(userEnteredAmount)) &&
                    `You will receive ${toFixedNoRounding(
                      Number(userEnteredAmount) / Number(crownPerXCrown),
                      4
                    ).toLocaleString()} xCROWN`}{' '}
                </p>

                <p className="available-text r-caption">
                  <button
                    type="button"
                    onClick={() => {
                      setUserEnteredAmount(crown);
                    }}
                    className={`user-available-crown ${
                      toFixedNoRounding(avoidScientificNotation(crown)) === 0 && 'click-disable'
                    }`}>
                    {toFixedNoRounding(avoidScientificNotation(crown)) !== 0
                      ? toFixedNoRounding(avoidScientificNotation(crown)).toLocaleString()
                      : 0}{' '}
                    CROWN available{' '}
                  </button>
                </p>
              </>
            ) : (
              <>
                <p className="available-text r-caption" style={{color: 'silver'}}>
                  {Number(userEnteredAmount) !== 0 &&
                    !Number.isNaN(Number(userEnteredAmount)) &&
                    `You will receive ${toFixedNoRounding(
                      Number(userEnteredAmount) * Number(crownPerXCrown),
                      4
                    ).toLocaleString()} CROWN`}{' '}
                </p>
                <p className="available-text r-caption">
                  <button
                    type="button"
                    onClick={() => {
                      if (toFixedNoRounding(avoidScientificNotation(totalUserXCrown)) !== 0) {
                        setUserEnteredAmount(totalUserXCrown);
                      }
                    }}
                    className={`user-available-crown ${
                      toFixedNoRounding(avoidScientificNotation(totalUserXCrown)) === 0 &&
                      'click-disable'
                    }`}>
                    {toFixedNoRounding(avoidScientificNotation(totalUserXCrown)).toLocaleString()}{' '}
                    xCROWN available
                  </button>
                </p>
              </>
            )}
          </div>
          <div className="deposit__withdraw__modal__btn">
            <Button
              isProgress={bankProcess.bankLoading}
              onClick={() => {
                depositOrWithdrawAmount(text);
              }}
              style={{width: '300px'}}
              className="primary"
              text={text}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default DepositWithdrawModal;
