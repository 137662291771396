/*eslint-disable*/

import {IMarkerPosition} from '@interfaces/MapMarker.interface';
// import map from '@assets/images/map/fillMap.png';
import {SIMPLE_FILL_MAP} from '@constants/images';
import ImageLoader from '@loader/ImageLoader';

import RippleCanvas from '@components/property/RippleCanvas.component';

const LocationMarkMap = ({lat, lng}: IMarkerPosition) => {
  return (
    <div className="leaflet-map-wrapper">
      <p className="title primary-color">Location on map</p>
      {/* <SimpleMap lat={lat} lng={lng} /> */}
      <div className="map-wrapper">
        <ImageLoader className="map-img" src={SIMPLE_FILL_MAP} alt="map" />
        <RippleCanvas
          // canvasProps={{height: 400, width: 800}}
          circle={{x: lat, y: lng, radius: 0}}
          speed={350}
          maxRadius={20}
          className="map-canvas"
        />
      </div>
    </div>
  );
};

export default LocationMarkMap;
