// import Mint from '@pages/Home/mint/Mint.component';
import Congratulation from '@pages/Home/mint/Congratulation.component';
import EmeraldInfo from '@components/mansion/EmeraldInfo.componenet';
// import GangsterInfo from '@components/mansion/GangsterInfo.component';
import Feature from '@components/mansion/Feature.component';
import Carousel from '@components/mansion/Carousel.component';
import {useSelector} from 'react-redux';
import {RootState} from '@redux/store';
import RevealProperty from '@components/mansion/RevealProperty.component';
// import EmeraldCity from '@components/mansion/EmeraldCity.component';

const Mansion = () => {
  const {recentMintedNFT, mintProgress} = useSelector((state: RootState) => state.mint);
  return (
    <div>
      {recentMintedNFT?.length > 0 && mintProgress.mintStatus === 'complete' ? (
        <Congratulation NFTMintedList={recentMintedNFT} />
      ) : (
        <div>
          {/* <EmeraldCity /> */}
          <RevealProperty />
          <EmeraldInfo />
          {/* <Mint /> */}

          {/* <EmeraldCityMap /> */}
          <Carousel />
          {/* <GangsterInfo /> */}
          <Feature />
        </div>
      )}
    </div>
  );
};

export default Mansion;
