import Logo from '@assets/images/logo/EmeraldCityLogo.svg';
import Map from '@assets/images/extra/map.svg';
import Crown from '@assets/images/logo/crown.svg';
import Icon from '@assets/images/logo/icon.svg';
import Decorator from '@assets/images/common/boxEdge.svg';
import plainSep from '@assets/images/common/plainSeperator.svg';
import stylishSep from '@assets/images/common/line.svg';
import Bank from '@assets/images/bank/bankBottomRight.webp';
import plainDecorEdge from '@assets/images/common/plainEdge.svg';
import verticalLine from '@assets/images/common/verticalLine.svg';
import landSneekpeekHome from '@assets/images/common/land.png';
import mintBoxSep from '@assets/images/common/mintBoxSep.svg';
import cardCertificate from '@assets/images/extra/card_certificate.svg';
import cardSeperator from '@assets/images/extra/card_seperator.svg';
import modalSeperator from '@assets/images/extra/modal_seperator.svg';
import bribe1 from '@assets/images/bribes/1.png';
import bribe2 from '@assets/images/bribes/2.png';
import bribe3 from '@assets/images/bribes/3.png';
import bribe4 from '@assets/images/bribes/4.png';
import bribe5 from '@assets/images/bribes/5.png';
import bribe6 from '@assets/images/bribes/6.png';
import bribe7 from '@assets/images/bribes/7.png';
import bribe8 from '@assets/images/bribes/8.png';
import bribeCollection from '@assets/images/bribes/bribe_collection.svg';
import wallet from '@assets/images/wallet/wallet.png';
import noResultFound from '@assets/images/extra/no_result_found.svg';
import gifLoader from '@assets/images/gifs/loading_chase.gif';
// import emeraldInfo from '@assets/images/extra/emeraldInfo.svg';
import gangster from '@assets/images/extra/gangsters.svg';
import carouselOne from '@assets/images/extra/carouselOne.webp';
import carouselTwo from '@assets/images/extra/carouselTwo.webp';
import carouselThree from '@assets/images/extra/CarouselThree.webp';
import carouselFour from '@assets/images/extra/carouselFour.webp';
import errorIconToaster from '@assets/images/toaster/error_icon.svg';
import successIconToaster from '@assets/images/toaster/success_icon.svg';
import warnIconToaster from '@assets/images/toaster/warn_icon.svg';
import closeToaster from '@assets/images/toaster/close_icon.svg';
import ledger from '@assets/images/extra/ledger.svg';

// images for bribe inventory

import blessedBird from '@assets/images/inventory/blessedBird.svg';
import enchantedStone from '@assets/images/inventory/enchantedStone.svg';
import goldCoin from '@assets/images/inventory/goldCoin.svg';
import hammer from '@assets/images/inventory/hammer.svg';
import potion from '@assets/images/inventory/potion.svg';
import pouch from '@assets/images/inventory/pouch.svg';
import shrine from '@assets/images/inventory/shrine.svg';
import tankard from '@assets/images/inventory/tankard.svg';

// carousel images
import BottomLeftOne from '@assets/images/showPiece/one/BottomLeft_1.webp';
import BottomRightOne from '@assets/images/showPiece/one/BottomRight_1.webp';
import TopLeftOne from '@assets/images/showPiece/one/TopLeft_1.webp';
import TopRightOne from '@assets/images/showPiece/one/TopRight_1.webp';
import BottomLeftTwo from '@assets/images/showPiece/two/BottomLeft_2.webp';
import BottomRightTwo from '@assets/images/showPiece/two/BottomRight_2.webp';
import TopLeftTwo from '@assets/images/showPiece/two/TopLeft_2.webp';
import TopRightTwo from '@assets/images/showPiece/two/TopRight_2.webp';

import BottomLeftThree from '@assets/images/showPiece/three/BottomLeft.webp';
import BottomRightThree from '@assets/images/showPiece/three/BottomRight.webp';
import TopLeftThree from '@assets/images/showPiece/three/TopLeft.webp';
import TopRightThree from '@assets/images/showPiece/three/TopRight.webp';

import BottomLeftFour from '@assets/images/showPiece/four/BottomLeft.webp';
import BottomRightFour from '@assets/images/showPiece/four/BottomRight.webp';
import TopLeftFour from '@assets/images/showPiece/four/TopLeft.webp';
import TopRightFour from '@assets/images/showPiece/four/TopRight.webp';

import BottomLeftFive from '@assets/images/showPiece/five/BottomLeft.webp';
import BottomRightFive from '@assets/images/showPiece/five/BottomRight.webp';
import TopLeftFive from '@assets/images/showPiece/five/TopLeft.webp';
import TopRightFive from '@assets/images/showPiece/five/TopRight.webp';

import CarouselNextIcon from '@assets/images/extra/carouselNextIcon.svg';
import Crown3d from '@assets/images/common/crown-loading.gif';

import EmeraldInfoBg from '@assets/images/extra/emeraldInfoBg.png';
import Hana from '@assets/images/wallet/hana.jpg';

// footer images
import Twitter from '@assets/images/footer/twitter.svg';
import Discord from '@assets/images/footer/discord.svg';
import Telegram from '@assets/images/footer/telegram.svg';
import medium from '@assets/images/footer/medium.svg';

import LandViewer from '@assets/images/extra/landViewer.svg';
import WhiteLandViewer from '@assets/images/extra/white_land_viewer.svg';
import GoldenKey from '@assets/images/common/tiny_golden_key.png';

// certificates

import airport from '@assets/images/certificates/airport.svg';
import commercial from '@assets/images/certificates/commercial.svg';
import education from '@assets/images/certificates/education.svg';
import governmental from '@assets/images/certificates/governmental.svg';
import health from '@assets/images/certificates/health.svg';
import industrial from '@assets/images/certificates/industrial.svg';
import recreational from '@assets/images/certificates/recreational.svg';
import residential from '@assets/images/certificates/residential.svg';
import generalCertificate from '@assets/images/certificates/general.svg';

// sold out certificate
import soldOutCertificate from '@assets/images/extra/sold_out_certificate.svg';

import spotlight from '@assets/images/common/spotlight.png';

import GBETbnUSD from '@assets/images/lp/GBET-balanced.png';
import CROWNbnUSD from '@assets/images/lp/CROWN-balanced.png';

// certificate badges
import airportBadge from '@assets/images/certificates/badges/airport.svg';
import industrialBadge from '@assets/images/certificates/badges/industrial.svg';
import residentialBadge from '@assets/images/certificates/badges/residential.svg';
import healthBadge from '@assets/images/certificates/badges/health.svg';
import governmentalBadge from '@assets/images/certificates/badges/governmental.svg';
import educationBadge from '@assets/images/certificates/badges/education.svg';
import commercialBadge from '@assets/images/certificates/badges/commercial.svg';
import recreationalBadge from '@assets/images/certificates/badges/recreational.svg';

// certificate canvases
import airportCanvas from '@assets/images/certificates/canvas/airport.svg';
import industrialCanvas from '@assets/images/certificates/canvas/industrial.svg';
import residentialCanvas from '@assets/images/certificates/canvas/residential.svg';
import healthCanvas from '@assets/images/certificates/canvas/health.svg';
import governmentalCanvas from '@assets/images/certificates/canvas/governmental.svg';
import educationCanvas from '@assets/images/certificates/canvas/education.svg';
import commercialCanvas from '@assets/images/certificates/canvas/commercial.svg';
import recreationalCanvas from '@assets/images/certificates/canvas/recreational.svg';

import simpleFillMap from '@assets/images/map/fillMap.png';

import CERTIFICATE_LOADING from '@assets/images/gifs/certificate_loading.gif';

import plus from '@assets/images/land/plus.svg';
import collapse from '@assets/images/land/collapse.svg';

const CERTIFICATE_TYPES = <any>{
  Airport: airport,
  Commercial: commercial,
  Education: education,
  Governmental: governmental,
  Health: health,
  Industrial: industrial,
  Recreational: recreational,
  Residential: residential,
};

const CERTIFICATE = generalCertificate;

const SOLD_OUT_CERTIFICATE = soldOutCertificate;

const CROWN3D = Crown3d;

const BANK_PAGE = {
  BANK: Bank,
};

const FOOTER = {
  DISCORD: Discord,
  TELEGRAM: Telegram,
  MEDIUM: medium,
  TWITTER: Twitter,
};

const CARD = {
  CARD_CERTIFICATE: cardCertificate,
  CARD_SEPERATOR: cardSeperator,
};

const DECORATOR = {
  DECORATOR_EDGES: Decorator,
  PLAIN_SEPERATOR: plainSep,
  STYLISH_SEPERATOR: stylishSep,
  PLAIN_EDGES: plainDecorEdge,
  VERTICAL_LINE: verticalLine,
  MINT_BOX_SEPERATOR: mintBoxSep,
  MODAL_SEPERATOR: modalSeperator,
};

const INVENTORY_BRIBE = <any>{
  Recreational: tankard,
  Airport: blessedBird,
  Industrial: hammer,
  Governmental: pouch,
  Education: shrine,
  Health: potion,
  Commercial: goldCoin,
  Residential: enchantedStone,
};

const LAND_VIEWER = LandViewer;
const WHITE_LAND_VIEWER = WhiteLandViewer;

const HEADER = {
  LOGO: Logo,
  MAP: Map,
  ICON: Icon,
  CROWN: Crown,
  GOLDEN_KEY: GoldenKey,
};

const TOASTER_ICONS = {
  ERROR: errorIconToaster,
  SUCCESS: successIconToaster,
  WARN: warnIconToaster,
  CLOSE: closeToaster,
};

const CROWN = Crown;
const ICON = Icon;
const BRIBE = bribeCollection;
const BRIBES = [bribe1, bribe2, bribe3, bribe4, bribe5, bribe6, bribe7, bribe8];

const WALLET = {
  WALLET: wallet,
};

const MANSION = {
  EMERALD_INFO_BG: EmeraldInfoBg,
  GANGSTER: gangster,
  CAROUSEL: [carouselOne, carouselTwo, carouselThree, carouselFour],
  CAROUSEL_IMAGES: [
    [BottomLeftOne, BottomRightOne, TopRightOne, TopLeftOne],
    [BottomLeftFive, BottomRightFive, TopLeftFive, TopRightFive],
    [BottomLeftTwo, BottomRightTwo, TopRightTwo, TopLeftTwo],
    [BottomLeftThree, BottomRightThree, TopLeftThree, TopRightThree],
    [BottomLeftFour, BottomRightFour, TopLeftFour, TopRightFour],
  ],
  CAROUSEL_NEXT_ICON: CarouselNextIcon,
  SPOTLIGHT: spotlight,
};

const NO_RESULT_FOUND = noResultFound;
const BANK_3D_VIEW_LOADER = gifLoader;
const LEDGER = ledger;
const HANA = Hana;

const HOMEPAGE_LAND_SNEAKPEEK = landSneekpeekHome;
const LP_POOL = {
  GBET_POOL: GBETbnUSD,
  CROWN_POOL: CROWNbnUSD,
};

const CERTIFICATE_BADGES = <any>{
  Airport: airportBadge,
  Industrial: industrialBadge,
  Education: educationBadge,
  Health: healthBadge,
  Governmental: governmentalBadge,
  Recreational: recreationalBadge,
  Residential: residentialBadge,
  Commercial: commercialBadge,
};

const CANVAS_CERTIFICATE = <any>{
  Recreational: recreationalCanvas,
  Airport: airportCanvas,
  Industrial: industrialCanvas,
  Education: educationCanvas,
  Health: healthCanvas,
  Governmental: governmentalCanvas,
  Residential: residentialCanvas,
  Commercial: commercialCanvas,
};

const SIMPLE_FILL_MAP = simpleFillMap;

const ICON_PLUS = {
  SOURCE: plus,
};

const ICON_COLLAPSE = {
  SOURCE: collapse,
};
export {
  CERTIFICATE,
  HEADER,
  NO_RESULT_FOUND,
  DECORATOR,
  BANK_PAGE,
  CARD,
  BRIBE,
  BRIBES,
  CROWN,
  WALLET,
  ICON,
  BANK_3D_VIEW_LOADER,
  MANSION,
  TOASTER_ICONS,
  LEDGER,
  CROWN3D,
  HANA,
  LAND_VIEWER,
  WHITE_LAND_VIEWER,
  FOOTER,
  INVENTORY_BRIBE,
  CERTIFICATE_TYPES,
  SOLD_OUT_CERTIFICATE,
  HOMEPAGE_LAND_SNEAKPEEK,
  LP_POOL,
  CERTIFICATE_BADGES,
  CANVAS_CERTIFICATE,
  SIMPLE_FILL_MAP,
  CERTIFICATE_LOADING,
  ICON_PLUS,
  ICON_COLLAPSE,
};
