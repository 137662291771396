import {Link} from 'react-router-dom';
import {TbArrowUpRight} from 'react-icons/tb';

import PlainDecor from '@components/common/decorators/PlainDecor.component';
import ImageLoader from '@loader/ImageLoader';
import {useSelector} from 'react-redux';
import {RootState} from '@redux/store';
import {DEV_LAND_REVEAL_BUCKET} from '@constants/constants';
import {useEffect} from 'react';

const CongratulationSummary = () => {
  const {revealedNftsMetadata} = useSelector((state: RootState) => state.landReveal);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="congrat-summary-wrapper">
      <div className="summary-cards">
        {revealedNftsMetadata.map((nft: any, index: number) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="card-wrapper" key={index}>
              <PlainDecor edgeStyleEnabled>
                <ImageLoader
                  // src={nft.image_url}
                  src={
                    DEV_LAND_REVEAL_BUCKET ? DEV_LAND_REVEAL_BUCKET + nft.preview : nft.image_url
                  }
                  // src={`${process.env.REACT_APP_LAND_BUCKET_API}/${nft.preview}`}
                  className="property-image"
                  alt="property"
                  skeletonStyle={{height: '250px', position: 'relative', zIndex: '5'}}
                />
                <div className="property-info-wrapper">
                  <p className="property-name-text">{nft.name}</p>
                  <p className="zone-type-text">{nft.zone} zone</p>
                  <Link
                    className="links property-detail-link primary-color"
                    to={`/land?token=${+nft.nftId}`}>
                    View details page <TbArrowUpRight />
                  </Link>
                </div>
              </PlainDecor>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CongratulationSummary;
