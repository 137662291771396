/* eslint-disable */
export const toFixedNoRounding: any = (num: number, fixed = 4) => {
  if (num != null && Number(num) > 0.0001) {
    const numStr = num.toString();
    // const numStr = num.toLocaleString('fullwide', {useGrouping: false});
    const splitArr = numStr.split('.');
    let fixedNum;
    if (splitArr.length === 1) {
      fixedNum = num;
    } else {
      // eslint-disable-next-line
      const splitNum = Number(numStr.split('.')[0] + '.' + numStr.split('.')[1].slice(0, fixed));
      fixedNum = splitNum;
    }
    return fixedNum;
  } else {
    return 0;
  }
};

export const commaAfterThreeDigits = (num: string) => {
  const afterPoint = num.split('.');
  try {
    const replaceNum = afterPoint[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (afterPoint.length === 2) {
      return `${replaceNum}.${afterPoint[1].slice(0, 10)}`;
    }
    return replaceNum;
  } catch {
    return num;
  }
};

export const objectToHexWithPrefix = (params: any) => {
  const enc = new TextEncoder();
  const arrayBuffer = enc.encode(JSON.stringify(params));

  if (
    typeof arrayBuffer !== 'object' ||
    arrayBuffer === null ||
    typeof arrayBuffer.byteLength !== 'number'
  ) {
    throw new TypeError('Expected input to be an ArrayBuffer');
  }

  const view = new Uint8Array(arrayBuffer);
  let result = '';
  let value;

  for (let i = 0; i < view.length; i += 1) {
    value = view[i].toString(16);
    result += value.length === 1 ? `0${value}` : value;
  }

  return `0x${result}`;
};

export const dateFormatter = (date: string) => {
  const year = date.split('-')[0];
  let month = date.split('-')[1];
  if (month.length === 1) {
    month = `0${month}`;
  }
  return `${year}-${month}`;
};

export const monthAbbrev = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MAPPING_LOC_WITH_TYPES = <any>{
  Recreational: 1,
  Airport: 2,
  Industrial: 3,
  Governmental: 4,
  Education: 5,
  Health: 6,
  Commercial: 7,
  Residential: 8,
};

export const avoidScientificNotation = (x: any) => {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
};
