/* eslint-disable */
import {useState, useEffect} from 'react';
import {AiOutlineMinusCircle, AiOutlinePlusCircle} from 'react-icons/ai';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {RootState} from '@redux/store';
import {
  decrement,
  increment,
  setRevealLoading,
  setLandRevealCount,
  setRevealedNFT,
  setRevealStatus,
} from '@redux/features/landReveal/landRevealSlice';
import {setWalletConnection, setWalletAddress} from '@redux/features/wallet/walletSlice';

import LoadingScreen from '@pages/LandReveal/LoadingScreen.component';
import Certificate from '@pages/LandReveal/CertificateCard.component';
import StylishSeperator from '@components/common/decorators/StylishSeperator.component';
import StylishDecor from '@components/common/decorators/StylishDecor.component';
import PlainSeperator from '@components/common/decorators/PlainSeperator.component';
import Carousel from '@components/Carousel/Carousel.component';
import Button from '@components/common/Button.component';
import toasterService from '@components/common/toaster/ToasterService.component';
import LandRevealApproval from '@components/modal/LandRevealApproval';
import RevealFailedModal from '@components/modal/RevealFailedModal.modal';

import {
  checkICONaddressValidity,
  getOwnedNFTs,
  getZoneDetailsOfCertificate,
  approveLandAddressForReveal,
  isAddressApprovedForLandReveal,
  getTransactionResult,
  mintLand,
} from '@services/Icon.service';
import {ICON_STATES, MINT_STATES} from '@constants/states';
import {config} from '@config/config';
import {MAPPING_LOC_WITH_TYPES} from '@services/Misc.service';
import {FiExternalLink} from 'react-icons/fi';
import {syncLandMint, syncUserAddress} from '@api/API';
import {CERTIFICATE_LOADING} from '@constants/images';

interface IRevealedNFTDetail {
  nftId: string;
  nftMetaId: string;
}

const MAX_LAND_REVEAL = process.env.REACT_APP_MAX_LAND_REVEAL
  ? +process.env.REACT_APP_MAX_LAND_REVEAL
  : 5;

export const LandReveal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [walletCertificates, setWalletCertificates] = useState<any>({});
  const [zoneCertificateSupply, setZoneCertificateSupply] = useState<any>({});
  const [activeZone, setActiveZone] = useState<string>('');
  const [zoneMaxLandReveal, setZoneMaxLandReveal] = useState<any>(MAX_LAND_REVEAL);
  const [isFailedModalVisible, setFailedModalVisible] = useState<boolean>(true);
  const [showCertificateSkeleton, setShowCertificateSkeleton] = useState(false);
  const [landScoreApproved, setLandScoreApproved] = useState<boolean>(false);
  const [revealLoadingText, setRevealLoadingText] = useState<string>('waiting for transaction');

  const [isApproveModalVisible, setApproveModalVisible] = useState<boolean>(false);
  const [isApproveModalLoading, setApproveModalLoading] = useState<boolean>(false);
  const [certificateLoading, setCertificateLoading] = useState<boolean>(true);

  const [beLandSynced, setBELandSynced] = useState<boolean>(false);

  const {revealProgress} = useSelector((state: RootState) => state.landReveal);
  const {walletAddress, ledgerTransport, ledgerPath, isLedger} = useSelector(
    (state: RootState) => state.inventory
  );

  const syncBELandMint = async (txHash: string, retryCount = 0) => {
    try {
      const res = await syncLandMint(txHash);
      if (res?.status === 200) {
        setBELandSynced(true);
      } else {
        throw new Error('Syncing failed');
      }
    } catch (err) {
      setBELandSynced(false);

      if (retryCount < 2) {
        setTimeout(() => {
          syncBELandMint(txHash, retryCount + 1);
        }, 15000);
      } else {
        syncUserAddress(walletAddress!);
        toasterService.set({
          title: 'INFO',
          message: `Mint Successful. Syncing on progress`,
          type: 'success',
        });
        // await timeout(6000);

        dispatch(setRevealedNFT([]));
        dispatch(setRevealStatus('start'));
        dispatch(setRevealLoading(false));
        getOwnedNft();
      }
    }
  };

  const getOwnedNft = async () => {
    const isValid = await checkICONaddressValidity(walletAddress);

    if (isValid) {
      setShowCertificateSkeleton(true);
      const ownedNfts = await getOwnedNFTs(walletAddress as any);
      const totalZoneCertificateCount: any = {};

      for (let i = 0; i < 8; i++) {
        const certificateDetail = await getZoneDetailsOfCertificate(i + 1);
        totalZoneCertificateCount[certificateDetail.zoneName] = Number(
          certificateDetail.totalSupply
        );
      }
      setZoneCertificateSupply({...totalZoneCertificateCount});
      setWalletCertificates({...ownedNfts});
      setShowCertificateSkeleton(false);
      setCertificateLoading(false);
    } else {
      setWalletCertificates({});
      navigate('/error/');
    }
  };

  useEffect(() => {
    getOwnedNft();
  }, []);

  useEffect(() => {
    if (!walletCertificates) return;
    const zoneCertificateOwned = Number(walletCertificates[activeZone]);

    zoneCertificateOwned < MAX_LAND_REVEAL
      ? setZoneMaxLandReveal(zoneCertificateOwned)
      : setZoneMaxLandReveal(MAX_LAND_REVEAL);

    if (revealProgress.landRevealCount > zoneCertificateOwned) {
      dispatch(setLandRevealCount(zoneCertificateOwned));
    }
  }, [activeZone, walletCertificates]);

  useEffect(() => {
    window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler, false);
    return () => {
      window.removeEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    };
  }, []);

  useEffect(() => {
    if (!beLandSynced) return;
    dispatch(setRevealStatus(MINT_STATES.COMPLETE));
  }, [beLandSynced]);

  const eventHandler = (event: any) => {
    /* Handling wallet connection */
    if (event.detail.type === 'RESPONSE_ADDRESS') {
      if (event.detail.payload) {
        dispatch(setWalletConnection(true));
        dispatch(setWalletAddress(event.detail.payload));
        dispatch(setRevealLoading(false));
      } else {
        console.log('Error on wallet connection!');
        dispatch(setRevealLoading(false));
      }

      /* Handling txn */
    } else if (event.detail.type === 'RESPONSE_JSON-RPC') {
      const txHash = event.detail.payload.result;
      getTxResultWithTimer(txHash);

      /* Handling cancel event */
    } else if (event.detail.type === 'CANCEL_JSON-RPC') {
      dispatch(setRevealLoading(false));
    }
  };

  const handleRevealError = () => {
    dispatch(setRevealedNFT([]));
    dispatch(setRevealStatus('start'));
    dispatch(setRevealLoading(false));
    setFailedModalVisible(true);
  };

  const getTxResultWithTimer = (txHash: string) => {
    setTimeout(() => {
      getTx(txHash);
    }, 5000);
  };

  const timeout = (delay: number) => new Promise((res) => setTimeout(res, delay));

  const getTx = async (tx: string) => {
    const res: unknown | any = await getTransactionResult(tx);
    console.log('Tx result ===> ', res);

    if (res.status === ICON_STATES.PENDING) {
      // eslint-disable-next-line
      getTxResultWithTimer(tx);
    } else if (res.status === ICON_STATES.SUCCESS) {
      let isRevealed = false;
      const revealedNFTs: IRevealedNFTDetail[] = [];

      for (const event of res.eventLogs) {
        if (event.indexed[0] == config.events.APPROVAL_FOR_ALL) {
          setApproveModalLoading(false);
          setApproveModalVisible(false);
          setLandScoreApproved(true);

          toasterService.set({
            title: 'SUCCESS',
            message: `Setting approval successful.`,
            type: 'success',
          });
        } else if (event.indexed[0] == config.events.MINT_LAND) {
          setRevealLoadingText('revealing your property');

          const nftIds = {
            nftId: event.indexed[1],
            nftMetaId: event.indexed[2],
          };
          revealedNFTs.push(nftIds);
          isRevealed = true;
        }
      }

      if (isRevealed) {
        // await timeout(5000);
        await syncBELandMint(tx);
        dispatch(setRevealedNFT([...revealedNFTs]));
        // dispatch(setRevealStatus(MINT_STATES.COMPLETE));
      } else {
        dispatch(setRevealStatus(''));
        dispatch(setRevealLoading(false));
      }
    } else if (res.status === ICON_STATES.ERROR) {
      dispatch(setRevealStatus(MINT_STATES.FAILED));
      dispatch(setRevealLoading(false));
    }
  };

  const handleApproveBtnClick = async () => {
    setApproveModalLoading(true);

    const txHash: any = await approveLandAddressForReveal(
      walletAddress,
      ledgerTransport,
      ledgerPath,
      isLedger
    );

    if (isLedger) {
      if (txHash?.name === 'TransportStatusError') {
        console.log('Ledger cancelled!!!');
      } else if (txHash) {
        getTxResultWithTimer(txHash as string);
      } else {
        console.log('Error on ledger');
      }
    }
  };

  useEffect(() => {
    (async () => {
      const approvedStatus = await isAddressApprovedForLandReveal(walletAddress);
      setLandScoreApproved(approvedStatus);
    })();
  }, []);

  const handleLandRevealBtnClick = async () => {
    const approvedStatus = await isAddressApprovedForLandReveal(walletAddress);

    if (approvedStatus == 0) {
      setApproveModalVisible(true);
    } else {
      dispatch(setRevealLoading(true));

      const txHash: any = await mintLand(
        walletAddress,
        MAPPING_LOC_WITH_TYPES[activeZone],
        revealProgress.landRevealCount,
        ledgerTransport,
        ledgerPath,
        isLedger
      );

      if (isLedger) {
        if (txHash?.name === 'TransportStatusError') {
          console.log('Ledger cancelled!!!');
        } else if (txHash) {
          getTxResultWithTimer(txHash);
        } else {
          console.log('Error on ledger');
        }
      }
    }
  };

  return (
    <>
      <StylishDecor style={{margin: '36px 0px 46px'}} className="land-reveal-page">
        <StylishSeperator
          className="stylish-info-seperator extra-sm-seperator"
          text="Reveal Your Property"
        />
        {/* <p className="text-center r-body-1" style={{padding: '20px'}}>
          Welcome to the Emerald City! If you hold land certificate(s), you can reveal them below.
        </p> */}
        <div className="land-reveal-wrapper" style={{padding: '20px'}}>
          {Object.keys(walletCertificates).length > 0 ? (
            <>
              <div className="land-reveal-header">
                <p className="title text-center">Select your Land Ownership Certificate</p>
                <PlainSeperator width="30%" />
                {/* <p className="sub-title text-center">
                  Select the type of land certificate you own from the options below
                </p> */}
              </div>
              <Carousel width="65" setActiveZone={setActiveZone}>
                {showCertificateSkeleton ? (
                  <div className={`skeleton skeleton-default-width skeleton-certificate`} />
                ) : (
                  Object.keys(walletCertificates).map((key, index) => {
                    return (
                      <div className="carousel-item" key={`key_${index}`}>
                        <Certificate
                          type={key}
                          number={Number(Object.values(walletCertificates)[index])}
                          totalCount={zoneCertificateSupply[key]}
                        />
                      </div>
                    );
                  })
                )}
              </Carousel>
              <div className="reveal-amt-wrapper">
                <p className="r-subtitle-2">Select the number of LOCs you want to use</p>
                <p className="reveal-amt-subtitle">Maximum limit is {MAX_LAND_REVEAL}</p>
                <div className="reveal-amt-counter">
                  <AiOutlineMinusCircle
                    onClick={() => {
                      if (revealProgress.landRevealCount > 1) {
                        dispatch(decrement());
                      }
                    }}
                    className="mint-minus"
                    size={45}
                  />
                  <h3 className="h3 mint-count">{revealProgress.landRevealCount}</h3>
                  <AiOutlinePlusCircle
                    onClick={() => {
                      if (
                        MAX_LAND_REVEAL !== 0 &&
                        revealProgress.landRevealCount < zoneMaxLandReveal
                      ) {
                        dispatch(increment());
                      }
                    }}
                    className="mint-plus"
                    size={45}
                  />
                </div>
                <div className="reveal-btn-wrapper">
                  <Button
                    text="Approve"
                    className="primary reveal-property-btn"
                    style={{width: '300px', marginTop: '20px'}}
                    onClick={() => {
                      handleLandRevealBtnClick();
                    }}
                    disabled={landScoreApproved}
                    shimmer
                  />
                  <Button
                    text="Reveal"
                    className="primary reveal-property-btn"
                    style={{width: '300px', marginTop: '20px'}}
                    onClick={() => {
                      handleLandRevealBtnClick();
                    }}
                    disabled={!landScoreApproved}
                    shimmer
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="no-certificate-found">
              {certificateLoading ? (
                <>
                  <img
                    src={CERTIFICATE_LOADING}
                    alt="certificate-loading"
                    height="350px"
                    width={'350px'}
                  />
                </>
              ) : (
                <>
                  <p>Looks like you don't have any certificates</p>
                  <div style={{textAlign: 'center'}}>
                    <Button
                      text="Buy Land Certificate in Craft"
                      icon={<FiExternalLink />}
                      onClick={() => {
                        window.open(`https://craft.network/collection/land-certificate`, '_blank');
                      }}
                      className="primary"
                      style={{width: '100%'}}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </StylishDecor>
      <LoadingScreen loading={revealProgress.revealLoading} text={revealLoadingText} />
      <LandRevealApproval
        isApproveModalVisible={isApproveModalVisible}
        setApproveModalVisible={setApproveModalVisible}
        isApproveModalLoading={isApproveModalLoading}
        handleApproveButtonClick={handleApproveBtnClick}
      />

      <RevealFailedModal
        revealProgress={revealProgress}
        isVisible={isFailedModalVisible}
        setVisible={setFailedModalVisible}
        handleRevealError={handleRevealError}
      />
    </>
  );
};

export default LandReveal;
